/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { MemberQuota } from '../models/MemberQuota';
import type { Paging_ExtraRecordingQuota_ } from '../models/Paging_ExtraRecordingQuota_';
import type { RecordingQuota } from '../models/RecordingQuota';

export class QuotaService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Member Quota
     * 현재 워크스페이스의 아카이빙 계정 Quota 및 사용량을 가져옵니다.
     * total이 0이면 제공되는 라이센스가 없음을 의미합니다.
     * @param slug
     * @returns MemberQuota Successful Response
     * @throws ApiError
     */
    public getMemberQuotaV1WorkspaceSlugQuotaMemberGet(
        slug: string
    ): CancelablePromise<MemberQuota> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/quota/member',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Recording Quota
     * 현재 워크스페이스의 레코딩 Quota 및 사용량을 가져옵니다.
     * total이 0이면 제공되는 라이센스가 없음을 의미합니다.
     * @param slug
     * @returns RecordingQuota Successful Response
     * @throws ApiError
     */
    public getRecordingQuotaV1WorkspaceSlugQuotaRecordingGet(
        slug: string
    ): CancelablePromise<RecordingQuota> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/quota/recording',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Recording Extra Quotas
     * 현재 워크스페이스에 부여된 추가 레코딩 Quota를 가져옵니다.
     * @param slug
     * @returns Paging_ExtraRecordingQuota_ Successful Response
     * @throws ApiError
     */
    public listRecordingExtraQuotasV1WorkspaceSlugQuotaRecordingExtraGet(
        slug: string
    ): CancelablePromise<Paging_ExtraRecordingQuota_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/quota/recording/extra',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
