/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GoogleAuthentication = {
    grant_type: GoogleAuthentication.grant_type;
    id_token: string;
    invitation_token?: string;
};

export namespace GoogleAuthentication {
    export enum grant_type {
        GOOGLE = 'google',
    }
}
