/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { SlackChannelIntegration } from '../models/SlackChannelIntegration';
import type { SlackIntegrationCreate } from '../models/SlackIntegrationCreate';

export class WebhookService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Slack Channel Integration
     * Slack channel incoming-webhook 연동을 조회합니다.
     * @param slug
     * @returns SlackChannelIntegration Successful Response
     * @throws ApiError
     */
    public getSlackChannelIntegrationV1WorkspaceSlugSlackGet(
        slug: string
    ): CancelablePromise<SlackChannelIntegration> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/slack',
            path: {
                slug: slug,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Slack Channel Integration
     * Slack channel incoming-webhook 연동을 새로 추가합니다.
     * @param slug
     * @param requestBody
     * @returns SlackChannelIntegration Successful Response
     * @throws ApiError
     */
    public createSlackChannelIntegrationV1WorkspaceSlugSlackPost(
        slug: string,
        requestBody: SlackIntegrationCreate
    ): CancelablePromise<SlackChannelIntegration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/slack',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Slack Integration
     * @param slug
     * @returns void
     * @throws ApiError
     */
    public deleteSlackIntegrationV1WorkspaceSlugSlackDelete(slug: string): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/slack',
            path: {
                slug: slug,
            },
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }
}
