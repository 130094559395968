import { WorkspacesUsersType } from 'callabo-api/src';
import { LicensePlan } from 'libs/callabo-state/types/License';

export type FeaturePolicyNames =
    // TODO: 필요에 따라 주석 제거하고 기능 적용 예정
    | 'edit_workspace_name'
    | 'add_member'
    /**
     * owner만 가능
     */
    | 'only_owner'
    /**
     * amdin 이상 가능
     */
    | 'above_admin'
    /**
     * member 이상 가능
     */
    | 'above_member'
    /**
     * guest 이상 가능
     */
    | 'above_guest';

export const FEATURE_POLICY: Record<FeaturePolicyNames, Record<WorkspacesUsersType, boolean>> = {
    edit_workspace_name: {
        owner: true,
        admin: true,
        member: false,
        guest: false,
        deactivated: false,
    },
    add_member: {
        owner: true,
        admin: true,
        member: false,
        guest: false,
        deactivated: false,
    },
    only_owner: {
        owner: true,
        admin: false,
        member: false,
        guest: false,
        deactivated: false,
    },
    above_admin: {
        owner: true,
        admin: true,
        member: false,
        guest: false,
        deactivated: false,
    },
    above_member: {
        owner: true,
        admin: true,
        member: true,
        guest: false,
        deactivated: false,
    },
    above_guest: {
        owner: true,
        admin: true,
        member: true,
        guest: true,
        deactivated: false,
    },
};

/** plan별 기능 제한 목적 */
export type PlanPolicyNames = 'above_plus' | 'above_pro' | 'only_trial' | 'only_plus';

export const PLAN_POLICY: Record<PlanPolicyNames, Record<LicensePlan, boolean>> = {
    above_plus: {
        trial: false,
        plus: true,
        pro: true,
    },
    above_pro: {
        trial: false,
        plus: false,
        pro: true,
    },
    only_trial: {
        trial: true,
        plus: false,
        pro: false,
    },
    only_plus: {
        trial: false,
        plus: true,
        pro: false,
    },
};
