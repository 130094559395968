/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Body_update_recording_schedule_v1_workspace__slug__event__id__recording_schedule_patch } from '../models/Body_update_recording_schedule_v1_workspace__slug__event__id__recording_schedule_patch';
import type { Body_update_transcribe_config_v1_workspace__slug__event__id__transcribe_config_patch } from '../models/Body_update_transcribe_config_v1_workspace__slug__event__id__transcribe_config_patch';
import type { EventWithExtra } from '../models/EventWithExtra';
import type { InstantEvent } from '../models/InstantEvent';
import type { InstantEventCreateRequest } from '../models/InstantEventCreateRequest';
import type { Paging_EventWithExtra_ } from '../models/Paging_EventWithExtra_';

export class EventService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Event
     * @param slug
     * @param query 검색어: Callabo 주간회의
     * @param startAt
     * @param endAt
     * @param userId 참석자 기반 필터링
     * @param nextToken pagination token
     * @returns Paging_EventWithExtra_ Successful Response
     * @throws ApiError
     */
    public listEventV1WorkspaceSlugEventGet(
        slug: string,
        query?: string,
        startAt?: string,
        endAt?: string,
        userId?: number,
        nextToken?: string
    ): CancelablePromise<Paging_EventWithExtra_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/event',
            path: {
                slug: slug,
            },
            query: {
                query: query,
                start_at: startAt,
                end_at: endAt,
                user_id: userId,
                next_token: nextToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Recording Schedule
     * @param id
     * @param slug
     * @param requestBody
     * @returns EventWithExtra Successful Response
     * @throws ApiError
     */
    public updateRecordingScheduleV1WorkspaceSlugEventIdRecordingSchedulePatch(
        id: number,
        slug: string,
        requestBody: Body_update_recording_schedule_v1_workspace__slug__event__id__recording_schedule_patch
    ): CancelablePromise<Array<EventWithExtra>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}/event/{id}/recording-schedule',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Event in past`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Transcribe Config
     * @param id
     * @param slug
     * @param requestBody
     * @returns EventWithExtra Successful Response
     * @throws ApiError
     */
    public updateTranscribeConfigV1WorkspaceSlugEventIdTranscribeConfigPatch(
        id: number,
        slug: string,
        requestBody?: Body_update_transcribe_config_v1_workspace__slug__event__id__transcribe_config_patch
    ): CancelablePromise<Array<EventWithExtra>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}/event/{id}/transcribe-config',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                410: `Event in past`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Instant Event
     * @param slug
     * @param requestBody
     * @returns InstantEvent Successful Response
     * @throws ApiError
     */
    public createInstantEventV1WorkspaceSlugEventInstantPost(
        slug: string,
        requestBody: InstantEventCreateRequest
    ): CancelablePromise<InstantEvent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/event/instant',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                402: `Workspace Quota Exceeded`,
                409: `Instant Event Already Exists`,
                422: `Invalid Conference URL`,
            },
        });
    }
}
