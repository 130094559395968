import { useState } from 'react';

const useDropdownMenu = (): {
    expanded: boolean;
    openMenu: () => void;
    closeMenu: () => void;
} => {
    const [expanded, setExpanded] = useState(false);

    const openMenu = () => {
        setExpanded(true);
    };

    const closeMenu = () => {
        setExpanded(false);
    };

    return { expanded, openMenu, closeMenu };
};

export default useDropdownMenu;
