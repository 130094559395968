/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type WorkspaceMemberUpdateRequest = {
    user_id: number;
    type: WorkspaceMemberUpdateRequest.type;
};

export namespace WorkspaceMemberUpdateRequest {
    export enum type {
        ADMIN = 'admin',
        MEMBER = 'member',
        GUEST = 'guest',
        DEACTIVATED = 'deactivated',
    }
}
