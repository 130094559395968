/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Body_update_transcribe_config_v1_workspace__slug__event__id__transcribe_config_patch = {
    /**
     * 언어
     */
    language?: Body_update_transcribe_config_v1_workspace__slug__event__id__transcribe_config_patch.language;
    /**
     * 반복 일정일 경우, 이 후 일정에도 적용할지 유/무
     */
    recurring?: boolean;
};

export namespace Body_update_transcribe_config_v1_workspace__slug__event__id__transcribe_config_patch {
    /**
     * 언어
     */
    export enum language {
        KO = 'ko',
        EN = 'en',
        JA = 'ja',
    }
}
