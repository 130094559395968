/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { ActionItemModel } from './ActionItemModel';
import type { ActionItemTimestampModel } from './ActionItemTimestampModel';
import type { DateTimeModel } from './DateTimeModel';
import type { RecordInsightType } from './RecordInsightType';
import type { TContentModel } from './TContentModel';

export type RecordInsight = {
    record_id: number;
    type: RecordInsightType;
    status: RecordInsight.status;
    value?:
        | Array<TContentModel>
        | Array<string>
        | Array<DateTimeModel>
        | Array<ActionItemTimestampModel>
        | Array<ActionItemModel>;
};

export namespace RecordInsight {
    export enum status {
        CREATED = 'created',
        PROCESSED = 'processed',
        FAILED = 'failed',
        NOT_SUPPORTED = 'not_supported',
    }
}
