/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Group } from '../models/Group';
import type { GroupCreateRequest } from '../models/GroupCreateRequest';
import type { GroupMembersCreate } from '../models/GroupMembersCreate';
import type { GroupUpdateRequest } from '../models/GroupUpdateRequest';

export class GroupService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Group
     * worksapce 내의 user가 속한 group 목록을 반환합니다.
     * owner와 admin은 모든 group을 조회할 수 있고, member와 guest는 자신이 속한 group만 조회할 수 있습니다.
     * @param slug
     * @returns Group Successful Response
     * @throws ApiError
     */
    public listGroupV1WorkspaceSlugGroupsGet(slug: string): CancelablePromise<Array<Group>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/groups',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Group
     * @param slug
     * @param requestBody
     * @returns Group Successful Response
     * @throws ApiError
     */
    public createGroupV1WorkspaceSlugGroupPost(
        slug: string,
        requestBody: GroupCreateRequest
    ): CancelablePromise<Group> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/group',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Group
     * @param slug
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteGroupV1WorkspaceSlugGroupIdDelete(
        slug: string,
        id: number
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/group/{id}',
            path: {
                slug: slug,
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Group
     * @param slug
     * @param id
     * @param requestBody
     * @returns Group Successful Response
     * @throws ApiError
     */
    public updateGroupV1WorkspaceSlugGroupIdPatch(
        slug: string,
        id: number,
        requestBody: GroupUpdateRequest
    ): CancelablePromise<Group> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}/group/{id}',
            path: {
                slug: slug,
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Group Members
     * @param slug
     * @param id
     * @param requestBody
     * @returns Group Successful Response
     * @throws ApiError
     */
    public addGroupMembersV1WorkspaceSlugGroupIdMembersPost(
        slug: string,
        id: number,
        requestBody: GroupMembersCreate
    ): CancelablePromise<Group> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/group/{id}/members',
            path: {
                slug: slug,
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Group Member
     * @param memberId
     * @param slug
     * @param id
     * @returns Group Successful Response
     * @throws ApiError
     */
    public removeGroupMemberV1WorkspaceSlugGroupIdMemberMemberIdDelete(
        memberId: number,
        slug: string,
        id: number
    ): CancelablePromise<Group> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/group/{id}/member/{member_id}',
            path: {
                member_id: memberId,
                slug: slug,
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
