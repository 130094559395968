/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { DailyUsage } from '../models/DailyUsage';
import type { MonthlyUsage } from '../models/MonthlyUsage';

export class UsageService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Usage Daily
     * 기간내 일별 기록 가져오기
     * @param slug
     * @param startDate 조회 시작일자 (yyyy-mm-dd)
     * @param endDate 조회 마지막 날짜 (yyyy-mm-dd / Default: 오늘날짜)
     * @returns DailyUsage Successful Response
     * @throws ApiError
     */
    public listUsageDailyV1WorkspaceSlugUsageDailyGet(
        slug: string,
        startDate: string,
        endDate: string = '2024-10-21'
    ): CancelablePromise<Array<DailyUsage>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/usage/daily',
            path: {
                slug: slug,
            },
            query: {
                start_date: startDate,
                end_date: endDate,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Usage Monthly
     * 기간내 월별 기록 가져오기
     * @param slug
     * @param startMonth 조회 시작월 (yyyy-mm)
     * @param endMonth 조회 마지막 월 (yyyy-mm)
     * @returns MonthlyUsage Successful Response
     * @throws ApiError
     */
    public listUsageMonthlyV1WorkspaceSlugUsageMonthlyGet(
        slug: string,
        startMonth: string,
        endMonth: string
    ): CancelablePromise<Array<MonthlyUsage>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/usage/monthly',
            path: {
                slug: slug,
            },
            query: {
                start_month: startMonth,
                end_month: endMonth,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }
}
