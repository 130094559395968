import styled from '@emotion/styled';
import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { RecordingQuotaModel } from 'libs/callabo-state/models/RecordingQuotaModel';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { secToHourMinute } from 'libs/rtzr-commons/NumberUtils';
import Image from 'next/image';
import { BarChart } from 'src/ui/common/BarChart';

export const UsageState = ({
    recordingQuota,
    locale,
}: {
    recordingQuota: RecordingQuotaModel | undefined;
} & PageProps): JSX.Element => {
    const { t } = useTranslate({ locale });
    return (
        <>
            <RemainingUsage hasEnoughRemaining={recordingQuota?.hasEnoughRemaining}>
                {recordingQuota?.hasEnoughRemaining ? (
                    <>
                        <span>
                            {t.lnb.USAGE_REMAINING_ENOUGH.replace(
                                '{{remaining}}',
                                secToHourMinute(recordingQuota?.remaining ?? 0, locale)
                            )}
                        </span>
                        <Image
                            src={require('../assets/icon_medium_arrow-right_enough.svg')}
                            width={16}
                            height={16}
                            alt="enough"
                        />
                    </>
                ) : (
                    <>
                        <span>
                            {t.lnb.USAGE_REMAINING.replace(
                                '{{remaining}}',
                                secToHourMinute(recordingQuota?.remaining ?? 0, locale)
                            )}
                        </span>
                        <Image
                            src={require('../assets/icon_medium_arrow-right.svg')}
                            width={16}
                            height={16}
                            alt="arrow-right"
                        />
                    </>
                )}
            </RemainingUsage>
            <BarChart
                height={'6px'}
                width={`${
                    recordingQuota && recordingQuota.total > 0 ? recordingQuota.usageRatio * 100 : 0
                }%`}
                color={recordingQuota?.hasEnoughRemaining ? '#2e67fe' : '#EF6868'}
            />
            <TotalEnableUsage>
                {t.lnb.USAGE_TOTAL.replace(
                    '{{total}}',
                    secToHourMinute(recordingQuota?.total ?? 0, locale)
                )}
            </TotalEnableUsage>
        </>
    );
};

const RemainingUsage = styled.div<{ hasEnoughRemaining: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    color: ${(props) => (props.hasEnoughRemaining ? '#2e67fe' : '#ef6868')};
`;

const TotalEnableUsage = styled.div`
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 12px;
    color: #888888;
    margin-top: 6px;
`;
