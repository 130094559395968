/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { LicensePlanPricing } from '../models/LicensePlanPricing';
import type { ServiceSetting } from '../models/ServiceSetting';
import type { Term } from '../models/Term';
import type { TermLong } from '../models/TermLong';

export class ServiceService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Terms
     * @param locale 약관 언어, ko, en, ...
     * @param categories 약관 category 목록
     * @param version 약관 version
     * @returns Term Successful Response
     * @throws ApiError
     */
    public getTermsV1TermsGet(
        locale?: string,
        categories?: Array<
            'service' | 'privacy_agreement' | 'marketing' | 'addendum_save_phone_number' | 'privacy'
        >,
        version?: number
    ): CancelablePromise<Array<Term>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/terms',
            query: {
                locale: locale,
                categories: categories,
                version: version,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Term
     * @param termId
     * @returns TermLong Successful Response
     * @throws ApiError
     */
    public getTermV1TermsTermIdGet(termId: number): CancelablePromise<TermLong> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/terms/{term_id}',
            path: {
                term_id: termId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Service Settings
     * @returns ServiceSetting Successful Response
     * @throws ApiError
     */
    public getServiceSettingsV1SettingsGet(): CancelablePromise<ServiceSetting> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/settings',
        });
    }

    /**
     * Get Pricing
     * 현재 라이센스 요금을 조회합니다.
     * @returns LicensePlanPricing Successful Response
     * @throws ApiError
     */
    public getPricingV1LicensePricingGet(): CancelablePromise<LicensePlanPricing> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/license/pricing',
        });
    }
}
