import { PropsWithChildren, createContext, useEffect, useRef } from 'react';

interface State {
    isPopState: boolean;
}

export const RouterStateContext = createContext<State>({
    isPopState: undefined,
});

export const RouterStateContextProvider: React.FC<PropsWithChildren> = (props) => {
    const isPopStateRef = useRef<boolean>(false);

    useEffect(() => {
        window.history.scrollRestoration = 'manual';
        window.onpopstate = () => {
            isPopStateRef.current = true;
        };

        const pushState = history.pushState.bind(history);
        history.pushState = (...params) => {
            pushState(...params);
            isPopStateRef.current = false;
        };

        return () => {
            window.onpopstate = undefined;
            history.pushState = pushState;
        };
    }, []);

    return (
        <RouterStateContext.Provider value={{ isPopState: isPopStateRef.current }}>
            {props.children}
        </RouterStateContext.Provider>
    );
};
