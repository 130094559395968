/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum WorkspaceStatus {
    CREATED = 'created',
    DEACTIVATED = 'deactivated',
}
