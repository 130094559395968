import Media from './Media';
import styled from '@emotion/styled';

export class Style {
    static CommonContentContainer = styled.div`
        width: 100%;
        height: 100%;
        position: relative;
        background: #ffffff;
        padding: 24px 24px 0 24px;
        box-sizing: border-box;

        ${Media.forMobile(`
            padding: 0;
        `)}
    `;

    static BodyContainer = styled.div<{ bgColor?: string }>`
        width: 100%;
        min-height: 100%;
        background: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `;

    static ListContainer = styled.ul`
        height: calc(100% - 81px);
        padding-bottom: 24px;
        box-sizing: border-box;
        overflow-y: scroll;

        ${Media.forDesktop(`
            width: calc(100% + 14px);
            padding-right: 11px;
        `)}

        ${Media.forMobile(`
            width: 100%;
            height: calc(100% - 66px);
            padding: 0;
            box-sizing: border-box;

            li::marker {
                color: transparent;
            }
        `)}
    `;

    static FormContainer = styled.form`
        width: 290px;
        max-width: calc(100vw - 72px);
    `;

    static FormTitle = styled.h1`
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #333333;
        margin: 0;
    `;

    static TextInput = styled.input`
        box-sizing: border-box;
        width: 100%;
        padding: 15px 20px;
        height: 44px;
        background: #f6f6f6;
        border-radius: 4px;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 14px;
        color: #333333;
        caret-color: #2e67fe;

        &::placeholder {
            color: #bbbbbb;
        }

        &:focus {
            outline: 2px solid #2e67fe;
        }

        &:disabled {
            background: #f1f7ff;
        }
    `;

    static InputAlert = styled.p`
        color: #ef6868;
        font-size: 0.625rem;
        margin-top: 4px;
        font-weight: 400;
        line-height: 14px;
    `;

    static SubmitButton = styled.button<{ disabled: boolean }>(
        {
            width: '100%',
            height: '44px',
            borderRadius: '8px',
            border: 'none',
            fontWeight: 700,
            fontSize: '0.875rem',
            lineHeight: '14px',
            textAlign: 'center',
            padding: '15px 20px',
            color: ' #FFFFFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        (props) =>
            props.disabled
                ? {
                      background: '#DDDDDD',
                      cursor: 'default',
                  }
                : {
                      background: '#2E67FE',
                      cursor: 'pointer',
                      '&:hover': {
                          background: '#2E67FE',
                      },
                  }
    );

    static DialogHeader = styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 557px;
        max-width: 100%;
        margin: 0 0 24px 0;
        white-space: pre-line;
    `;

    static DialogTitle = styled.h1`
        font-weight: 600;
        font-size: 1rem;
        line-height: 130%;
        color: #333333;
        margin: 0;
    `;

    static DialogDescription = styled.p`
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 143%;
        color: #333333;
        margin: 0;
    `;

    static DialogButton = styled.button<{ disabled?: boolean }>`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        padding: 13px 20px;
        border-radius: 100px;
        font-weight: 600;
        font-size: 0.875rem;
        color: #ffffff;
        background: ${(props) => (props.disabled ? '#dddddd' : '#2E67FE')};
        cursor: ${(props) => props.disabled && 'default'};
    `;

    static CancelButton = styled.button`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 20px;
        height: 44px;
        background: #f6f6fc;
        border-radius: 100px;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 17px;
        text-align: center;
        color: #2e67fe;
    `;

    static AlertDialogWrapper = styled.div`
        width: calc(290px - 48px);
        max-width: calc(290px - 48px);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 143%;
            color: #333333;
            margin-bottom: 12px;
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
    `;

    static EditDialogFormContainer = styled.form`
        width: calc(290px - 48px);
        display: flex;
        flex-direction: column;
        max-width: calc(100vw - 72px);

        h2 {
            font-weight: 600;
            font-size: 1rem;
            line-height: 130%;
            margin-bottom: 20px;
        }

        section:nth-of-type(2) {
            margin-top: 20px;

            input:nth-of-type(2) {
                margin-top: 8px;
            }
        }

        section {
            h3 {
                font-weight: 600;
                font-size: 0.75rem;
                line-height: 14px;
                margin-bottom: 8px;
            }
        }

        button {
            align-self: flex-end;
            margin-top: 12px;
        }
    `;

    static LogoutButton = styled.button`
        padding: 12px;
        margin: 0 auto;
        font-size: 0.75rem;
        line-height: 14px;
        color: #888888;
        text-align: center;
    `;

    static MarkDownWrapper = styled.div`
        width: calc(100% + 4px);
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 8px;

        h1 {
            font-weight: 600;
            font-size: 1rem;
            line-height: 130%;
            color: #333333;
            margin-bottom: 40px;
        }

        h2 {
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 17px;
            color: #333333;
            margin-top: 20px;
        }

        h3 {
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 17px;
            color: #333333;
            margin-top: 20px;
            margin-bottom: 12px;
        }

        h6 {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 17px;
            color: #888888;
            margin-top: 20px;
            margin-bottom: 12px;
        }

        p {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 143%;
            color: #333333;
        }

        ol {
            margin-left: 25px;
            list-style-type: decimal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 200%;
            color: #555555;
        }

        li {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 200%;
            color: #555555;
        }

        a {
            color: blue;
        }

        table {
            width: 100%;
            margin: 30px 0;
            border-collapse: collapse;
        }

        th {
            padding: 10px;
            font-weight: 600;
            font-size: 0.813rem;
            line-height: 16px;
            letter-spacing: -0.03em;
            color: #555555;
            text-align: left;
        }

        tr:nth-of-type(1) {
            border-top: 2px solid #616161;
        }

        td {
            padding: 10px;
            font-weight: 400;
            font-size: 0.688rem;
            letter-spacing: -0.03em;
            color: #555555;
            border-bottom: 1px solid #444444;
            line-height: 18px;
        }
    `;

    static DesktopOnly = styled.div`
        ${Media.forMobile(`
            display: none;
        `)}
    `;

    static MobileOnly = styled.div`
        ${Media.forDesktop(`
            display: none;
        `)}
    `;

    static DropdownMenuItem = styled.div`
        min-height: 24px;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #333333;
        padding: 14px 0;
        min-height: 52px;

        ${Media.forMobile(`
            font-weight: 600;
            padding: 0;
        `)}
    `;

    static PageTitle = styled.h1`
        font-weight: 600;
        font-size: 1rem;
        line-height: 19px;
        color: #333333;
        margin-top: 8.5px;
        margin-bottom: 20px;
    `;

    static PageSubTitle = styled.h2`
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 19px;
        color: #333333;
    `;

    static CopyButton = styled.button`
        padding: 4px 8px;
        border-radius: 4px;
        border: 1px solid #eee;
        background: #fff;
        color: #999;
        font-size: 0.75rem;
        font-weight: 600;
        white-space: nowrap;
        flex-shrink: 0;
        ${Media.forMobile(`
            padding: 4px;
            font-weight: 400;
        `)}
    `;
}
