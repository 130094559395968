import { AuthTokenStorage } from './AuthTokenStorage';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { LoginResponse } from 'callabo-api/src';
import EventEmitter from 'eventemitter3';
import { dateIsOver } from 'libs/rtzr-commons/DateUtils';

type EventTypes = 'unauthorized' | 'serviceUnavaliable';

export class AuthTokenManager {
    private _requestQueue: RequestQueue = undefined;
    private _eventEmitter: EventEmitter<EventTypes> = new EventEmitter<EventTypes>();
    public get axiosInstance(): AxiosInstance {
        return this._axiosInstance;
    }

    public get auth(): AuthTokenStorage {
        return this._auth;
    }

    public get event(): EventEmitter<EventTypes> {
        return this._eventEmitter;
    }

    public static async createAsync(
        accessToken: string,
        refreshToken: string,
        tokenExpiredDate: Date,
        deviceId: string,
        onRefresh?: (response: LoginResponse) => void
    ): Promise<AuthTokenManager> {
        const axiosInstance = axios.create();
        const auth = await AuthTokenStorage.createAsync(
            accessToken,
            refreshToken,
            tokenExpiredDate
        );
        return new AuthTokenManager(axiosInstance, auth, deviceId, onRefresh);
    }

    public static async createForShareAsync(
        accessToken: string,
        refreshToken: string,
        tokenExpiredDate: Date,
        deviceId: string
    ): Promise<AuthTokenManager> {
        const axiosInstance = axios.create();
        const auth = await AuthTokenStorage.createForShareAsync(
            accessToken,
            refreshToken,
            tokenExpiredDate
        );
        return new AuthTokenManager(axiosInstance, auth, deviceId);
    }

    private constructor(
        private _axiosInstance: AxiosInstance,
        private _auth: AuthTokenStorage,
        private _deviceId: string,
        onRefresh?: (response: LoginResponse) => void
    ) {
        this._requestQueue = new RequestQueue(_axiosInstance, _auth);
        buildInterceptorsInVitoApiClient(
            _axiosInstance,
            _auth,
            this._requestQueue,
            this._eventEmitter,
            this._deviceId,
            onRefresh
        );
    }
}

const buildInterceptorsInVitoApiClient = (
    axiosInstance: AxiosInstance,
    auth: AuthTokenStorage,
    requestSubscriber: RequestQueue,
    eventEmitter: EventEmitter<EventTypes>,
    deviceId: string,
    onRefresh: (response: LoginResponse) => void
): void => {
    const successRequest = async function (config) {
        const tokenExpiredDate = await auth.getAccessTokenExpiredDate();
        if (dateIsOver(new Date(tokenExpiredDate))) {
            const loginResponse = await auth.refreshAsync();
            if (onRefresh && loginResponse) {
                // console.log('onRefresh call');
                // console.log(loginResponse);
                onRefresh(loginResponse);
            }
        }

        const accessToken = await auth.getAccessTokenAsync();
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers['X-Header-DeviceId'] = deviceId;
        return config;
    };
    const errorRequest: (error: any) => any = function (error) {
        return Promise.reject(error);
    };
    axiosInstance.interceptors.request.use(successRequest, errorRequest);

    const successResponse = function (response) {
        return response;
    };
    const errorResponse: (error: any) => any = refreshIfNotAuthorizedAsync;
    axiosInstance.interceptors.response.use(successResponse, errorResponse);

    async function refreshIfNotAuthorizedAsync(error: any) {
        // console.log(`on refresh? ${!!onRefresh}`);
        // console.log(error.response?.status);
        if (error.response && error.response.status === 401) {
            if (auth.isRefreshing) {
                const { config }: { config: AxiosRequestConfig } = error;
                return new Promise((resolve, reject) => {
                    requestSubscriber.add({
                        resolve,
                        reject,
                        error,
                        config,
                    });
                });
            }

            // console.log(`refresh try`);
            try {
                const loginResponse = await auth.refreshAsync();
                if (onRefresh && loginResponse) {
                    // console.log('onRefresh call');
                    // console.log(loginResponse);
                    onRefresh(loginResponse);
                }
            } catch (error) {
                // console.error(error);
                requestSubscriber.clear();
                eventEmitter.emit('unauthorized');
                return;
            }

            try {
                // console.log(`requestSubscriber retry`);
                await requestSubscriber.runRequestAsync();
                const { config }: { config: AxiosRequestConfig } = error;
                return retryOriginRequestAsync(axiosInstance, auth, config);
            } catch (e) {
                // console.error(e);
                requestSubscriber.runThrowError();
                return Promise.reject(error);
            }
        }

        if (error.response && error.response.status === 503) {
            eventEmitter.emit('serviceUnavaliable');
        }

        return Promise.reject(error);
    }
};

interface RequestQueueItem {
    resolve: (value: unknown) => void;
    reject: (error: any) => void;
    error: any;
    config: AxiosRequestConfig;
}

/**
 * 요청을 모아서 처리하는 클래스
 */
class RequestQueue {
    private _requestQueue: RequestQueueItem[] = [];

    constructor(private _axiosInstance: AxiosInstance, private _auth: AuthTokenStorage) {}

    public add = (request: RequestQueueItem): void => {
        this._requestQueue.push(request);
    };

    public runRequestAsync = async (): Promise<void> => {
        while (this._requestQueue.length) {
            const request = this._requestQueue.pop();
            const originRequest = await retryOriginRequestAsync(
                this._axiosInstance,
                this._auth,
                request.config
            );
            request.resolve(originRequest);
        }
    };

    public runThrowError = (): void => {
        while (this._requestQueue.length) {
            const request = this._requestQueue.pop();
            request.reject(request.error);
        }
    };

    public clear = (): void => {
        this._requestQueue.splice(0, this._requestQueue.length);
    };
}

async function retryOriginRequestAsync(
    axiosInstance: AxiosInstance,
    auth: AuthTokenStorage,
    config: AxiosRequestConfig
) {
    // console.log(`retryOriginRequestAsync ${config.url}`);
    const accessToken = await auth.getAccessTokenAsync();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return axiosInstance(config);
}
