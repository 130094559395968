import { useRouterQuery } from '../hooks/useRouterQuery';
import { AuthTokenManager } from '@/api/AuthTokenManager';
import { ApiClientContext } from '@/contexts/ApiClientContext';
import * as Sentry from '@sentry/nextjs';
import { SharedRecordAuthResponse, SharedRecordAuthentication } from 'callabo-api/src';
import { CallaboApiClient } from 'callabo-api/src/CallaboApiClient';
import { SharedRecordModel } from 'libs/callabo-state/models/SharedRecordModel';
import { getOrCreateDeviceIdAsync } from 'libs/rtzr-commons/FingerprintUtils';
import useEventCallback from 'libs/rtzr-commons/useEventCallback';
import React, { createContext, useEffect, useState } from 'react';
import { CALLABO_API_BASE_URL } from 'src/modules/Paths';

export type AuthState =
    | {
          type: 'loading';
      }
    | {
          type: 'signedIn';
          callaboApiClient: CallaboApiClient;
          token: {
              accessToken: string;
              refreshToken: string;
              expiredDate: Date;
          };
          sharedRecord: SharedRecordModel;
      }
    | {
          type: 'signedOut';
          callaboApiClient: CallaboApiClient;
      };

interface IState {
    authState: AuthState;
    workspaceId: string;
    sharedId: string;
    password: string;
    authAsync: ({ workspaceId, sharedId, password }: AuthProps) => Promise<AuthAsyncResult>;
}

interface IProps {
    children?: React.ReactNode;
}

interface AuthProps {
    workspaceId: string;
    sharedId: string;
    password: string;
}

type AuthAsyncResult = SharedRecordAuthResponse | 'inCorrect' | 'tooMany' | 'fail';

export const ShareAuthContext = createContext<IState>({
    authState: { type: 'loading' },
    workspaceId: undefined,
    sharedId: undefined,
    password: undefined,
    authAsync: () => undefined,
});

export const ShareAuthProvider: React.FC<IProps> = (props: IProps) => {
    const [authState, setAuthState] = useState<AuthState>({ type: 'loading' });
    const [password, setPassword] = useState<string>();
    const [workspaceId] = useRouterQuery('workspaceId');
    const [sharedId] = useRouterQuery('sharedId');
    const [pw, , deletePassword] = useRouterQuery('pw');

    useEffect(() => {
        try {
            if (pw) setPassword(atob(decodeURIComponent(pw as string)));
        } catch (e) {
            deletePassword();
        }
    }, [pw]);

    const authAsync = useEventCallback(
        async ({ workspaceId, sharedId, password }: AuthProps): Promise<AuthAsyncResult> => {
            try {
                const authResponse = await new CallaboApiClient({
                    BASE: CALLABO_API_BASE_URL,
                }).user.authV1UserAuthPost({
                    grant_type: SharedRecordAuthentication.grant_type.SHARED_RECORD,
                    slug: workspaceId,
                    share_id: sharedId,
                    password: password,
                });
                const { token } = authResponse as SharedRecordAuthResponse;
                if (token?.expires_in > 0) {
                    await onSignIn(authResponse as SharedRecordAuthResponse);
                    return authResponse as SharedRecordAuthResponse;
                }
                return 'fail';
            } catch (e) {
                await onSignOut();
                if (e.status === 400) {
                    return 'inCorrect';
                }
                if (e.status === 429) {
                    return 'tooMany';
                }
                Sentry.captureException(e);
                return 'fail';
            }
        }
    );

    const onSignIn = async (authResponse: SharedRecordAuthResponse) => {
        const accessToken = authResponse.token.access_token;
        const refreshToken = authResponse.token.refresh_token;
        const tokenCreateAt = authResponse.token.created_at;
        const tokenExpiredDate = new Date(tokenCreateAt);
        tokenExpiredDate.setSeconds(
            tokenExpiredDate.getSeconds() + Math.round(authResponse.token.expires_in / 2)
        );

        const authTokenManager = await AuthTokenManager.createForShareAsync(
            accessToken,
            refreshToken,
            tokenExpiredDate,
            await getOrCreateDeviceIdAsync()
        );
        authTokenManager.event.on('unauthorized', onSignOut);

        setAuthState({
            type: 'signedIn',
            callaboApiClient: new CallaboApiClient({
                BASE: CALLABO_API_BASE_URL,
                TOKEN: accessToken,
                INSTANCE: authTokenManager.axiosInstance,
            }),
            token: {
                accessToken,
                refreshToken,
                expiredDate: tokenExpiredDate,
            },
            sharedRecord: new SharedRecordModel(authResponse.shared_record),
        });
    };

    const onSignOut = async () => {
        setAuthState({
            type: 'signedOut',
            callaboApiClient: new CallaboApiClient({ BASE: CALLABO_API_BASE_URL }),
        });
    };

    return (
        <ShareAuthContext.Provider
            value={{
                authState,
                workspaceId,
                sharedId,
                password,
                authAsync,
            }}
        >
            <ApiClientContext.Provider
                value={
                    authState.type === 'loading'
                        ? new CallaboApiClient({ BASE: CALLABO_API_BASE_URL })
                        : authState.callaboApiClient
                }
            >
                {props.children}
            </ApiClientContext.Provider>
        </ShareAuthContext.Provider>
    );
};
