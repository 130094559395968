/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserLocale {
    KO = 'ko',
    JA = 'ja',
    EN = 'en',
}
