import { ApiClientContext } from './ApiClientContext';
import { FirebaseContext } from './FirebaseContext';
import { PathName } from '@/modules/PathName';
import { useSafeNavigator } from '@/modules/SafeNavigator';
import { removeCookies, setCookie } from '@/utils/BrowserCookie';
import * as Sentry from '@sentry/nextjs';
import {
    AgreementBase,
    AppleAuthentication,
    Body_signup_v1_user_post,
    Device,
    EmailAuthentication,
    GoogleAuthentication,
    LoginResponse,
    MicrosoftAuthentication,
    UserStatus,
    UserUpdateRequest,
} from 'callabo-api/src';
import { RefreshAuthentication } from 'callabo-api/src//models/RefreshAuthentication';
import { CallaboApiClient } from 'callabo-api/src/CallaboApiClient';
import { ApiError } from 'callabo-api/src/core/ApiError';
import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { UserModel } from 'libs/callabo-state/models/UserModel';
import { Phase } from 'libs/callabo-state/types/Phase';
import { RecordsTabType } from 'libs/callabo-state/types/RecordsTabType';
import { dateIsOver } from 'libs/rtzr-commons/DateUtils';
import { getOrCreateDeviceIdAsync } from 'libs/rtzr-commons/FingerprintUtils';
import getIndexedDbStorageAsync from 'libs/rtzr-commons/IndexedDbStorage';
import analyticsInstance from 'libs/rtzr-commons/modules/marketing/Analytics';
import useEventCallback from 'libs/rtzr-commons/useEventCallback';
import getConfig from 'next/config';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AuthTokenManager } from 'src/api/AuthTokenManager';
import { CALLABO_API_BASE_URL, Paths } from 'src/modules/Paths';
import { LoginFormValue } from 'src/ui/Login/LoginForm';

export class AuthResponseError extends Error {
    constructor(message?: string) {
        super(message);

        // Set the prototype explicitly.
        // https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
        Object.setPrototypeOf(this, AuthResponseError.prototype);
    }
}

export class InvalidInvitationTokenError extends AuthResponseError {
    constructor(message?: string) {
        super(message);

        // Set the prototype explicitly.
        // https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
        Object.setPrototypeOf(this, InvalidInvitationTokenError.prototype);
    }
}
export class UnownedInvitationTokenError extends AuthResponseError {
    constructor(message?: string) {
        super(message);

        // Set the prototype explicitly.
        // https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
        Object.setPrototypeOf(this, UnownedInvitationTokenError.prototype);
    }
}

export class DuplicatedEmailError extends AuthResponseError {
    constructor(message?: string) {
        super(message);

        // Set the prototype explicitly.
        // https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
        Object.setPrototypeOf(this, DuplicatedEmailError.prototype);
    }
}

export class RateLimitedError extends AuthResponseError {
    constructor(message?: string) {
        super(message);

        // Set the prototype explicitly.
        // https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
        Object.setPrototypeOf(this, RateLimitedError.prototype);
    }
}

export class InvalidCredentialsError extends AuthResponseError {
    constructor(message?: string) {
        super(message);

        // Set the prototype explicitly.
        // https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
        Object.setPrototypeOf(this, InvalidCredentialsError.prototype);
    }
}

const AUTH_ERROR_CODE_EXCEPTIONS = [
    'auth/popup-closed-by-user',
    'auth/user-cancelled',
    'auth/cancelled-popup-request',
    'auth/popup-blocked',
    'installations/app-offline',
];

export type AuthState =
    | {
          type: 'loading';
      }
    | {
          type: 'signedIn';
          callaboApiClient: CallaboApiClient;
          token: {
              accessToken: string;
              refreshToken: string;
              expiredDate: Date;
          };
          user: UserModel;
      }
    | {
          type: 'signedOut';
          callaboApiClient: CallaboApiClient;
      };

interface IState {
    authState: AuthState;
    signUpFinishAsync: (
        greements: Array<AgreementBase>,
        name: string,
        company: string,
        token?: string
    ) => Promise<void>;
    signUpEmailAsync: (data: Body_signup_v1_user_post, token?: string) => Promise<void>;
    loginGoogleAsync: (token?: string, loginHint?: string) => Promise<void>;
    loginMicrosoftAsync: (token?: string, loginHint?: string) => Promise<void>;
    loginAppleAsync: (token?: string, loginHint?: string) => Promise<void>;
    loginEmailAsync: (data: LoginFormValue) => Promise<void>;

    modifyUserAsync: (data: UserUpdateRequest) => Promise<void>;
    logoutAsync: (redirect?: string) => Promise<void>;
    withdrawAsync: () => Promise<void>;
}
interface IProps {
    children?: React.ReactNode;
}

export const AuthContext = createContext<IState>({
    authState: { type: 'loading' },
    signUpFinishAsync: () => undefined,
    signUpEmailAsync: () => undefined,
    loginGoogleAsync: () => undefined,
    loginMicrosoftAsync: () => undefined,
    loginAppleAsync: () => undefined,
    loginEmailAsync: () => undefined,

    modifyUserAsync: () => undefined,
    logoutAsync: () => undefined,
    withdrawAsync: () => undefined,
});

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const PHASE = serverRuntimeConfig.PHASE || publicRuntimeConfig.PHASE;

/**
 * auth에 대해서만 관리
 * @param props
 * @returns
 */
export const AuthProvider: React.FC<IProps> = (props: IProps) => {
    const [authState, setAuthState] = useState<AuthState>({ type: 'loading' });
    const { pushToken } = useContext(FirebaseContext);
    const navigator = useSafeNavigator();

    const onSignIn = async (loginResponse: LoginResponse) => {
        const accessToken = loginResponse.token.access_token;
        const refreshToken = loginResponse.token.refresh_token;
        const grantType = loginResponse.user.provider;
        const tokenCreateAt = loginResponse.token.created_at;
        const tokenExpiredDate = new Date(tokenCreateAt);
        tokenExpiredDate.setSeconds(
            tokenExpiredDate.getSeconds() + Math.round(loginResponse.token.expires_in / 2)
        );

        const authTokenManager = await AuthTokenManager.createAsync(
            accessToken,
            refreshToken,
            tokenExpiredDate,
            await getOrCreateDeviceIdAsync(),
            async (response) => {
                try {
                    await onSignIn(response);
                } catch (e) {
                    onSignOut();
                }
            }
        );
        authTokenManager.event.on('unauthorized', onSignOut);

        const storage = await getIndexedDbStorageAsync();
        await storage.setAsync('grant_type', grantType);
        const isOnboardingCleared = await storage.getAsync('skip_onboarding');
        const tooltipList = isOnboardingCleared
            ? ['member', 'install_app_prompt'] // onboarding을 마친 경우 공유 툴팁은 보여주지 않음
            : ['member', 'share', 'install_app_prompt'];
        await storage.setAsync('tutorial', {
            tooltips: tooltipList,
        });
        await storage.setAsync('tab', RecordsTabType.All);
        await storage.setAsync('new_popup_enable', isOnboardingCleared ? true : false);

        setAuthState({
            type: 'signedIn',
            callaboApiClient: new CallaboApiClient({
                BASE: CALLABO_API_BASE_URL,
                TOKEN: accessToken,
                INSTANCE: authTokenManager.axiosInstance,
            }),
            user: new UserModel(loginResponse.user),
            token: {
                accessToken,
                refreshToken,
                expiredDate: tokenExpiredDate,
            },
        });

        // user locale을 브라우저에 업데이트
        setCookie('locale', loginResponse.user.locale, 30 * 24 * 60 * 60);
        setCookie('access_token', accessToken, loginResponse.token.expires_in / 2);
        setCookie('refresh_token', refreshToken, 30 * 24 * 60 * 60);
        setCookie('tab', RecordsTabType.All);

        // FIXME: 기존 prefix없는 locale 쿠키 사용할때까지는 유지
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30); // 쿠키 만료일: 30일
        const cookieString = `locale=${
            loginResponse.user.locale
        }; expires=${expiryDate.toUTCString()}; path=/`;
        document.cookie = cookieString;
    };

    const onSignOut = useEventCallback(async () => {
        const storage = await getIndexedDbStorageAsync();
        await storage.removeMany([
            'access_token',
            'refresh_token',
            'token_expired_date',
            'grant_type',
            'tutorial',
            'tab',
            'labels', // deprecated
        ]);

        removeCookies([
            'locale',
            'access_token',
            'refresh_token',
            'tab',
            'labels', // deprecated
            'workspaces_label_ids',
            'last_used_workspace_id',
        ]);
        setAuthState({
            type: 'signedOut',
            callaboApiClient: new CallaboApiClient({ BASE: CALLABO_API_BASE_URL }),
        });
    });

    useEffect(() => {
        (async () => {
            if (authState.type !== 'loading') {
                return;
            }
            try {
                const storage = await getIndexedDbStorageAsync();
                // FIXME: CUE) token 설정을 위해 임시로 설정함
                // 2년째 임시로 설정한 코드가 남아있는 개그
                const accessToken = await storage.getAsync<string>('access_token');
                const refreshToken = await storage.getAsync<string>('refresh_token');
                const tokenExpiredDate = await storage.getAsync<string>('token_expired_date');
                if (!accessToken || !tokenExpiredDate || !refreshToken) {
                    await onSignOut();
                    return;
                }
                const callaboApiClient = new CallaboApiClient({
                    BASE: CALLABO_API_BASE_URL,
                });
                if (dateIsOver(new Date(tokenExpiredDate))) {
                    const refreshResponse = await callaboApiClient.user.authV1UserAuthPost({
                        grant_type: RefreshAuthentication.grant_type.REFRESH_TOKEN,
                        refresh_token: refreshToken,
                    });
                    await onSignIn(refreshResponse as LoginResponse);
                    return;
                }

                const authTokenManager = await AuthTokenManager.createAsync(
                    accessToken,
                    refreshToken,
                    new Date(tokenExpiredDate),
                    await getOrCreateDeviceIdAsync(),
                    async (response) => {
                        try {
                            await onSignIn(response);
                        } catch (e) {
                            onSignOut();
                        }
                    }
                );

                const client = new CallaboApiClient({
                    BASE: CALLABO_API_BASE_URL,
                    TOKEN: accessToken,
                    INSTANCE: authTokenManager.axiosInstance,
                });

                const userResponse = await client.user.whoAmIV1UserMeGet();
                const user = new UserModel(userResponse);

                await storage.setAsync('grant_type', user.provider);
                setAuthState({
                    type: 'signedIn',
                    callaboApiClient: new CallaboApiClient({
                        BASE: CALLABO_API_BASE_URL,
                        TOKEN: accessToken,
                        INSTANCE: authTokenManager.axiosInstance,
                    }),
                    user,
                    token: {
                        accessToken,
                        refreshToken,
                        expiredDate: new Date(tokenExpiredDate),
                    },
                });
            } catch (error) {
                await onSignOut();
            }
        })();
    }, [authState.type]);

    useEffect(() => {
        if (authState.type !== 'signedIn') return;
        const { user } = authState;
        analyticsInstance.setUserId(user.id?.toString());
    }, [authState.type]);

    useEffect(() => {
        (async () => {
            if (!pushToken) return;
            if (authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            const deviceId = await getOrCreateDeviceIdAsync();
            await callaboApiClient.user.pushTokenV1UserPushTokenPost({
                device_id: deviceId,
                platform: Device.platform.WEB,
                push_token: pushToken,
            });
        })();
    }, [pushToken, authState]);

    useEffect(() => {
        const hideUserPrivacy = PHASE === Phase.production;
        switch (authState.type) {
            case 'signedIn': {
                const { user } = authState;
                Sentry.setUser({
                    email: hideUserPrivacy ? undefined : user.email,
                    name: hideUserPrivacy ? undefined : user.name,
                    username: hideUserPrivacy ? `${user.id}` : user.email,
                    uid: `${user.id}`,
                });
                break;
            }
            default: {
                Sentry.setUser(undefined);
            }
        }
    }, [authState]);

    const signUpEmailAsync = useCallback(
        async (data: Body_signup_v1_user_post, token?: string): Promise<void> => {
            if (authState.type !== 'signedOut') {
                throw new Error('Invalid auth state');
            }
            const { callaboApiClient } = authState;
            let loginResponse: LoginResponse;
            try {
                loginResponse = await callaboApiClient.user.signupV1UserPost({
                    user: data.user,
                    agreements: data.agreements,
                    invitation_token: token ? token : undefined,
                });
            } catch (e) {
                await onSignOut();
                if (e instanceof ApiError) {
                    switch (e.status) {
                        case 409: {
                            throw new DuplicatedEmailError();
                        }
                        case 429: {
                            throw new RateLimitedError();
                        }
                    }
                }
                alert(`[${e.status}] ${e.message}`);
                throw e;
            }
            await onSignIn(loginResponse as LoginResponse);
        },
        [authState]
    );

    const loginEmailAsync = useEventCallback(async (data: any): Promise<void> => {
        if (authState.type !== 'signedOut') return;
        const { callaboApiClient } = authState;
        let authResponse: LoginResponse;
        try {
            authResponse = (await callaboApiClient.user.authV1UserAuthPost({
                grant_type: EmailAuthentication.grant_type.EMAIL,
                email: data.email,
                password: data.password,
            })) as LoginResponse;
        } catch (e) {
            await onSignOut();
            if (e instanceof ApiError) {
                switch (e.status) {
                    case 400:
                    case 422: {
                        throw new InvalidCredentialsError();
                    }
                    case 429: {
                        throw new RateLimitedError();
                    }
                }
            }
            alert(`[${e.status}] ${e.message}`);
            throw e;
        }
        await onSignIn(authResponse);
    });

    const signUpFinishAsync = useEventCallback(
        async (agreements: Array<AgreementBase>, name: string, company: string): Promise<void> => {
            if (authState.type !== 'signedIn') return;
            const { callaboApiClient, user } = authState;
            try {
                if (user.userStatus !== UserStatus.CREATED || user.provider === 'email') {
                    await onSignOut();
                    throw Error('Invalid user status');
                }
                await callaboApiClient.user.acceptTermV1UserAcceptancePatch(agreements);
                await callaboApiClient.user.modifyV1UserPatch({
                    name: name,
                    company: company,
                });
            } catch (e) {
                await onSignOut();
                alert(`[${e.status}] ${e.message}`);
                throw e;
            }
        }
    );

    const loginGoogleAsync = useEventCallback(
        async (token?: string, loginHint?: string): Promise<void> => {
            if (authState.type !== 'signedOut') return;
            const { callaboApiClient } = authState;
            const provider = new GoogleAuthProvider();
            loginHint &&
                provider.setCustomParameters({
                    login_hint: loginHint,
                });
            const auth = getAuth();
            let idToken: string;
            try {
                await signInWithPopup(auth, provider);
                const currentUser = auth.currentUser;
                idToken = await currentUser.getIdToken();
            } catch (e) {
                // firebase auth error인 경우 오류로 처리하지 않음
                if (AUTH_ERROR_CODE_EXCEPTIONS.includes(e.code)) return;
                analyticsInstance.logEvent('action_google_auth_fail', {
                    code: e.code,
                });
                throw e;
            }
            let loginResponse: LoginResponse;
            try {
                loginResponse = (await callaboApiClient.user.authV1UserAuthPost({
                    grant_type: GoogleAuthentication.grant_type.GOOGLE,
                    id_token: idToken,
                    invitation_token: token ? token : undefined,
                })) as LoginResponse;
            } catch (e) {
                await onSignOut();
                if (e instanceof ApiError) {
                    switch (e.status) {
                        case 401: {
                            throw new InvalidInvitationTokenError();
                        }
                        case 403: {
                            throw new UnownedInvitationTokenError();
                        }
                        case 409: {
                            throw new DuplicatedEmailError();
                        }
                        case 429: {
                            throw new RateLimitedError();
                        }
                    }
                }
                alert(`[${e.status}] ${e.message}`);
                throw e;
            }
            await onSignIn(loginResponse);
        }
    );

    const loginMicrosoftAsync = useEventCallback(
        async (token?: string, loginHint?: string): Promise<void> => {
            if (authState.type !== 'signedOut') return;
            const { callaboApiClient } = authState;
            const provider = new OAuthProvider('microsoft.com');
            loginHint &&
                provider.setCustomParameters({
                    login_hint: loginHint,
                });
            const auth = getAuth();
            let idToken: string;
            try {
                await signInWithPopup(auth, provider);
                const currentUser = auth.currentUser;
                idToken = await currentUser.getIdToken();
            } catch (e) {
                // firebase auth error인 경우 오류로 처리하지 않음
                if (AUTH_ERROR_CODE_EXCEPTIONS.includes(e.code)) return;
                analyticsInstance.logEvent('action_microsoft_auth_fail', {
                    code: e.code,
                });
                // 이 에러에 대해선 특별처리
                if (e.code == 'auth/account-exists-with-different-credential') {
                    throw new DuplicatedEmailError();
                }
                throw e;
            }
            let loginResponse: LoginResponse;
            try {
                loginResponse = (await callaboApiClient.user.authV1UserAuthPost({
                    grant_type: MicrosoftAuthentication.grant_type.MICROSOFT,
                    id_token: idToken,
                    invitation_token: token ? token : undefined,
                })) as LoginResponse;
            } catch (e) {
                await onSignOut();
                if (e instanceof ApiError) {
                    switch (e.status) {
                        case 401: {
                            throw new InvalidInvitationTokenError();
                        }
                        case 403: {
                            throw new UnownedInvitationTokenError();
                        }
                        case 409: {
                            throw new DuplicatedEmailError();
                        }
                        case 429: {
                            throw new RateLimitedError();
                        }
                    }
                }
                alert(`[${e.status}] ${e.message}`);
                throw e;
            }
            await onSignIn(loginResponse);
        }
    );

    const loginAppleAsync = useEventCallback(
        async (token?: string, loginHint?: string): Promise<void> => {
            if (authState.type !== 'signedOut') return;
            const { callaboApiClient } = authState;
            const provider = new OAuthProvider('apple.com');
            loginHint &&
                provider.setCustomParameters({
                    login_hint: loginHint,
                });
            const auth = getAuth();
            let idToken: string;
            try {
                await signInWithPopup(auth, provider);
                const currentUser = auth.currentUser;
                idToken = await currentUser.getIdToken();
            } catch (e) {
                // user가 인증을 중단한 경우 오류로 처리하지 않음
                if (AUTH_ERROR_CODE_EXCEPTIONS.includes(e.code)) return;
                analyticsInstance.logEvent('action_apple_auth_fail', {
                    code: e.code,
                });
                throw e;
            }
            let loginResponse: LoginResponse;
            try {
                loginResponse = (await callaboApiClient.user.authV1UserAuthPost({
                    grant_type: AppleAuthentication.grant_type.APPLE,
                    id_token: idToken,
                    invitation_token: token ? token : undefined,
                })) as LoginResponse;
            } catch (e) {
                await onSignOut();
                if (e instanceof ApiError) {
                    switch (e.status) {
                        case 401: {
                            throw new InvalidInvitationTokenError();
                        }
                        case 403: {
                            throw new UnownedInvitationTokenError();
                        }
                        case 409: {
                            throw new DuplicatedEmailError();
                        }
                        case 429: {
                            throw new RateLimitedError();
                        }
                    }
                }
                alert(`[${e.status}] ${e.message}`);
                throw e;
            }
            await onSignIn(loginResponse);
        }
    );

    const modifyUserAsync = useCallback(
        async (data: UserUpdateRequest) => {
            if (authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            const response = await callaboApiClient.user.modifyV1UserPatch(data);
            setAuthState({
                ...authState,
                user: new UserModel(response),
            });
        },
        [authState]
    );

    const logoutAsync = useCallback(
        async (redirect?: string): Promise<any> => {
            if (authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            try {
                //pushToken 없으면 body 안보냄
                await callaboApiClient.user.logoutV1UserLogoutPost(
                    pushToken
                        ? {
                              device: {
                                  platform: Device.platform.WEB,
                                  device_id: await getOrCreateDeviceIdAsync(),
                                  push_token: pushToken,
                              },
                          }
                        : undefined
                );
            } finally {
                await onSignOut();
                await navigator.push(
                    redirect ??
                        new PathName(Paths.Index).setLocale(authState.user.locale).toString()
                );
            }
        },
        [authState, pushToken]
    );

    const withdrawAsync = useCallback(async (): Promise<void> => {
        if (authState.type !== 'signedIn') return;
        const { callaboApiClient } = authState;
        try {
            await callaboApiClient.user.withdrawV1UserDelete();
            await onSignOut();
            await navigator.push(
                new PathName(Paths.Index).setLocale(authState.user.locale).toString()
            );
        } catch (e) {
            alert(`[${e.status}] ${e.message}`);
            throw e;
        }
    }, [authState]);

    return (
        <AuthContext.Provider
            value={{
                authState,
                signUpFinishAsync,
                signUpEmailAsync,
                loginGoogleAsync,
                loginMicrosoftAsync,
                loginAppleAsync,
                loginEmailAsync,
                logoutAsync,
                modifyUserAsync,
                withdrawAsync,
            }}
        >
            <ApiClientContext.Provider
                value={
                    authState.type === 'loading'
                        ? new CallaboApiClient({ BASE: CALLABO_API_BASE_URL })
                        : authState.callaboApiClient
                }
            >
                {props.children}
            </ApiClientContext.Provider>
        </AuthContext.Provider>
    );
};
