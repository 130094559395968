import Script from 'next/script';
import { useEffect } from 'react';

declare global {
    interface Window {
        ChannelIO?: IChannelIO;
    }
}

interface IChannelIO {
    c?: (...args: any) => void;
    q?: [methodName: string, ...args: any[]][];
    (...args: any): void;
}
interface Props {
    show: boolean;
}

const CHANNEL_TALK_PLUGIN_KEY = '8a6804e8-a7fc-4a7a-8955-87d8a915959e';
const CHANNEL_TALK_LOADING_SCRIPT = `(function(){var w=window;if(w.ChannelIO){return w.console.error("ChannelIO script included twice.")}var ch=function(){ch.c(arguments)};ch.q=[];ch.c=function(args){ch.q.push(args)};w.ChannelIO=ch;function l(){if(w.ChannelIOInitialized){return}w.ChannelIOInitialized=true;var s=document.createElement("script");s.type="text/javascript";s.async=true;s.src="https://cdn.channel.io/plugin/ch-plugin-web.js";var x=document.getElementsByTagName("script")[0];if(x.parentNode){x.parentNode.insertBefore(s,x)}}if(document.readyState==="complete"){l()}else{w.addEventListener("DOMContentLoaded",l);w.addEventListener("load",l)}})();`;

export function ChannelTalk({ show }: Props): JSX.Element {
    useEffect(() => {
        window.ChannelIO?.('boot', {
            pluginKey: CHANNEL_TALK_PLUGIN_KEY,
            hideChannelButtonOnBoot: !show,
        });
    }, [show]);

    return (
        <Script
            id="channeltalk"
            dangerouslySetInnerHTML={{
                __html: CHANNEL_TALK_LOADING_SCRIPT,
            }}
        />
    );
}

export function openChannelTalk(): void {
    window.ChannelIO?.('openChat', '', '');
}
