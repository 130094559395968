import { Modal, ModalProps } from 'libs/callabo-ui/common/Modal/Modal';
import { PropsWithChildren, useCallback, useState } from 'react';

export type UseModalProps = Omit<ModalProps, 'children'>;

export function useModal(
    props: UseModalProps = {}
): [React.FC<any>, () => void, () => void, boolean] {
    const { onClose, maskClosable = true, closable = true } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const openModal = useCallback(() => {
        setModalVisible(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalVisible(false);
    }, []);

    const ModalComponent: React.FC<PropsWithChildren> = useCallback(
        (props) => {
            return (
                <>
                    {modalVisible && (
                        <Modal
                            onClose={() => {
                                closeModal();
                                if (onClose) onClose();
                            }}
                            maskClosable={maskClosable}
                            closable={closable}
                        >
                            {props.children}
                        </Modal>
                    )}
                </>
            );
        },
        [closable, maskClosable, modalVisible]
    );

    return [ModalComponent, openModal, closeModal, modalVisible];
}
