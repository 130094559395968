import { AuthContext } from '../contexts/AuthContext';
import './app.css';
import '/src/notion/styles/global.css';
// this might be better for dark mode
// import 'prismjs/themes/prism-okaidia.css'
// global style overrides for notion
import '/src/notion/styles/notion.css';
// global style overrides for prism theme (optional)
import '/src/notion/styles/prism-theme.css';
import { useAnalyticsUserProperty } from '@/hooks/useAnalyticsUserProperty';
import { PathName } from '@/modules/PathName';
import Redirect from '@/modules/Redirect';
import { SafeNavigatorProvider } from '@/modules/SafeNavigator';
import { InvitationRequest } from '@/ui/workspace/InvitationRequest';
import { initialize } from '@/utils/FirebaseUtil';
import { ThemeProvider } from '@emotion/react';
import { AuthInfoProvider } from 'callabo-api/src';
import locales from 'callabo-locale/locales/common.json';
import { Locales, getValidLocale, isValidLocale } from 'callabo-locale/src/hooks/useTranslate';
import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp } from 'firebase/app';
import { Accessibility, CallaboPage } from 'libs/callabo-state/types/CallaboPage';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { GlobalModalRoot } from 'libs/callabo-ui/common/Modal/GlobalModalRoot';
import { NetworkModalPopup } from 'libs/callabo-ui/common/Modal/NetworkModalPopup';
import { GlobalToastMessageProvider } from 'libs/callabo-ui/contexts/GlobalToastMessageContext';
import analyticsInstance from 'libs/rtzr-commons/modules/marketing/Analytics';
import getTheme from 'libs/rtzr-design/theme';
import { GetServerSideProps } from 'next';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Fragment, useContext, useEffect, useState } from 'react';
// core styles shared by all of react-notion-x (required)
import 'react-notion-x/src/styles.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'src/contexts/AuthContext';
import { FirebaseContextProvider } from 'src/contexts/FirebaseContext';
import { RouterStateContextProvider } from 'src/contexts/RouterStateContext';
import WorkspaceContext, { WorkspaceContextProvider } from 'src/contexts/WorkspaceContext';
import { Paths } from 'src/modules/Paths';
import { ChannelTalk } from 'src/seo/ChannelTalk';
import { GoogleTagManager } from 'src/seo/GoogleTagManager';
import { Hotjar } from 'src/seo/Hotjar';
import { CommonLayout } from 'src/ui/CommonLayout/CommonLayout';
import { CommonSidebar } from 'src/ui/CommonLayout/CommonSidebar/CommonSidebar';
import { ShareAuthProvider } from 'src/ui/Share/contexts/ShareAuthContext';

const { publicRuntimeConfig } = getConfig();

function App({ Component, pageProps }: { Component: CallaboPage; pageProps: any }): JSX.Element {
    const router = useRouter();

    if (router.pathname === Paths.Docs || router.pathname === Paths.DocsDetail) {
        return (
            <>
                <Component {...pageProps} />
            </>
        );
    }

    return (
        <>
            <GoogleTagManager gaId={publicRuntimeConfig.GOOGLE_ANALYTICS_3_ID} />
            <GoogleTagManager gaId={publicRuntimeConfig.GOOGLE_ANALYTICS_4_ID} />
            <Hotjar hotjarId={publicRuntimeConfig.HOTJAR_ID} />
            <ChannelTalk show={Component.isHomepage} />
            <Header locale={pageProps.locale} />
            {/* 내부 페이지는 인덱싱 명시적으로 막기 */}
            {(Component.isInWorkspace || Component.isShared) &&
                <Head>
                    <meta name="robots" content="noindex" />
                </Head>
            }
            <ThemeProvider theme={getTheme()}>
                <GlobalModalRoot />
                <GlobalToastMessageProvider>
                    <SafeNavigatorProvider>
                        <AppProvider component={Component} pageProps={pageProps}>
                            {Component.enableCommonLayout && (
                                <CommonLayout
                                    sidebar={<CommonSidebar locale={pageProps.locale} />}
                                    content={<Component {...pageProps} />}
                                />
                            )}
                            {!Component.enableCommonLayout && <Component {...pageProps} />}
                        </AppProvider>
                    </SafeNavigatorProvider>
                </GlobalToastMessageProvider>
                <NetworkModalPopup locale={pageProps.locale} />
            </ThemeProvider>
        </>
    );
}

function Header({ locale }: PageProps): JSX.Element {
    return (
        <>
            <CommonTitleHead locale={locale} />
            <SlackHead />
            <CommonHead />
        </>
    );
}

const CommonTitleHead = ({ locale }: PageProps) => {
    const l = locales[locale];
    if (!l) return <></>;
    return (
        <Head>
            <title>{l.head.TITLE}</title>
            <meta name="description" content={l.head.META_DESC} />
            <meta name="keywords" content={l.head.META_KEYWORDS} />
            <meta property="og:site_name" content={l.head.TITLE} />
            <meta property="og:title" content={l.head.TITLE} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={l.head.META_DESC} />
            <meta property="og:locale" content={getValidLocale(locale)} />
            <meta property="og:locale:alternative" content={'ja'} />
            <meta property="og:locale:alternative" content={'en'} />
            <meta property="og:image" content={`/og/${getValidLocale(locale)}/og_1200x630.png`} />
            <meta
                property="og:image:url"
                content={`/og/${getValidLocale(locale)}/og_1200x630.png`}
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:url" content={`https://callabo.ai${locale ? `/${locale}` : ''}/`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={l.head.TITLE} />
            <meta name="twitter:image" content={`/og/${getValidLocale(locale)}/og_1200x630.png`} />
            <meta name="twitter:description" content={l.head.META_DESC} />
            <meta name="twitter:creator" content="@CALLABO_RETURNZERO" />
            <meta name="apple-itunes-app" content="app-id=6445909567" />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
};

const CommonHead = () => {
    const phase = publicRuntimeConfig.PHASE ?? 'dev';

    return (
        <Head>
            <meta
                content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"
                name="viewport"
            />
            <link rel="shortcut icon" href={`/favicon_${phase}.ico`} />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href={`/favicons/callabo_favicon_${phase}_120.png`}
            ></link>
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href={`/favicons/callabo_favicon_${phase}_96.png`}
            ></link>
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={`/favicons/callabo_favicon_${phase}_32.png`}
            ></link>
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={`/favicons/callabo_favicon_${phase}_16.png`}
            ></link>
        </Head>
    );
};

const SlackHead = () => {
    return (
        <Head>
            <meta name="slack-app-id" content={publicRuntimeConfig.SLACK_APP_ID}></meta>
        </Head>
    );
};

function AppProvider({
    component,
    children,
    pageProps,
}: {
    children?: React.ReactNode;
    component: CallaboPage;
    pageProps: PageProps;
}): JSX.Element {
    const [queryClient] = useState(() => new QueryClient());
    const [firebaseApp, setFirebaseApp] = useState<FirebaseApp>();

    useEffect(() => {
        const firebase = initialize();
        setFirebaseApp(firebase);
        analyticsInstance.googleAnalytics = getAnalytics(firebaseApp);
    }, []);

    const WorkspaceOrFragment = component.isInWorkspace ? WorkspaceContextProvider : Fragment;

    if (component.isShared) {
        return (
            <ShareAuthProvider>
                {children}
            </ShareAuthProvider>
        );
    }

    return (
        <QueryClientProvider client={queryClient}>
            <FirebaseContextProvider app={firebaseApp}>
                <AuthProvider>
                    <WorkspaceOrFragment>
                        <RouterStateContextProvider>
                            <WithAnalytics>
                                <WithAccessibility
                                    accessibility={component.accessibility}
                                    locale={pageProps.locale}
                                >
                                    {children}
                                </WithAccessibility>
                            </WithAnalytics>
                        </RouterStateContextProvider>
                    </WorkspaceOrFragment>
                </AuthProvider>
            </FirebaseContextProvider>
        </QueryClientProvider>
    );
}

function WithAnalytics({ children }: { children?: React.ReactNode }): JSX.Element {
    useAnalyticsUserProperty();
    return <>{children}</>;
}

function WithAccessibility({
    children,
    accessibility,
    locale,
}: {
    children?: React.ReactNode;
    accessibility: Accessibility;
    locale: Locales;
}): JSX.Element {
    const { authState } = useContext(AuthContext);
    const { workspace, isNotExistWorkspace, workspaceNotFound } = useContext(WorkspaceContext);
    const router = useRouter();

    switch (accessibility) {
        case Accessibility.PUBLIC_ONLY: {
            switch (authState.type) {
                case 'signedIn': {
                    return <Redirect url={Paths.WorkspaceIndex} push />;
                }
                case 'signedOut': {
                    return <>{children}</>;
                }
                default: {
                    return <></>;
                }
            }
        }
        case Accessibility.USER: {
            switch (authState.type) {
                case 'signedOut': {
                    return (
                        <Redirect
                            url={{
                                pathname: Paths.Login,
                                query: { redirect: location.href },
                            }}
                        />
                    );
                }
                case 'signedIn': {
                    const { user } = authState;
                    if (!user.isVerified) {
                        const query = {
                            redirect: router.query.redirect ?? location.href,
                        };
                        if (user.provider === AuthInfoProvider.GOOGLE) {
                            return (
                                <Redirect
                                    url={{
                                        pathname: Paths.SignupWithGoogle,
                                        query,
                                    }}
                                />
                            );
                        } else if (user.provider === AuthInfoProvider.APPLE) {
                            return (
                                <Redirect
                                    url={{
                                        pathname: Paths.SignupWithApple,
                                        query,
                                    }}
                                />
                            );
                        } else if (user.provider === AuthInfoProvider.MICROSOFT) {
                            return (
                                <Redirect
                                    url={{
                                        pathname: Paths.SignupWithMicrosoft,
                                        query,
                                    }}
                                />
                            );
                        } else {
                            return (
                                <Redirect
                                    url={{
                                        pathname: Paths.UserVerify,
                                        query,
                                    }}
                                />
                            );
                        }
                    }
                    if (isNotExistWorkspace) {
                        analyticsInstance.from = (router.query.redirect as string) ?? location.href;
                        return workspaceNotFound ? (
                            <Redirect url={Paths.WorkspaceIndex} />
                        ) : (
                            <InvitationRequest locale={locale} />
                        );
                    }
                    return <>{children}</>;
                }
                default: {
                    return <></>;
                }
            }
        }
        case Accessibility.UNVERIFIED_USER: {
            switch (authState.type) {
                case 'signedOut': {
                    return <Redirect url={Paths.Login} />;
                }
                case 'signedIn': {
                    const { user } = authState;
                    if (user.isVerified) {
                        return <Redirect url={Paths.WorkspaceIndex} />;
                    }
                    return <>{children}</>;
                }
                default: {
                    return <></>;
                }
            }
        }
        case Accessibility.NEW_VERIFIED_USER: {
            switch (authState.type) {
                case 'signedOut': {
                    return (
                        <Redirect
                            url={{
                                pathname: Paths.Login,
                                query: { redirect: location.href },
                            }}
                        />
                    );
                }
                case 'signedIn': {
                    const { user } = authState;
                    if (!user.isVerified) {
                        const query = {
                            redirect: router.query.redirect ?? location.href,
                        };
                        if (user.provider === AuthInfoProvider.GOOGLE) {
                            return (
                                <Redirect
                                    url={{
                                        pathname: Paths.SignupWithGoogle,
                                        query,
                                    }}
                                />
                            );
                        } else if (user.provider === AuthInfoProvider.APPLE) {
                            return (
                                <Redirect
                                    url={{
                                        pathname: Paths.SignupWithApple,
                                        query,
                                    }}
                                />
                            );
                        } else if (user.provider === AuthInfoProvider.MICROSOFT) {
                            return (
                                <Redirect
                                    url={{
                                        pathname: Paths.SignupWithMicrosoft,
                                        query,
                                    }}
                                />
                            );
                        } else {
                            return (
                                <Redirect
                                    url={{
                                        pathname: Paths.UserVerify,
                                        query,
                                    }}
                                />
                            );
                        }
                    }
                    if (workspace) {
                        return (
                            <Redirect
                                url={new PathName(Paths.Calls)
                                    .setWorkspaceId(workspace.id)
                                    .toString()}
                            />
                        );
                    }
                    return <>{children}</>;
                }
                default: {
                    return <></>;
                }
            }
        }
        case Accessibility.EVERYONE: {
            return <>{children}</>;
        }
    }
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const localeQuery = context.query.locale as string;
    const localeCookie = context.req.headers.cookie
        ?.split(';')
        .find((cookie) => cookie.trim().startsWith('locale='))
        ?.split('=')[1];
    const locale = isValidLocale(localeQuery)
        ? localeQuery
        : isValidLocale(localeCookie)
            ? localeCookie
            : 'ko';
    return {
        props: {
            locale,
        },
    };
};

export default App;
