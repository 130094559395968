import { useCallback, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export default function useEventCallback<Args extends unknown[], Return>(
    fn: (...args: Args) => Return
): (...args: Args) => Return {
    const ref = useRef(fn);
    ref.current = fn;
    return useCallback((...args: Args) => ref.current(...args), []);
}
