export const color = {
    light_primary: '#2E67FE',
    dark_primary: '#2E67FE',
    dark_bg01: '#00000',
    light_bg01: '#FFFFFF',
    light_bg02: '#F6F6F6',
    light_badge01: '#E6626F',
    light_point01: '#FF5161',
    light_bubble01: '#F6F6F6',
    light_bubble02: '#E7F3FF',
    bubble_capture: '#FFE444',
    bubble_play: '#2E67FE',
    bubble_ars: '#E8E8E8',
    light_text01: '#222222',
    light_text02: '#666666',
    light_text03: '#FFFFFF',
    light_text04: '#999999',
    light_text05: '#333333',
    light_text06: '#ADADAD',
    dark_text01: '#D9D9D9',
    dark_text02: '#B2B2B2',
    light_icon01: '#909090',
    light_icon02: '#BBBBBB',
    light_icon03: '#333333',
    light_icon04: '#FFFFFF',
    light_line01: '#F4F4F4',
    light_line02: '#DEDEDE',
    light_btn_line01: '#B2B2B2',
    light_btn_text01: '#666666',
    light_btn_text02: '#FFFFFF',
    light_btn_text03: '#909090',
    light_btn_text04: '#828282',
    light_btn_bg03: '#E0E0E0',
    light_btn_bg04: '#E7F3FF',
    light_btn_icon01: '#D2D3DB',
    light_btn_icon02: 'rgba(46, 103, 254, 0.6)',
    light_btn_icon03: 'rgba(46, 103, 254, 0.3)',
    light_btn_icon04: 'rgba(153, 153, 153, 0.6)',
    light_btn_icon05: 'rgba(153, 153, 153, 0.3)',
};

export const typography = {
    fonts: {
        primary: 'Noto Sans KR',
    },
    weight: {
        regular: '400',
        bold: 'bold',
        extrabold: '800',
        black: '900',
    },
    sizes: {
        h1: {
            size: '2rem',
            height: '32px',
        },
        h2: {
            size: '1.8rem',
            height: '28px',
        },
    },
};

export const Colors: ColorType = {
    primary: '#2E67fe',
    positive: '',
    negative: '',
    background: '#ffffff',
    blank: 'ffffff',
};

export interface ColorType {
    primary: string;
    positive: string;
    negative: string;
    background: string;
    blank: string;
}
