import WorkspaceContext from '@/contexts/WorkspaceContext';
import { PathName } from '@/modules/PathName';
import { Paths } from '@/modules/Paths';
import { useSafeNavigator } from '@/modules/SafeNavigator';
import { WorkspaceCreateForm } from '@/ui/workspace/WorkspaceCreateForm';
import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { CallaboPopover } from 'libs/callabo-ui/common/Popover/CallaboPopover';
import { useModal } from 'libs/callabo-ui/hooks/useModal';
import { getContrastColor } from 'libs/rtzr-commons/ColorUtil';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useContext } from 'react';

export const WorkspacePopover = ({ locale }: PageProps): JSX.Element => {
    const { t } = useTranslate({ locale });
    const { workspaces, workspace: currentWorkspace } = useContext(WorkspaceContext);
    const [ModalComponent, openModal, closeModal] = useModal({ maskClosable: false });
    const navigator = useSafeNavigator();

    const onCreateWorkspace = useCallback((id: string) => {
        closeModal();
        navigator.push(new PathName(Paths.Calls).setWorkspaceId(id).toString());
    }, []);

    if (!currentWorkspace) {
        return <></>;
    }

    return (
        <>
            <CallaboPopover
                options={{
                    anchor: {
                        gap: 4,
                        padding: 10,
                        to: 'bottom start',
                    },
                    backdrop: true,
                }}
            >
                <button
                    className="group/workspaceSelector w-full overflow-hidden flex items-center gap-1 md:px-3.5 text-base font-semibold cursor-pointer"
                    title={currentWorkspace.name}
                >
                    <div className="w-10 h-10 shrink-0 rounded-[4px] p-1 bg-transparent md:group-hover/workspaceSelector:bg-blue-mist">
                        <div
                            className="w-full h-full flex justify-center items-center rounded-[4px] border border-overlay-5 border-solid"
                            style={{
                                backgroundColor: currentWorkspace.color,
                            }}
                        >
                            <span
                                style={{ color: getContrastColor(currentWorkspace.color) }}
                                className="text-md md:group-hover/workspaceSelector:scale-110 font-semibold transition-transform duration-[0.1s] ease-in-out"
                            >
                                {currentWorkspace.name.charAt(0).toLocaleUpperCase()}
                            </span>
                        </div>
                    </div>
                    <span className="whitespace-nowrap overflow-hidden text-ellipsis text-black-light">
                        {currentWorkspace.name}
                    </span>
                    <div className="block md:hidden group-hover/workspaceSelector:block shrink-0 px-[7px] py-[1px] border-[1px] border-gray-fog border-solid rounded-xl ml-1 bg-white">
                        <Image
                            src={'/callabo/small_arrow_down.svg'}
                            width={12}
                            height={12}
                            alt="button"
                            className="group-data-[open]/popover:rotate-180"
                        />
                    </div>
                </button>
                {({ close }) => (
                    <div className="w-[260px] min-w-[260px] shadow bg-white rounded-xl overflow-y-hidden pt-4">
                        {workspaces && (
                            <ul className="w-full max-h-[212px] overflow-auto custom-scrollbar px-4">
                                {workspaces.map((workspace) => (
                                    <li
                                        key={`w-${workspace.id}`}
                                        onClick={() =>
                                            navigator.push(
                                                new PathName(Paths.Calls)
                                                    .setWorkspaceId(workspace.id)
                                                    .toString()
                                            )
                                        }
                                        className="group flex gap-2 px-3.5 py-3 text-sm rounded-[4px] hover:bg-gray-mist cursor-pointer"
                                    >
                                        <div
                                            style={{
                                                backgroundColor: workspace.color,
                                                color: getContrastColor(workspace.color),
                                            }}
                                            className="flex w-8 h-8 shrink-0 justify-center items-center text-md font-semibold rounded-[4px] border border-overlay-5 border-solid"
                                        >
                                            {workspace.name.charAt(0).toLocaleUpperCase()}
                                        </div>
                                        <div>
                                            <p className="text-sm font-semibold text-black-light break-all">
                                                {workspace.name}
                                            </p>
                                            <p className="text-xs text-gray break-all">{`callabo.ai/w/${workspace.id}`}</p>
                                        </div>
                                        {workspace.hasPolicy('above_admin') && (
                                            <Link
                                                href={new PathName(Paths.Config)
                                                    .setWorkspaceId(workspace.id)
                                                    .toString()}
                                                passHref
                                                className="ml-auto self-center md:hidden md:group-hover/workspaceSelector:block shrink-0"
                                            >
                                                <button
                                                    onClick={(e) => e.stopPropagation()}
                                                    className="p-1"
                                                >
                                                    <Image
                                                        src={require('./assets/config.svg')}
                                                        width={16}
                                                        height={16}
                                                        alt="config"
                                                    />
                                                </button>
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                        <div className="h-[1px] bg-gray-fog mx-3.5" />
                        <button
                            onClick={() => {
                                close();
                                openModal();
                            }}
                            className="text-primary text-sm font-semibold py-4 mx-4 px-3.5 whitespace-nowrap"
                        >
                            {t.lnb.NEW_WORKSPACE_BUTTON}
                        </button>
                    </div>
                )}
            </CallaboPopover>
            <ModalComponent>
                <div className="w-[550px] max-w-[290px] md:max-w-full py-10">
                    <Image
                        src={'/callabo/logo.svg'}
                        width={96}
                        height={23}
                        alt="callabo"
                        className="mx-auto"
                    />
                    <h3 className="font-semibold text-[1.25rem] md:text-[1.75rem] leading-[33px] text-center text-[#333333] mt-6 mb-10">
                        {t.lnb.NEW_WORKSPACE_POPUP_TITLE}
                    </h3>
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-base leading-[130%] text-center text-[#333333] mb-6">
                            {t.lnb.NEW_WORKSPACE_POPUP_DESC}
                        </p>
                        <WorkspaceCreateForm
                            onCreateWorkspace={onCreateWorkspace}
                            locale={locale}
                        />
                    </div>
                </div>
            </ModalComponent>
        </>
    );
};
