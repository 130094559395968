import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { useModal } from 'libs/callabo-ui/hooks/useModal';
import { delayAsync } from 'libs/rtzr-commons/async/Delay';
import { Style } from 'libs/rtzr-design/common';
import { useEffect } from 'react';

export function NetworkModalPopup({ locale }: PageProps): JSX.Element {
    const { t } = useTranslate({ locale });
    const [ModalRoot, openPopup, closePopup] = useModal({
        closable: false,
        maskClosable: false,
    });

    useEffect(() => {
        window.addEventListener('offline', openPopup);
        window.addEventListener('online', closePopup);

        return () => {
            window.removeEventListener('offline', openPopup);
            window.removeEventListener('online', closePopup);
        };
    }, []);

    return (
        <ModalRoot>
            <Style.AlertDialogWrapper>
                <Style.DialogTitle>{t.common.NETWORK_ERROR_TITLE}</Style.DialogTitle>
                <Style.DialogDescription>{t.common.NETWORK_ERROR_DESC}</Style.DialogDescription>
                <Style.DialogButton
                    onClick={() => {
                        (async () => {
                            closePopup();
                            await delayAsync(100);
                            openPopup();
                        })();
                    }}
                >
                    {t.common.NETWORK_ERROR_RETRY_BUTTON}
                </Style.DialogButton>
            </Style.AlertDialogWrapper>
        </ModalRoot>
    );
}
