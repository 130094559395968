import { SharedRecord, SharedRecordDuration } from 'callabo-api/src';

export class SharedRecordModel {
    private _title: string;
    private _duration: number;
    private _isShared: boolean;

    constructor(private originResponse: SharedRecord) {
        this._title = this.originResponse.title;
        this._duration =
            this.originResponse.duration === SharedRecordDuration.INFINITE
                ? Infinity
                : Number(this.originResponse.duration.replace('d', ''));

        const now = new Date();
        const expiredDate = new Date(this.originResponse.expires_at);
        this._isShared =
            this.originResponse &&
            (now < expiredDate || this.originResponse.duration === SharedRecordDuration.INFINITE)
                ? true
                : false;
    }

    public get title(): string {
        return this._title;
    }

    public set title(title: string) {
        this._title = title;
    }

    public get duration(): number {
        return this._duration;
    }

    public set duration(duration: number) {
        this._duration = duration;
    }

    public get recordId(): number {
        return this.originResponse.record_id;
    }

    public get expiresAt(): string {
        return this.originResponse.expires_at;
    }

    public get sharedId(): string {
        return this.originResponse.share_id;
    }

    public get password(): string {
        return this.originResponse.password;
    }

    public get isShared(): boolean {
        return this._isShared;
    }

    public get createdAt(): string {
        return this.originResponse.created_at;
    }

    public get startDate(): Date {
        return new Date(this.originResponse.created_at) || new Date();
    }

    public get endDate(): Date {
        const end = this.startDate;
        end.setDate(end.getDate() + this.duration);
        return new Date(end);
    }

    public isChangeableDuration(newDuration: number): boolean {
        if (newDuration === Infinity) return true;
        const newEndDate = this.startDate;
        newEndDate.setDate(newEndDate.getDate() + newDuration);
        newEndDate.setHours(23, 59, 59);
        return newEndDate.getTime() > Date.now();
    }
}
