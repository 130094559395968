/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { ApiKey } from '../models/ApiKey';

export class ApiKeyService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Api Key
     * @param slug
     * @returns ApiKey Successful Response
     * @throws ApiError
     */
    public getApiKeyV1WorkspaceSlugApiKeyGet(slug: string): CancelablePromise<ApiKey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/api-key',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Api Key
     * @param slug
     * @returns ApiKey Successful Response
     * @throws ApiError
     */
    public createApiKeyV1WorkspaceSlugApiKeyPut(slug: string): CancelablePromise<ApiKey> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/workspace/{slug}/api-key',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Api Key
     * @param id
     * @param slug
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteApiKeyV1WorkspaceSlugApiKeyIdDelete(
        id: number,
        slug: string
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/api-key/{id}',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }
}
