/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RefreshAuthentication = {
    grant_type: RefreshAuthentication.grant_type;
    refresh_token: string;
};

export namespace RefreshAuthentication {
    export enum grant_type {
        REFRESH_TOKEN = 'refresh_token',
    }
}
