import { Paths } from './Paths';
import { Locales } from 'callabo-locale/src/hooks/useTranslate';

/**
 * url Path를 생성 용도
 */
export class PathName {
    locale: Locales;
    workspaceId: string;
    recordId: string;
    recordQuery: { tab?: string; query?: string; startAt?: string; referer?: string };
    sharedRecordQuery: { record?: string; pw?: string };
    sharedId: string;
    licenseQuery: { tab?: string };

    constructor(public readonly path: Paths) {}

    public setLocale(locale: Locales): PathName {
        this.locale = locale;
        return this;
    }

    public setWorkspaceId(workspaceId: string): PathName {
        this.workspaceId = workspaceId;
        return this;
    }

    public setRecordId(recordId: string): PathName {
        this.recordId = recordId;
        return this;
    }

    public setSharedId(sharedId: string): PathName {
        this.sharedId = sharedId;
        return this;
    }

    /**
     * record detail query
     * @param query
     * @returns
     */
    public setRecordQuery(query: {
        tab?: string;
        query?: string;
        startAt?: string;
        referer?: string;
    }): PathName {
        this.recordQuery = query;
        return this;
    }

    public setSharedRecordQuery(query: { record?: string; pw?: string }): PathName {
        this.sharedRecordQuery = query;
        return this;
    }

    public setLicenseQuery(query: { tab?: string }): PathName {
        this.licenseQuery = query;
        return this;
    }

    /**
     * url에 적용하기 위해 string으로 변환
     * @returns pathname 생성
     */
    public toString(): string {
        let resultPath = this.path as string;

        if (this.locale) {
            if (!this.path.includes('[locale]')) throw Error('path is not locale path');
            resultPath = resultPath.replace('[locale]', this.locale);
        }
        if (this.workspaceId) {
            if (!this.path.includes('[workspaceId]')) throw Error('path is not workspace path');
            resultPath = resultPath.replace('[workspaceId]', this.workspaceId);
        }
        if (this.recordId) {
            if (!this.path.includes('[recordId]')) throw Error('path is not workspace path');
            resultPath = resultPath.replace('[recordId]', this.recordId);
        }
        if (this.sharedId) {
            if (!this.path.includes('[sharedId]')) throw Error('path is not workspace path');
            resultPath = resultPath.replace('[sharedId]', this.sharedId);
        }

        const params = new URLSearchParams();
        this.recordQuery?.tab && params.append('tab', this.recordQuery.tab);
        this.recordQuery?.query && params.append('query', this.recordQuery.query);
        this.recordQuery?.startAt && params.append('startAt', this.recordQuery.startAt);
        this.recordQuery?.referer && params.append('referer', this.recordQuery.referer);
        this.sharedRecordQuery?.pw && params.append('pw', this.sharedRecordQuery.pw);
        this.licenseQuery?.tab && params.append('tab', this.licenseQuery.tab);
        if (resultPath.includes('[locale]')) throw new Error('not set locale');
        if (resultPath.includes('[workspaceId]')) throw new Error('not set workspaceId');
        if (resultPath.includes('[recordId]')) throw new Error('not set recordId');
        if (resultPath.includes('[sharedId]')) throw new Error('not set sharedId');

        if (params.toString()) {
            resultPath = `${resultPath}?${params.toString()}`;
        }

        return resultPath;
    }
}
