/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LicenseSubscriptionUpdateRequest = {
    plan: LicenseSubscriptionUpdateRequest.plan;
    count: number;
    period?: LicenseSubscriptionUpdateRequest.period;
};

export namespace LicenseSubscriptionUpdateRequest {
    export enum plan {
        PLUS = 'plus',
        PRO = 'pro',
    }

    export enum period {
        P1M = 'P1M',
        P1Y = 'P1Y',
    }
}
