import { isClientSideRender } from 'libs/rtzr-commons/isClientSideRender';
import { createPortal } from 'react-dom';

export const Portal: React.FC<{ elementId: string; children: React.ReactNode }> = (props) => {
    const isCSR = isClientSideRender();
    if (!isCSR) return <></>;

    const { elementId, children } = props;
    return createPortal(children, document.getElementById(elementId));
};
