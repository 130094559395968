import { AuthInfoProvider, User, UserLocale, UserStatus } from 'callabo-api/src';

export class UserModel {
    constructor(private originResponse: User) {
        //
    }

    public get id(): number {
        return this.originResponse.id;
    }

    public get name(): string {
        return this.originResponse.name;
    }

    public get phoneNumber(): string {
        return this.originResponse.phone_number;
    }

    public get company(): string {
        return this.originResponse.company;
    }

    public get email(): string {
        return this.originResponse.email;
    }

    public get userStatus(): UserStatus {
        return this.originResponse.status;
    }

    public get provider(): AuthInfoProvider {
        return this.originResponse.provider;
    }

    public get locale(): UserLocale {
        return this.originResponse.locale;
    }

    public get isValidUser(): boolean {
        return this.userStatus === UserStatus.CREATED || this.userStatus === UserStatus.VERIFIED;
    }

    public get isVerified(): boolean {
        return this.userStatus === UserStatus.VERIFIED;
    }
}
