/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { ApiKeyService } from './services/ApiKeyService';
import { EventService } from './services/EventService';
import { GroupService } from './services/GroupService';
import { IntegrationService } from './services/IntegrationService';
import { LabelService } from './services/LabelService';
import { LicenseService } from './services/LicenseService';
import { NotificationService } from './services/NotificationService';
import { QuotaService } from './services/QuotaService';
import { RecordService } from './services/RecordService';
import { SalesforceService } from './services/SalesforceService';
import { ServiceService } from './services/ServiceService';
import { SlackService } from './services/SlackService';
import { UsageService } from './services/UsageService';
import { UserService } from './services/UserService';
import { WebhookService } from './services/WebhookService';
import { WorkspaceCardService } from './services/WorkspaceCardService';
import { WorkspaceService } from './services/WorkspaceService';
import { ZapierService } from './services/ZapierService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class BackendApiClient {
    public readonly apiKey: ApiKeyService;
    public readonly event: EventService;
    public readonly group: GroupService;
    public readonly integration: IntegrationService;
    public readonly label: LabelService;
    public readonly license: LicenseService;
    public readonly notification: NotificationService;
    public readonly quota: QuotaService;
    public readonly record: RecordService;
    public readonly salesforce: SalesforceService;
    public readonly service: ServiceService;
    public readonly slack: SlackService;
    public readonly usage: UsageService;
    public readonly user: UserService;
    public readonly webhook: WebhookService;
    public readonly workspace: WorkspaceService;
    public readonly workspaceCard: WorkspaceCardService;
    public readonly zapier: ZapierService;

    public readonly request: BaseHttpRequest;

    constructor(
        config?: Partial<OpenAPIConfig>,
        HttpRequest: HttpRequestConstructor = AxiosHttpRequest
    ) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://dev-api.callabo.ai',
            INSTANCE: config?.INSTANCE,
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.apiKey = new ApiKeyService(this.request);
        this.event = new EventService(this.request);
        this.group = new GroupService(this.request);
        this.integration = new IntegrationService(this.request);
        this.label = new LabelService(this.request);
        this.license = new LicenseService(this.request);
        this.notification = new NotificationService(this.request);
        this.quota = new QuotaService(this.request);
        this.record = new RecordService(this.request);
        this.salesforce = new SalesforceService(this.request);
        this.service = new ServiceService(this.request);
        this.slack = new SlackService(this.request);
        this.usage = new UsageService(this.request);
        this.user = new UserService(this.request);
        this.webhook = new WebhookService(this.request);
        this.workspace = new WorkspaceService(this.request);
        this.workspaceCard = new WorkspaceCardService(this.request);
        this.zapier = new ZapierService(this.request);
    }
}
