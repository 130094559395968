/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Body_zapier_subscribe_v1_zapier_post } from '../models/Body_zapier_subscribe_v1_zapier_post';
import type { Body_zapier_unsubscribe_v1_zapier_delete } from '../models/Body_zapier_unsubscribe_v1_zapier_delete';
import type { ZapierMe } from '../models/ZapierMe';
import type { ZapierTriggerRecord } from '../models/ZapierTriggerRecord';

export class ZapierService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Zapier Me
     * @param xApiKey
     * @returns ZapierMe Successful Response
     * @throws ApiError
     */
    public zapierMeV1ZapierMeGet(xApiKey: string): CancelablePromise<ZapierMe> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/zapier/me',
            headers: {
                'X-API-KEY': xApiKey,
            },
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Zapier Subscribe
     * @param xApiKey
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public zapierSubscribeV1ZapierPost(
        xApiKey: string,
        requestBody: Body_zapier_subscribe_v1_zapier_post
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/zapier',
            headers: {
                'X-API-KEY': xApiKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Zapier Unsubscribe
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public zapierUnsubscribeV1ZapierDelete(
        requestBody: Body_zapier_unsubscribe_v1_zapier_delete
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/zapier',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Zapier Sample
     * @param xApiKey
     * @returns ZapierTriggerRecord Successful Response
     * @throws ApiError
     */
    public zapierSampleV1ZapierSampleGet(
        xApiKey: string
    ): CancelablePromise<Array<ZapierTriggerRecord>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/zapier/sample',
            headers: {
                'X-API-KEY': xApiKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
