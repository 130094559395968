/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { Scope } from './Scope';

export type ScopedTokenAuthentication = {
    grant_type: ScopedTokenAuthentication.grant_type;
    scopes: Array<Scope>;
};

export namespace ScopedTokenAuthentication {
    export enum grant_type {
        SCOPED_TOKEN = 'scoped_token',
    }
}
