import locale from '../../locales/common.json';

export type Locales = 'ko' | 'ja' | 'en';

export function isValidLocale(locale: string | undefined): locale is Locales {
    if (!locale) return false;
    const targetLang = locale.split('-')[0];
    return targetLang === 'ko' || targetLang === 'ja' || targetLang === 'en';
}

/** @description fallback locale: ko */
export function getValidLocale(locale: string): Locales {
    return isValidLocale(locale) ? locale : 'ko';
}

export interface Props {
    locale?: Locales;
}

export const useTranslate = ({ locale }: Props = {}): Translate<Locales> => {
    return new Translate(getValidLocale(locale));
};

export class Translate<L extends Locales> {
    locale: L;
    t: (typeof locale)[L];

    constructor(l: L) {
        this.locale = l;
        this.t = locale[l];
    }
}
