import WorkspaceContext from '@/contexts/WorkspaceContext';
import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { GlobalToastMessageContext } from 'libs/callabo-ui/contexts/GlobalToastMessageContext';
import { RegexPattern } from 'libs/rtzr-commons/RegexPattern';
import analyticsInstance from 'libs/rtzr-commons/modules/marketing/Analytics';
import { Style } from 'libs/rtzr-design/common';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
    onCreateWorkspace?: (id: string) => void;
}

interface WorkspaceFormValue {
    name: string;
    slug: string;
}

export const WorkspaceCreateForm = ({
    onCreateWorkspace,
    locale,
}: Props & PageProps): JSX.Element => {
    const [invalidWorkspaceName, setInvalidWorkspaceName] = useState<boolean>(false);
    const [invalidWorkspaceSlug, setInvalidWorkspaceSlug] = useState<boolean>(false);
    const [duplicateSlug, setDuplicateSlug] = useState<boolean>(false);
    const { createWorkspaceAsync } = useContext(WorkspaceContext);
    const { toast } = useContext(GlobalToastMessageContext);
    const { t } = useTranslate({ locale });

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<WorkspaceFormValue>({
        mode: 'onChange',
    });

    useEffect(() => {
        setInvalidWorkspaceName(!!errors.name);
        setInvalidWorkspaceSlug(!!errors.slug);
        setDuplicateSlug(false);
    }, [errors.name, errors.slug]);

    const handleCreateWorkspace = async (data: WorkspaceFormValue, event: any) => {
        event.preventDefault();

        try {
            const newWorkspace = await createWorkspaceAsync(data.name, data.slug);
            onCreateWorkspace?.(newWorkspace.id);
        } catch (e) {
            if (e.status === 409) {
                setDuplicateSlug(true);
            } else if (e.status === 412) {
                toast(t.lnb.NEW_WORKSPACE_POPUP_MSG_MAX);
            } else {
                toast(t.common.ERROR);
            }
            return;
        }
        analyticsInstance.logEvent('action_create_workspace_submit', {
            w_name: data.name,
            w_slug: data.slug,
        });
    };

    return (
        <Style.FormContainer onSubmit={handleSubmit(handleCreateWorkspace)}>
            <div className="mb-6">
                <Style.TextInput
                    id="name"
                    placeholder={t.new.W_NAME_PLACEHOLDER}
                    autoComplete="off"
                    autoFocus
                    type="text"
                    {...register('name', {
                        required: true,
                        pattern: RegexPattern.WorkspaceName,
                        minLength: 2,
                        maxLength: 50,
                    })}
                />
                {invalidWorkspaceName && (
                    <Style.InputAlert>{t.common.W_NAME_VALIDATION_ERROR}</Style.InputAlert>
                )}
                <div className='mt-2 relative before:content-["callabo.ai/w/"] before:absolute before:top-[50%] before:left-[20px] before:font-semibold before:text-sm before:-translate-y-[50%]'>
                    <Style.TextInput
                        id="slug"
                        placeholder={t.common.W_SLUG_PLACEHOLDER}
                        autoComplete="off"
                        type="text"
                        style={{ paddingLeft: 106 }}
                        {...register('slug', {
                            required: true,
                            pattern: RegexPattern.WorkspaceSlug,
                            minLength: 2,
                            maxLength: 20,
                        })}
                    />
                </div>
                {invalidWorkspaceSlug && (
                    <Style.InputAlert>{t.new.W_SLUG_VALIDATION_ERROR}</Style.InputAlert>
                )}
                {duplicateSlug && (
                    <Style.InputAlert>{t.new.W_SLUG_DUPLICATE_ERROR}</Style.InputAlert>
                )}
            </div>
            <Style.SubmitButton id="email-certification" type="submit" disabled={!isValid}>
                {t.new.SUBMIT_BUTTON}
            </Style.SubmitButton>
        </Style.FormContainer>
    );
};
