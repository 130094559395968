/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { Dialog } from './Dialog';
import type { Event } from './Event';
import type { Group } from './Group';
import type { HTMLStr } from './HTMLStr';
import type { Label } from './Label';
import type { RecordExtra } from './RecordExtra';
import type { SharedRecord } from './SharedRecord';
import type { User } from './User';
import type { WorkspaceMember } from './WorkspaceMember';

export type Record = {
    workspace_id: number;
    id: number;
    source: Record.source;
    user_id: number;
    user: User;
    /**
     * required if source is `zoom` or `google_meet` or `teams_meet`
     */
    event?: Event;
    title: string | HTMLStr;
    duration?: number;
    /**
     * 녹음/녹화 종료 시간
     */
    rec_date: string;
    filesize: number;
    dialogs?: Array<Dialog>;
    status: Record.status;
    extra?: RecordExtra;
    shared_record?: SharedRecord;
    accessible_for_all?: boolean;
    accessible_groups?: Array<Group>;
    accessible_users?: Array<WorkspaceMember>;
    labels?: Array<Label>;
};

export namespace Record {
    export enum source {
        ZOOM = 'zoom',
        GOOGLE_MEET = 'google_meet',
        TEAMS_MEET = 'teams_meet',
        PHONE_CALL = 'phone_call',
        VOICE_RECORDER = 'voice_recorder',
        SCREEN_RECORDER = 'screen_recorder',
        MANUAL_UPLOAD = 'manual_upload',
    }

    export enum status {
        CREATED = 'created',
        UPLOADED = 'uploaded',
        PROCESSED = 'processed',
        FAILED = 'failed',
        NOT_SUPPORTED = 'not_supported',
    }
}
