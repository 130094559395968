/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Scope of a JWT token.
 */
export enum Scope {
    SCREEN_RECORDER = 'screen_recorder',
    SHARED_RECORD = 'shared_record',
}
