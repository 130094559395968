import { useRouterQuery } from '../Share/hooks/useRouterQuery';
import { ApiClientContext } from '@/contexts/ApiClientContext';
import WorkspaceContext from '@/contexts/WorkspaceContext';
import { useSafeNavigator } from '@/modules/SafeNavigator';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';
import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { WorkspaceModel } from 'libs/callabo-state/models/WorkspaceModel';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { GlobalToastMessageContext } from 'libs/callabo-ui/contexts/GlobalToastMessageContext';
import analyticsInstance from 'libs/rtzr-commons/modules/marketing/Analytics';
import { Style } from 'libs/rtzr-design/common';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts/AuthContext';
import { Paths } from 'src/modules/Paths';

export function InvitationRequest({ locale }: PageProps): JSX.Element {
    const callaboApiClient = useContext(ApiClientContext);
    const { authState } = useContext(AuthContext);
    const { toast } = useContext(GlobalToastMessageContext);
    const { searchWorkspaceAsync } = useContext(WorkspaceContext);
    const [workspaceId] = useRouterQuery('workspaceId');
    const [workspace, setWorkspace] = useState<WorkspaceModel>();
    const navigator = useSafeNavigator();
    const { t } = useTranslate({ locale });

    useEffect(() => {
        (async () => {
            try {
                const response = await searchWorkspaceAsync(workspaceId);
                setWorkspace(response);
            } catch (e) {
                if (e.status === 404) {
                    navigator.replace(`${Paths.WorkspaceIndex}`);
                    return;
                }
                toast(t.common.ERROR);
            }
        })();
    }, [workspaceId]);

    useEffect(() => {
        analyticsInstance.logEvent('page_request_invitation', {
            from: analyticsInstance.from,
        });
    }, []);

    const handelSendInviteRequest = useCallback(async () => {
        try {
            if (authState.type !== 'signedIn' || !workspace) return;
            await callaboApiClient.workspace.requestInvitationV1WorkspaceSlugRequestInvitationPost(
                workspace.id
            );
            analyticsInstance.logEvent('action_request_invitation');
            toast(t.invitationRequest.SUCCESS);
        } catch (e) {
            if (e.status === 429) {
                toast(t.invitationRequest.IN_REVIEW);
                return;
            }
            toast(t.common.ERROR);
            Sentry.captureException(e);
        }
    }, [callaboApiClient, authState, workspace]);

    return (
        workspace && (
            <Style.BodyContainer>
                <Wrapper>
                    <Image
                        src={'/callabo/logo.svg'}
                        width={96}
                        height={23}
                        alt="logo"
                        className="mx-auto"
                    />
                    <Title>
                        {`'${workspace?.name}'`}
                        <br />
                        {t.invitationRequest.PAGE_TITLE}
                    </Title>
                    <Description>{`callabo.ai/w/${workspace?.id}`}</Description>
                    <Style.SubmitButton
                        type="button"
                        disabled={false}
                        onClick={handelSendInviteRequest}
                    >
                        {t.invitationRequest.SUBMIT_BUTTON}
                    </Style.SubmitButton>
                    <Link href={Paths.WorkspaceIndex} passHref className="mx-auto">
                        <HomeButton type="button">{t.common.GO_HOME}</HomeButton>
                    </Link>
                </Wrapper>
            </Style.BodyContainer>
        )
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 1.75em;
    line-height: 33px;
    text-align: center;
    color: #333333;
    margin-top: 24px;
`;

const Description = styled.p`
    font-weight: 400;
    font-size: 1rem;
    line-height: 21px;
    text-align: center;
    color: #333333;
    margin: 12px 0 24px 0;
`;

const HomeButton = styled.button`
    padding: 15px 20px;
    height: 44px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 100%;
    text-align: center;
    color: #888888;
`;
