import { openChannelTalk } from '@/seo/ChannelTalk';
import styled from '@emotion/styled';
import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import Image from 'next/image';
import Link from 'next/link';

interface Props {
    onCloseMenu: (e) => void;
    onClickItem: (eventTarget: string) => void;
}

export const InquiryMenu = ({
    onCloseMenu,
    onClickItem,
    locale,
}: Props & PageProps): JSX.Element => {
    const { t } = useTranslate({ locale });
    const handleClickItem = (e: any, target: string) => {
        e.stopPropagation();
        onClickItem(target);
    };

    return (
        <>
            <MenuOverlay onClick={onCloseMenu} />
            <Container>
                <MenuContent>
                    <div>
                        <Title>{t.lnb.INQUIRY}</Title>
                        <SubTitle>{t.lnb.INQUIRY_DESC}</SubTitle>
                    </div>
                    {locale === 'ko' && (
                        <>
                            <GuideLink
                                href={`https://callabo.ai/docs/${encodeURIComponent(
                                    '8331534fb094473bb07a22bd2074d7e3'
                                )}`}
                                text={t.lnb.INQUIRY_MEETING}
                                onClick={(e) => handleClickItem(e, 'meeting')}
                            />
                            <GuideLink
                                href={`https://callabo.ai/docs/${encodeURIComponent(
                                    '709042767e254fbb8818035337f7db92'
                                )}`}
                                text={t.lnb.INQUIRY_RECORDER}
                                onClick={(e) => handleClickItem(e, 'recorder')}
                            />
                            <GuideLink
                                href={`https://callabo.ai/docs/${encodeURIComponent(
                                    '98f05323f3d841628823a7276708a9b5'
                                )}`}
                                text={t.lnb.INQUIRY_ROLE}
                                onClick={(e) => handleClickItem(e, 'member')}
                            />
                            <Divider />
                            <LinkButtonWithImage
                                href="https://callabo.ai/docs"
                                imageSrc={require('../assets/guide.svg')}
                                text={t.lnb.INQUIRY_GUID}
                                onClick={(e) => handleClickItem(e, 'guide')}
                            />
                            <LinkButtonWithImage
                                href="https://whattime.co.kr/callabo/onboarding"
                                imageSrc={require('../assets/schedule.svg')}
                                text={t.lnb.INQUIRY_ONBOARDING}
                                onClick={(e) => handleClickItem(e, 'onboarding_schedule')}
                            />
                        </>
                    )}
                    <LinkButtonWithImage
                        imageSrc={require('../assets/channeltalk.png')}
                        text={t.lnb.INQUIRY_CHANNELTALK}
                        onClick={openChannelTalk}
                    />
                </MenuContent>
            </Container>
        </>
    );
};

const GuideLink = (props: { href: string; text: string; onClick: (e) => void }): JSX.Element => {
    return (
        <Link href={props.href} passHref target="_blank">
            <GuideLinkButton onClick={props.onClick}>
                {props.text}
                <Image
                    src={require('../assets/icon_small_question-circle.svg')}
                    width={12}
                    height={12}
                    layout="fixed"
                    alt="?"
                />
            </GuideLinkButton>
        </Link>
    );
};

const GuideLinkButton = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    color: #333;
    font-size: 0.875rem;
    font-weight: 400;
`;

const MenuOverlay = styled.div`
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    cursor: default;
`;

const Container = styled.div`
    position: absolute;
    z-index: 1001;
    right: calc(-100% - 24px);
    bottom: 34px;
`;

const MenuContent = styled.div`
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(11, 14, 56, 0.16);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 17px;
    color: #333333;
`;

const SubTitle = styled.p`
    color: #bbb;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 8px;
`;

const LinkButtonWithImage = (props: {
    href?: string;
    imageSrc: string;
    text: string;
    onClick: (e) => void;
}): JSX.Element => {
    return props.href ? (
        <Link href={props.href} passHref target="_blank">
            <LinkButton onClick={props.onClick}>
                <Image
                    src={props.imageSrc}
                    width={20}
                    height={20}
                    layout="fixed"
                    className="flex-shrink-0"
                    alt="img"
                />
                {props.text}
            </LinkButton>
        </Link>
    ) : (
        <LinkButton onClick={props.onClick}>
            <Image
                src={props.imageSrc}
                width={20}
                height={20}
                layout="fixed"
                className="flex-shrink-0"
                alt="img"
            />
            {props.text}
        </LinkButton>
    );
};

const LinkButton = styled.button`
    display: flex;
    flex-direction: row;
    gap: 12px;
    font-weight: 400;
    font-size: 0.875rem;
    color: #333;
    align-items: center;
    white-space: nowrap;
`;

const Divider = styled.div`
    height: 1px;
    background: #ebebeb;
`;
