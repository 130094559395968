import { AuthContext } from './AuthContext';
import { LicenseContextProvider } from './LicenseContext';
import { Paths } from '@/modules/Paths';
import { SlackIntegrationModel } from '@/ui/Setting/Integration/models/SlackIntegrationModel';
import { getCookie, setCookie } from '@/utils/BrowserCookie';
import { WorkspaceUpdate, WorkspacesUsersType } from 'callabo-api/src';
import { IntegrationType } from 'callabo-api/src/models/IntegrationType';
import { IntegrationModel } from 'libs/callabo-state/models/IntegrationModel';
import { WorkspaceModel } from 'libs/callabo-state/models/WorkspaceModel';
import { useRouter } from 'next/router';
import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { ApiKeyModel } from 'src/ui/Setting/Integration/models/ApiKeyModel';
import { useRouterQuery } from 'src/ui/Share/hooks/useRouterQuery';

export interface State {
    workspace: WorkspaceModel;
    workspaces: WorkspaceModel[];
    isNotExistWorkspace: boolean;
    workspaceNotFound: boolean;
    hasPermission: boolean;
    searchWorkspaceAsync: (workspaceId: string) => Promise<WorkspaceModel>;
    createWorkspaceAsync: (name: string, slug: string) => Promise<WorkspaceModel>;
    updateWorkspaceAsync: ({
        name,
        default_access_scope,
        default_transcribe_language,
    }: WorkspaceUpdate) => Promise<void>;
    integrations: IntegrationModel[];
    integrationForSlack: SlackIntegrationModel;
    apiKey: ApiKeyModel;
    createIntegrationAsync: (
        type: IntegrationType,
        code: string,
        workspaceId: string,
        setting?: { [property: string]: string }
    ) => Promise<void>;
    deleteIntegrationAsync: (id: number) => Promise<void>;
    createIntegrationForSlackAsync: (code: string, workspaceId: string) => Promise<void>;
    deleteIntegrationForSlackAsync: () => Promise<void>;
    createApiKeyAsync: () => Promise<void>;
    deleteApiKeyAsync: (id: number) => Promise<void>;
    isIntegrationLoaded: boolean;
}

const initialState: State = {
    workspace: undefined,
    workspaces: undefined,
    isNotExistWorkspace: false,
    workspaceNotFound: false,
    hasPermission: false,
    searchWorkspaceAsync: () => undefined,
    createWorkspaceAsync: () => undefined,
    updateWorkspaceAsync: () => undefined,
    integrations: undefined,
    integrationForSlack: undefined,
    apiKey: undefined,
    createIntegrationAsync: () => undefined,
    deleteIntegrationAsync: () => undefined,
    createIntegrationForSlackAsync: () => undefined,
    deleteIntegrationForSlackAsync: () => undefined,
    createApiKeyAsync: () => undefined,
    deleteApiKeyAsync: () => undefined,
    isIntegrationLoaded: false,
};

const WorkspaceContext = createContext<State>(initialState);

export const WorkspaceContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { authState } = useContext(AuthContext);
    /** 소속된 워크스페이스가 있는지 여부 */
    const [isNotExistWorkspace, setIsNotExistWorkspace] = useState(false);
    /** 접근하려는 워크스페이스가 404인지 여부 */
    const [workspaceNotFound, setWorkspaceNotFound] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);
    const [workspace, setWorkspace] = useState<WorkspaceModel>();
    const [workspaces, setWorkspaces] = useState<WorkspaceModel[]>([]);
    const [integrations, setIntegrations] = useState<IntegrationModel[]>([]);
    const [integrationForSlack, setIntegrationForSlack] = useState<
        SlackIntegrationModel | undefined
    >();
    const [apiKey, setApiKey] = useState<ApiKeyModel | undefined>();
    const [isIntegrationLoaded, setIsIntegrationLoaded] = useState(false);
    const [workspaceId, setWorkspaceId] = useRouterQuery('workspaceId');
    const router = useRouter();

    useEffect(() => {
        if (authState.type === 'signedOut') {
            setWorkspace(undefined);
        }
    }, [authState]);

    /**
     * 사용자의 워크스페이스 목록을 가져오고 활성화된 메인 워크스페이스를 세팅하는 과정
     */
    useEffect(() => {
        (async () => {
            if (authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            setWorkspaceNotFound(false);

            if (workspaceId) {
                try {
                    const workspaces =
                        await callaboApiClient.workspace.listWorkspaceV1WorkspaceGet();
                    if (workspaces && workspaces.length > 0) {
                        const workspaceModels = workspaces.map(
                            (workspace) => new WorkspaceModel(workspace)
                        );
                        setWorkspaces(workspaceModels);
                    }

                    const workspace = workspaces.find(
                        (workspace) => workspace.slug === workspaceId
                    );

                    // 조회한 워크스페이스에 소속되어 있을 때만 워크스페이스 정보를 저장한다.
                    if (
                        workspace.member_type !== null &&
                        workspace.member_type !== WorkspacesUsersType.DEACTIVATED
                    ) {
                        const workspaceModel = new WorkspaceModel(workspace);
                        await setCookie('last_used_workspace_id', workspaceModel.id);

                        setHasPermission(true);
                        setWorkspace(workspaceModel);
                        setIsNotExistWorkspace(false);
                        return;
                    }

                    // 예외적으로 소속되지 않은 워크스페이스를 그대로 저장
                    // record 페이지 링크를 공유받아 직접 접근하는 경우 대응 목적
                    if (router.pathname === Paths.RecordDetail) {
                        const workspaceModel = new WorkspaceModel(workspace);
                        setHasPermission(false);
                        setWorkspace(workspaceModel);
                        setIsNotExistWorkspace(false);
                        return;
                    }
                } catch (e) {
                    if (e.status === 404) {
                        setWorkspaceNotFound(true);
                    }
                }
            }

            const workspaces = await callaboApiClient.workspace.listWorkspaceV1WorkspaceGet();
            if (!workspaces || workspaces.length === 0) {
                setIsNotExistWorkspace(true);
                setWorkspace(undefined);
                setWorkspaces([]);
            } else {
                const workspaceModels = workspaces.map(
                    (workspace) => new WorkspaceModel(workspace)
                );
                setWorkspaces(workspaceModels);

                const lastUsedWorkspaceId = await getCookie('last_used_workspace_id');
                const defaultWorkspace =
                    workspaceModels.find((workspace) => workspace.id === lastUsedWorkspaceId) ??
                    workspaceModels[0];

                setWorkspace(defaultWorkspace);
                setIsNotExistWorkspace(false);
                if (workspaceId && workspaceId !== defaultWorkspace.id) {
                    setWorkspaceId(defaultWorkspace.id);
                }
            }
        })();
    }, [authState, workspaceId, router.pathname]);

    /**
     * 활성화된 메인 워크스페이스에 연동된 서비스 목록을 가져오는 과정
     */
    useEffect(() => {
        (async () => {
            if (!workspace || authState.type !== 'signedIn' || !hasPermission) return;
            const { callaboApiClient } = authState;
            try {
                const calendar =
                    await callaboApiClient.integration.listIntegrationV1WorkspaceSlugIntegrationGet(
                        workspace.id
                    );
                setIntegrations(calendar.map((integration) => new IntegrationModel(integration)));
            } catch (error) {
                setIntegrations([]);
            }
            try {
                const slackChannel =
                    await callaboApiClient.integration.getSlackChannelIntegrationV1WorkspaceSlugSlackGet(
                        workspace.id
                    );
                setIntegrationForSlack(
                    slackChannel ? new SlackIntegrationModel(slackChannel) : undefined
                );
            } catch (error) {
                setIntegrationForSlack(undefined);
            }
            try {
                const apiKey = await callaboApiClient.apiKey.getApiKeyV1WorkspaceSlugApiKeyGet(
                    workspace.id
                );
                setApiKey(apiKey ? new ApiKeyModel(apiKey) : undefined);
            } catch (error) {
                setApiKey(undefined);
            }
            setIsIntegrationLoaded(true);
        })();
    }, [workspace, authState, hasPermission]);

    const searchWorkspaceAsync = useCallback(
        async (workspaceId: string): Promise<WorkspaceModel> => {
            if (authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            const response = await callaboApiClient.workspace.getWorkspaceV1WorkspaceSlugGet(
                workspaceId
            );
            return new WorkspaceModel(response);
        },
        [authState]
    );

    const createWorkspaceAsync = useCallback(
        async (name: string, slug: string): Promise<WorkspaceModel> => {
            if (authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            const response = await callaboApiClient.workspace.createWorkspaceV1WorkspacePost({
                name,
                slug,
            });
            const newWorkspace = new WorkspaceModel(response);
            setWorkspace(newWorkspace);
            return newWorkspace;
        },
        [authState]
    );

    const updateWorkspaceAsync = useCallback(
        async ({
            name,
            default_access_scope,
            default_transcribe_language,
            color,
        }: WorkspaceUpdate): Promise<void> => {
            if (!workspace || authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            const response = await callaboApiClient.workspace.modifyWorkspaceV1WorkspaceSlugPatch(
                workspace.id,
                {
                    name,
                    default_access_scope,
                    default_transcribe_language,
                    color,
                }
            );
            const updatedWorkspace = new WorkspaceModel(response);
            setWorkspace(updatedWorkspace);
            setWorkspaces((workspaces) =>
                workspaces.map((workspace) =>
                    workspace.id === updatedWorkspace.id ? updatedWorkspace : workspace
                )
            );
        },
        [authState, workspace]
    );

    const createIntegrationAsync = useCallback(
        async (
            type: IntegrationType,
            code: string,
            workspaceId: string,
            setting?: { [property: string]: string }
        ) => {
            if (authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            const response =
                await callaboApiClient.integration.createIntegrationV1WorkspaceSlugIntegrationPost(
                    workspaceId,
                    {
                        type: type,
                        credential: {
                            code,
                        },
                        setting: { ...setting },
                    }
                );
            if (response) {
                setIntegrations((integrations) => [
                    ...integrations,
                    new IntegrationModel(response),
                ]);
            }
        },
        [authState]
    );

    const deleteIntegrationAsync = useCallback(
        async (id: number) => {
            if (!workspace || authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            await callaboApiClient.integration.deleteIntegrationV1WorkspaceSlugIntegrationIdDelete(
                id,
                workspace.id
            );
            setIntegrations((integrations) => integrations.filter((item) => item.id !== id));
        },
        [workspace, authState]
    );

    const createIntegrationForSlackAsync = useCallback(
        async (code: string, workspaceId: string) => {
            if (authState.type !== 'signedIn') return;
            const { callaboApiClient } = authState;
            const response =
                await callaboApiClient.integration.createSlackChannelIntegrationV1WorkspaceSlugSlackPost(
                    workspaceId,
                    {
                        code,
                        redirect_uri: `${window.location.origin}${Paths.IntegrationSlackChannel}`,
                    }
                );
            setIntegrationForSlack(new SlackIntegrationModel(response));
        },
        [authState]
    );

    const deleteIntegrationForSlackAsync = useCallback(async () => {
        if (authState.type !== 'signedIn' || !workspace) return;
        const { callaboApiClient } = authState;
        try {
            await callaboApiClient.integration.deleteSlackIntegrationV1WorkspaceSlugSlackDelete(
                workspace.id
            );
            setIntegrationForSlack(undefined);
        } catch (e) {
            if (e.status === 404) {
                setIntegrationForSlack(undefined);
                return;
            }
            throw e;
        }
    }, [authState, workspace]);

    const createApiKeyAsync = useCallback(async () => {
        if (authState.type !== 'signedIn' || !workspace) return;
        const { callaboApiClient } = authState;
        const response = await callaboApiClient.apiKey.createApiKeyV1WorkspaceSlugApiKeyPut(
            workspace.id
        );
        setApiKey(new ApiKeyModel(response));
    }, [authState, apiKey, workspace]);

    const deleteApiKeyAsync = useCallback(
        async (id: number) => {
            if (authState.type !== 'signedIn' || !workspace) return;
            const { callaboApiClient } = authState;
            try {
                await callaboApiClient.apiKey.deleteApiKeyV1WorkspaceSlugApiKeyIdDelete(
                    id,
                    workspace.id
                );
                setApiKey(undefined);
            } catch (e) {
                if (e.status === 404) {
                    setApiKey(undefined);
                }
                throw e;
            }
        },
        [authState, apiKey, workspace]
    );

    return (
        <WorkspaceContext.Provider
            value={{
                workspace,
                workspaces,
                searchWorkspaceAsync,
                createWorkspaceAsync,
                updateWorkspaceAsync,
                isNotExistWorkspace,
                workspaceNotFound,
                hasPermission,
                integrations,
                integrationForSlack,
                apiKey,
                createIntegrationAsync,
                deleteIntegrationAsync,
                createIntegrationForSlackAsync,
                deleteIntegrationForSlackAsync,
                createApiKeyAsync,
                deleteApiKeyAsync,
                isIntegrationLoaded,
            }}
        >
            <LicenseContextProvider workspace={hasPermission ? workspace : undefined}>
                {children}
            </LicenseContextProvider>
        </WorkspaceContext.Provider>
    );
};

export default WorkspaceContext;
