import { WorkspacePopover } from './WorkspacePopover';
import { PageProps } from 'libs/callabo-state/types/PageProps';

const Header = ({ locale }: PageProps): JSX.Element => {
    return (
        <div className="relative mt-6 mb-5">
            <WorkspacePopover locale={locale} />
        </div>
    );
};

export default Header;
