import { Popover, PopoverBackdrop, PopoverButton, PopoverPanel } from '@headlessui/react';
import { AnchorProps } from '@headlessui/react/dist/internal/floating';
import clsx from 'clsx';
import { Fragment } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';

type PopoverOptions = {
    anchor?: AnchorProps;
    transition?: boolean;
    backdrop?:
        | {
              className?: string;
          }
        | boolean;
};

export function CallaboPopover({
    children,
    options,
}: {
    children: [ReactElement, (props: { open: boolean; close: () => void }) => JSX.Element];
    options?: PopoverOptions;
}): JSX.Element {
    return (
        <Popover className="group/popover">
            {options.backdrop && (
                <PopoverBackdrop
                    className={clsx(
                        'fixed inset-0 w-full h-full z-50 cursor-default',
                        typeof options.backdrop !== 'boolean' && options.backdrop.className
                    )}
                />
            )}
            <PopoverButton as={Fragment}>{children[0]}</PopoverButton>
            <PopoverPanel
                transition={options?.transition ?? true}
                anchor={options?.anchor ?? 'bottom'}
                className="transition duration-200 ease-in-out data-[closed]:-translate-y-1 data-[closed]:opacity-0 shadow z-[101]"
            >
                {children[1]}
            </PopoverPanel>
        </Popover>
    );
}
