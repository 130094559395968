import { ApiKey } from 'callabo-api/src/models/ApiKey';

export class ApiKeyModel {
    constructor(private originResponse: ApiKey) {
        //
    }

    public get key(): string {
        return this.originResponse.key;
    }

    public get createdAt(): string {
        return this.originResponse.created_at;
    }

    public get id(): number {
        return this.originResponse.id;
    }
}
