import { LicenseSubscriptionModel } from '../models/LicenseSubscriptionModel';
import { MemberQuotaModel } from '../models/MemberQuotaModel';
import { RecordingQuotaModel } from '../models/RecordingQuotaModel';
import { WorkspaceAlert } from 'callabo-api/src';
import { LoadingState } from 'libs/callabo-state/types/LoadingState';
import { create } from 'zustand';

export type LicenseState = LoadingState<LicenseSubscriptionModel>;
export type MemberQuotaState = LoadingState<MemberQuotaModel>;
export type RecordingQuotaState = LoadingState<RecordingQuotaModel>;
export type LicenseAlertState = LoadingState<WorkspaceAlert>;

type Store = {
    licenseState: LicenseState;
    memberQuotaState: MemberQuotaState;
    recordingQuotaState: RecordingQuotaState;
    licenseAlertState: LicenseAlertState;
};

const useLicenseStore = create<Store>()(() => ({
    licenseState: { type: 'unloaded' },
    memberQuotaState: { type: 'unloaded' },
    recordingQuotaState: { type: 'unloaded' },
    licenseAlertState: { type: 'unloaded' },
}));

export const useLicenseState = (): LicenseState => useLicenseStore((state) => state.licenseState);
export const useMemberQuotaState = (): MemberQuotaState =>
    useLicenseStore((state) => state.memberQuotaState);
export const useRecordingQuotaState = (): RecordingQuotaState =>
    useLicenseStore((state) => state.recordingQuotaState);
export const useLicenseAlertState = (): LicenseAlertState =>
    useLicenseStore((state) => state.licenseAlertState);

export const getLicenseState = (): LicenseState => useLicenseStore.getState().licenseState;
export const getMemberQuotaState = (): MemberQuotaState =>
    useLicenseStore.getState().memberQuotaState;
export const getRecordingQuotaState = (): RecordingQuotaState =>
    useLicenseStore.getState().recordingQuotaState;
export const getLicenseAlertState = (): LicenseAlertState =>
    useLicenseStore.getState().licenseAlertState;

export const setLicenseState = (licenseState: LicenseState): void =>
    useLicenseStore.setState({ licenseState });
export const setMemberQuotaState = (memberQuotaState: MemberQuotaState): void =>
    useLicenseStore.setState({ memberQuotaState });
export const setRecordingQuotaState = (recordingQuotaState: RecordingQuotaState): void =>
    useLicenseStore.setState({ recordingQuotaState });
export const setLicenseAlertState = (licenseAlertState: LicenseAlertState): void =>
    useLicenseStore.setState({ licenseAlertState });
