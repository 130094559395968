import { PathName } from './PathName';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export enum Paths {
    Index = '/[locale]',
    Login = '/login',
    Docs = '/docs',
    DocsDetail = '/docs/[pageId]',
    ERROR503 = '/503',
    Signup = '/signup',
    SignupWithEmail = '/signup/email',
    SignupWithGoogle = '/signup/google',
    SignupWithApple = '/signup/apple',
    SignupWithMicrosoft = '/signup/microsoft',
    SignUpComplete = '/signup/complete',
    Join = '/w/[workspaceId]/join',
    WorkspaceIndex = '/w',
    GettingStarted = '/w/getting-started',
    CreateWorkspace = '/w/getting-started/new',
    Calls = '/w/[workspaceId]',
    RecordDetail = '/w/[workspaceId]/record/[recordId]',
    Member = '/w/[workspaceId]/member',
    Label = '/w/[workspaceId]/label',
    Config = '/w/[workspaceId]/config',
    Setting = '/w/[workspaceId]/setting',
    Integration = '/w/[workspaceId]/setting/integration',
    License = '/w/[workspaceId]/license',
    My = '/my',
    ForgotPassword = '/user/password/forgot',
    ResetPassword = '/user/password/reset',
    UserVerify = '/user/verify',
    Withdraw = '/user/withdraw',
    IntegrationGoogleCalendar = '/integration/google-calendar',
    IntegrationOutlookCalendar = '/integration/outlook-calendar',
    IntegrationSalesforce = '/integration/salesforce',
    IntegrationSlack = '/integration/slack',
    IntegrationSlackChannel = '/integration/slack-channel',
    TermService = '/terms/service',
    TermPrivacy = '/terms/privacy',
    TermCRMAddEndum = '/terms/addendum_save_phone_number',
    SharedRecords = '/w/[workspaceId]/share',
    ShareIndex = '/w/[workspaceId]/s',
    Share = '/w/[workspaceId]/s/[sharedId]',
    Events = '/w/[workspaceId]/events',
    Pricing = '/[locale]/pricing',
    Usage = '/[locale]/feature/usage',
    UsageDashboard = '/w/[workspaceId]/usage',
    NoticeForStripe = '/stripe-notice',
}

export enum PathsForExternal {
    GooglePlayStore = 'https://play.google.com/store/apps/details?id=ai.rtzr.callabo',
    AppleAppStore = 'https://apps.apple.com/kr/app/callabo/id6445909567',
    ChromeExtension = 'https://chrome.google.com/webstore/detail/callabo-%EC%BD%9C%EB%9D%BC%EB%B3%B4-%EC%84%B8%EC%9D%BC%EC%A6%88%EC%BD%9C%EC%97%90%EC%84%9C-%EC%B0%BE%EB%8A%94-%EB%A7%A4%EC%B6%9C/dalcbknelnfobfkkhefbbhhfpdiibhgn?hl=ko&authuser=0',
    WhatTime = 'https://whattime.co.kr/callabo/onboarding',
    OpenChat = 'https://open.kakao.com/o/gSjBQqif',
    Email = 'mailto:callabo@rtzr.ai',
    Blog = 'https://callabo.ai/blog',
    IntroductionDocsForKO = 'https://sclu.io/share/bulk/file/8f044625d5132957',
    IntroductionDocsForJA = 'https://sclu.io/share/bulk/file/2d0a7879adc8d4a7',
    LinkedIn = 'https://www.linkedin.com/in/callabo-ai-985734262/',
    RTZR = 'https://www.rtzr.ai/',
    PricingGoogleForm = 'https://forms.gle/PZ114h7GNtrGqzck9',
    GoogleCalendar = 'https://calendar.google.com/calendar/',
    OutLookCalendar = 'https://outlook.office365.com/calendar/view/month',
    ChannelTalk = 'https://callabo.channel.io',
}

/**
 * @deprecated {@link PathName}을 활용해주세요.
 */
export function replaceWorkspaceId(path: Paths, workspaceId: string): string {
    return new PathName(path).setWorkspaceId(workspaceId).toString();
}

export const CALLABO_API_BASE_URL = publicRuntimeConfig.API_BASE_URL as string;
