/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum LicenseSubscriptionStatus {
    UNPAID = 'unpaid',
    PAID = 'paid',
    PAYMENT_FAILED = 'payment_failed',
    REVOKED = 'revoked',
}
