import Script from 'next/script';

export function GoogleTagManager({ gaId }: { gaId: string }): JSX.Element {
    return (
        gaId && (
            <>
                <Script async src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`} />
                <Script
                    id='google-tag-manager'
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    
                    gtag('config', '${gaId}');`,
                    }}
                />
            </>
        )
    );
}
