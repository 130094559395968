import { Colors } from './Styles';
import { Theme, css } from '@emotion/react';

export default function getTheme(): Theme {
    return {
        color: Colors,
        fonts: {
            text_regular_20: css`
                font-size: 1.25rem;
                line-height: 26px;
            `,
            text_regular_18: css`
                font-size: 1.125rem;
                line-height: 24px;
            `,
            text_regular_16: css`
                font-size: 1rem;
                line-height: 22px;
            `,
            text_regular_14: css`
                font-size: 0.875rem;
                line-height: 20px;
            `,
            text_regular_12: css`
                font-size: 0.75rem;
                line-height: 18px;
            `,
            text_regular_10: css`
                font-size: 0.625rem;
                line-height: 16px;
            `,
        },
        includeDefaultFont: () => `
            
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 17px;
        `,
    };
}
