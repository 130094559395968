/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum IntegrationType {
    GOOGLE_CALENDAR = 'google_calendar',
    OUTLOOK_CALENDAR = 'outlook_calendar',
    SALESFORCE = 'salesforce',
    PIPEDRIVE = 'pipedrive',
    SLACK = 'slack',
}
