import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

type Query =
    | 'tab'
    | 'workspaceId'
    | 'query'
    | 'startAt'
    | 'recordId'
    | 'sharedId'
    | 'pw'
    | 'token'
    | 'referer'
    | 'locale'
    | 'v'
    | 'legacy';

type ListQuery = 'label_ids';

export function useRouterQuery(
    query: Query,
    initialValue?: string
): [string | undefined, (value: string) => void, () => void];
export function useRouterQuery(
    query: ListQuery,
    initialValue?: string[]
): [string[] | undefined, (value: string[]) => void, () => void];

export function useRouterQuery(
    queryKey: Query | ListQuery,
    initialValue?: string | string[]
): [string | string[], (value: string | string[]) => void, () => void] {
    const router = useRouter();

    useEffect(() => {
        if (!router.isReady) return;
        if (!router.query[queryKey] && initialValue) {
            setQuery(initialValue);
        }
    }, [router.isReady]);

    const setQuery = useCallback(
        (value: string | string[]) => {
            if (router.query[queryKey] === value) return;

            router.replace(
                {
                    pathname: router.pathname,
                    query: { ...router.query, [queryKey]: value },
                },
                '',
                { shallow: true }
            );
        },
        [router.query]
    );

    const deleteQuery = useCallback(() => {
        if (!Object.keys(router.query).some((key) => key === queryKey)) return;
        router.replace(
            {
                pathname: router.pathname,
                query: Object.keys(router.query)
                    .filter((key) => key !== queryKey)
                    .reduce((obj, key) => {
                        return Object.assign(obj, {
                            [key]: router.query[key],
                        });
                    }, {}),
            },
            '',
            { shallow: true }
        );
    }, [router.query]);

    return [router.query[queryKey], setQuery, deleteQuery];
}
