import { Locales } from 'callabo-locale/src/hooks/useTranslate';

export const floor = (value: number, decimal: number): number => {
    return Math.floor(value * 10 ** decimal) / 10 ** decimal;
};

export const toDoubleDigit = (value: number): string => {
    return `${value}`.padStart(2, '0');
};

export const msTohhmmss = (value: number): string => {
    const ms = value % 1000;
    const second = ((value - ms) % (1000 * 60)) / 1000;
    const minute = ((value - ms - second * 1000) % (1000 * 60 * 60)) / (1000 * 60);
    const hour = (value - ms - minute * 60 * 1000 - second * 1000) / (1000 * 60 * 60);

    return (
        (hour === 0 ? '' : `${toDoubleDigit(hour)}:`) +
        `${toDoubleDigit(minute)}:${toDoubleDigit(second)}`
    );
};

const msToMinute = (ms: number): number => {
    return Math.floor(ms / 1000 / 60);
};

const msToHour = (ms: number): number => {
    return Math.floor(ms / 1000 / 60 / 60);
};

/**
 * ms단위를 시간 단위로, 1시간 미만이라면 분단위로, 1분 미만이라면 초단위로 변환
 * @param ms
 */
export const msToHourOrMinuteOrSecondString = (ms: number): string => {
    if (ms < 60 * 1000) {
        return `${Math.floor(ms / 1000)}초`;
    }

    if (ms < 60 * 60 * 1000) {
        return `${msToMinute(ms)}분`;
    }

    return `${msToHour(ms)}시간`;
};

export const msToBiggist2Units = (ms: number, l: Locales): string => {
    const units: Record<Locales, string[]> = {
        ko: ['시간', '분', '초'],
        ja: ['時間', '分', '秒'],
        en: ['h', 'm', 's'],
    };
    const resultArray = [];

    const isOverOneHour = ms >= 60 * 60 * 1000;
    if (isOverOneHour) {
        const hour = msToHour(ms);
        resultArray.push(`${hour}${units[l][0]}`);
        ms -= hour * 60 * 60 * 1000;
    }

    const isOverOneMinute = ms >= 60 * 1000;
    if (isOverOneMinute) {
        const minute = msToMinute(ms);
        resultArray.push(`${minute}${units[l][1]}`);
        ms -= minute * 60 * 1000;
    }

    if (isOverOneHour) {
        return resultArray.join(' ');
    }

    if (ms >= 1000) {
        resultArray.push(`${Math.floor(ms / 1000)}${units[l][2]}`);
    }

    if (resultArray.length > 0) {
        return resultArray.join(' ');
    }

    return `0${units[l][2]}`;
};

export const secToHourMinute = (sec: number, l: Locales): string => {
    const units: Record<Locales, string[]> = {
        ko: ['시간', '분'],
        ja: ['時間', '分'],
        en: ['h', 'm'],
    };
    const resultArray = [];

    const hour = Math.floor(sec / 3600);
    if (hour > 0) {
        resultArray.push(`${hour.toLocaleString()}${units[l][0]}`);
        sec -= hour * 3600;
    }

    const minute = Math.floor(sec / 60);
    if (minute > 0) {
        resultArray.push(`${minute}${units[l][1]}`);
    }

    if (resultArray.length > 0) {
        return resultArray.join(' ');
    }

    return `0${units[l][1]}`;
};

export function secToMs(sec: number): number {
    return Math.round(sec * 1000);
}

export const utteranceIdToMs = (utteranceStartAt: bigint): number => {
    if (utteranceStartAt == undefined) return 0;
    return Number(BigInt((1 << 26) - 1) & utteranceStartAt);
};
