/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Attendee = {
    id?: string;
    email: string;
    display_name?: string;
    organizer?: boolean;
    /**
     * Whether this entry represents the calendar on which this copy of the event appears.
     */
    self?: boolean;
    /**
     * Whether the attendee is a resource. ex) Zed, Riven
     */
    resource?: boolean;
    optional?: boolean;
    response_status?: Attendee.response_status;
    comment?: string;
    additional_guests?: number;
};

export namespace Attendee {
    export enum response_status {
        NEEDS_ACTION = 'needsAction',
        DECLINED = 'declined',
        TENTATIVE = 'tentative',
        ACCEPTED = 'accepted',
    }
}
