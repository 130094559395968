/**
 * amplitude 문서 : https://developers.amplitude.com/docs/javascript
 * google analytics 문서: https://firebase.google.com/docs/analytics/get-started?platform=web
 */
import amplitude from 'amplitude-js';
import { AuthInfoProvider, UserStatus, Workspace, WorkspacesUsersType } from 'callabo-api/src';
import {
    Analytics as GoogleAnalytics,
    logEvent,
    setUserId,
    setUserProperties,
} from 'firebase/analytics';
import { LicensePlan } from 'libs/callabo-state/types/License';
import { Phase } from 'libs/callabo-state/types/Phase';
import { RecordsTabType } from 'libs/callabo-state/types/RecordsTabType';
import getConfig from 'next/config';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const PHASE = serverRuntimeConfig.PHASE || publicRuntimeConfig.PHASE;
const AMPLITUDE_API_KEY =
    serverRuntimeConfig.AMPLITUDE_API_KEY || publicRuntimeConfig.AMPLITUDE_API_KEY;

interface CurrentTabType {
    recordList: RecordsTabType;
}

interface UserProps {
    email: string;
    workspaceId: string;
    plan: LicensePlan | 'expired';
    status: UserStatus;
    type: WorkspacesUsersType;
    grantType: AuthInfoProvider;
    calendarAuth: IntegrationAuthType;
    accessPreset: Workspace.default_access_scope;
}

export const enum ReSearchType {
    Yes = 'yes',
    No = 'no',
}

export const enum IntegrationAuthType {
    Yes = 'Y',
    No = 'N',
}

export class Analytics {
    private _enableAmplitude = !!AMPLITUDE_API_KEY;

    public googleAnalytics: GoogleAnalytics;

    /** 현재 선택된 탭: 각각 대화목록/대화방/트래커설정 페이지에서 사용 */
    public currentTab: CurrentTabType = {
        recordList: RecordsTabType.All,
    };
    /** 페이지 진입 루트 */
    public from = '';
    /** 기존 검색결과가 있는 상태에서의 재검색인지 여부 */
    public reSearch: ReSearchType = ReSearchType.No;

    constructor() {
        // init amplitude
        if (this._enableAmplitude) {
            const options = { includeReferrer: true, includeUtm: true, includeGclid: true };
            amplitude.getInstance().init(AMPLITUDE_API_KEY, undefined, options);
        }
    }

    public setUserId(userId: string): void {
        if (this.googleAnalytics) setUserId(this.googleAnalytics, userId);
        if (this._enableAmplitude) amplitude.getInstance().setUserId(userId);
    }

    public logEvent(eventName: string, eventProperties?: any | undefined): void {
        if (this.googleAnalytics) logEvent(this.googleAnalytics, eventName, eventProperties);
        if (this._enableAmplitude) amplitude.getInstance().logEvent(eventName, eventProperties);
    }

    public setVersionName(version: string): void {
        if (PHASE !== Phase.production) return;
        amplitude.getInstance().setVersionName(version);
    }

    public setDeviceId(deviceId: string): void {
        if (PHASE !== Phase.production) return;
        amplitude.getInstance().setDeviceId(deviceId);
    }

    public setUserProperties(props: UserProps): void {
        const properties = {
            email: props.email,
            workspace_slug: props.workspaceId,
            license: props.plan,
            status: props.status,
            type: props.type,
            grant_type: props.grantType,
            calendar_auth_yn: props.calendarAuth,
            viewer_access_preset: props.accessPreset,
        };
        if (this.googleAnalytics) setUserProperties(this.googleAnalytics, properties);
        if (this._enableAmplitude) amplitude.getInstance().setUserProperties(properties);
    }
}

const analyticsInstance = new Analytics();
export default analyticsInstance;
