/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { SlackChannelIntegration } from '../models/SlackChannelIntegration';
import type { SlackIntegration } from '../models/SlackIntegration';
import type { SlackIntegrationCreate } from '../models/SlackIntegrationCreate';

export class SlackService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Slack Channel Integration
     * Slack channel incoming-webhook 연동을 조회합니다.
     * @param slug
     * @returns SlackChannelIntegration Successful Response
     * @throws ApiError
     */
    public getSlackChannelIntegrationV1WorkspaceSlugSlackGet(
        slug: string
    ): CancelablePromise<SlackChannelIntegration> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/slack',
            path: {
                slug: slug,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Slack Channel Integration
     * Slack channel incoming-webhook 연동을 새로 추가합니다.
     * @param slug
     * @param requestBody
     * @returns SlackChannelIntegration Successful Response
     * @throws ApiError
     */
    public createSlackChannelIntegrationV1WorkspaceSlugSlackPost(
        slug: string,
        requestBody: SlackIntegrationCreate
    ): CancelablePromise<SlackChannelIntegration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/slack',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Slack Integration
     * @param slug
     * @returns void
     * @throws ApiError
     */
    public deleteSlackIntegrationV1WorkspaceSlugSlackDelete(slug: string): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/slack',
            path: {
                slug: slug,
            },
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Slack Integrations
     * Slack 연동 목록을 조회합니다.
     * @returns SlackIntegration Successful Response
     * @throws ApiError
     */
    public listSlackIntegrationsV1SlackIntegrationGet(): CancelablePromise<
        Array<SlackIntegration>
    > {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/slack-integration',
        });
    }

    /**
     * Create Slack Integration
     * Slack 연동을 새로 추가합니다.
     * @param requestBody
     * @returns SlackIntegration Successful Response
     * @throws ApiError
     */
    public createSlackIntegrationV1SlackIntegrationPost(
        requestBody: SlackIntegrationCreate
    ): CancelablePromise<SlackIntegration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/slack-integration',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Slack Integration
     * Slack 연동을 제거합니다. Slack team에 대한 모든 user 레벨 연동이 제거되면 team 레벨 연동도 제거됩니다.
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteSlackIntegrationV1SlackIntegrationIdDelete(id: number): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/slack-integration/{id}',
            path: {
                id: id,
            },
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Callback
     * @returns any Successful Response
     * @throws ApiError
     */
    public slackCallbackV1SlackIntegrationCallbackPost(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/slack-integration/callback',
        });
    }
}
