import { AuthContext } from './AuthContext';
import { DailyUsage } from 'callabo-api/src';
import {
    ExpiredLicenseSubscription,
    LicenseSubscriptionModel,
} from 'libs/callabo-state/models/LicenseSubscriptionModel';
import { MemberQuotaModel } from 'libs/callabo-state/models/MemberQuotaModel';
import { RecordingQuotaModel } from 'libs/callabo-state/models/RecordingQuotaModel';
import { WorkspaceModel } from 'libs/callabo-state/models/WorkspaceModel';
import {
    LicenseAlertState,
    LicenseState,
    MemberQuotaState,
    RecordingQuotaState,
    getLicenseState,
    setLicenseAlertState,
    setLicenseState,
    setMemberQuotaState,
    setRecordingQuotaState,
    useLicenseAlertState,
    useLicenseState,
    useMemberQuotaState,
    useRecordingQuotaState,
} from 'libs/callabo-state/store/useLicenseStore';
import { LicensePeriod, PaidLicensePlan } from 'libs/callabo-ui/types/License';
import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

export interface State {
    licenseState: LicenseState;
    updateLicenseAsync: (
        count: number,
        period: LicensePeriod,
        plan: PaidLicensePlan
    ) => Promise<void>;
    scheduleCancellationNextLicenseAsync: () => Promise<void>;
    dailyUsageList: DailyUsage[];
    fetchDailyUsageList: (startDate: string, endDate?: string) => Promise<DailyUsage[]>;
    memberQuotaState: MemberQuotaState;
    recordingQuotaState: RecordingQuotaState;
    fetchMemberQuotaAsync: () => Promise<void>;
    fetchRecordingQuotaAsync: () => Promise<void>;
    licenseAlertState: LicenseAlertState;
    fetchLicenseAlertAsync: () => Promise<void>;
}

const initialState: State = {
    licenseState: { type: 'unloaded' },
    updateLicenseAsync: () => undefined,
    scheduleCancellationNextLicenseAsync: () => undefined,
    dailyUsageList: undefined,
    fetchDailyUsageList: () => undefined,
    memberQuotaState: { type: 'unloaded' },
    recordingQuotaState: { type: 'unloaded' },
    fetchMemberQuotaAsync: () => undefined,
    fetchRecordingQuotaAsync: () => undefined,
    licenseAlertState: { type: 'unloaded' },
    fetchLicenseAlertAsync: () => undefined,
};

interface Props {
    workspace: WorkspaceModel;
}

const LicenseContext = createContext<State>(initialState);

export const LicenseContextProvider: React.FC<PropsWithChildren<Props>> = ({
    children,
    workspace,
}) => {
    const { authState } = useContext(AuthContext);
    const licenseState = useLicenseState();
    const memberQuotaState = useMemberQuotaState();
    const recordingQuotaState = useRecordingQuotaState();
    const licenseAlertState = useLicenseAlertState();

    const [dailyUsageList, setDailyUsageList] = useState<DailyUsage[]>([]);

    const fetchMemberQuotaAsync = useCallback(async () => {
        setMemberQuotaState({ type: 'loading' });
        if (authState.type !== 'signedIn' || !workspace) return;
        try {
            const memberQuota =
                await authState.callaboApiClient.quota.getMemberQuotaV1WorkspaceSlugQuotaMemberGet(
                    workspace.id
                );
            setMemberQuotaState({
                type: 'loaded',
                detail: new MemberQuotaModel(memberQuota),
            });
        } catch (error) {
            setMemberQuotaState({ type: 'error', error });
            throw error;
        }
    }, [authState, workspace]);

    const fetchRecordingQuotaAsync = useCallback(async () => {
        if (authState.type !== 'signedIn' || !workspace) return;
        setRecordingQuotaState({ type: 'loading' });
        try {
            const recordingQuota =
                await authState.callaboApiClient.quota.getRecordingQuotaV1WorkspaceSlugQuotaRecordingGet(
                    workspace.id
                );
            setRecordingQuotaState({
                type: 'loaded',
                detail: new RecordingQuotaModel(recordingQuota),
            });
        } catch (error) {
            setRecordingQuotaState({ type: 'error', error });
            throw error;
        }
    }, [authState, workspace]);

    const fetchLicenseAsync = useCallback(async () => {
        if (authState.type !== 'signedIn' || !workspace) return;
        setLicenseState({ type: 'loading' });
        try {
            const response =
                await authState.callaboApiClient.license.getActiveLicenseV1WorkspaceSlugLicenseActiveGet(
                    workspace.id
                );
            setLicenseState({
                type: 'loaded',
                detail: response
                    ? new LicenseSubscriptionModel(response)
                    : ExpiredLicenseSubscription,
            });
            fetchMemberQuotaAsync();
            fetchRecordingQuotaAsync();
        } catch (error) {
            setLicenseState({ type: 'error', error });
            throw error;
        }
    }, [authState, workspace]);

    const fetchLicenseAlertAsync = useCallback(async () => {
        if (authState.type !== 'signedIn' || !workspace) return;
        setLicenseAlertState({ type: 'loading' });
        try {
            const response =
                await authState.callaboApiClient.workspace.getWorkspaceServiceAlertV1WorkspaceSlugAlertGet(
                    workspace.id
                );
            setLicenseAlertState({
                type: 'loaded',
                detail: response,
            });
        } catch (error) {
            setLicenseAlertState({ type: 'error', error });
        }
    }, [authState, workspace]);

    useEffect(() => {
        (async () => {
            await fetchLicenseAsync();
            await fetchLicenseAlertAsync();
        })();
    }, [fetchLicenseAsync, fetchLicenseAlertAsync, workspace]);

    const updateLicenseAsync = useCallback(
        async (count: number, period: LicensePeriod, plan: PaidLicensePlan) => {
            if (authState.type !== 'signedIn' || !workspace) return;
            if (period === 'P2W') return;

            const response =
                await authState.callaboApiClient.license.updateLicenseV1WorkspaceSlugLicenseActivePut(
                    workspace.id,
                    {
                        plan: plan,
                        count,
                        period,
                    }
                );

            const oldLicenseState = getLicenseState();
            if (oldLicenseState.type === 'loaded') {
                setLicenseState({
                    ...oldLicenseState,
                    detail: response
                        ? new LicenseSubscriptionModel(response)
                        : ExpiredLicenseSubscription,
                });
            }
        },
        [authState, workspace]
    );

    const scheduleCancellationNextLicenseAsync = useCallback(async () => {
        if (authState.type !== 'signedIn' || !workspace) return;
        try {
            await authState.callaboApiClient.license.scheduleCancellationV1WorkspaceSlugLicenseNextDelete(
                workspace.id
            );
        } catch (error) {
            throw new Error(error);
        }
        await fetchLicenseAsync();
        await fetchLicenseAlertAsync();
    }, [authState, workspace, fetchLicenseAsync, fetchLicenseAlertAsync]);

    const fetchDailyUsageList = useCallback(
        async (startDate: string, endDate?: string): Promise<DailyUsage[]> => {
            if (authState.type !== 'signedIn' || !workspace) return;
            try {
                const response =
                    await authState.callaboApiClient.usage.listUsageDailyV1WorkspaceSlugUsageDailyGet(
                        workspace.id,
                        startDate,
                        endDate
                    );
                if (!response) return;
                setDailyUsageList(
                    response.map((usage) => {
                        return {
                            ...usage,
                            total_duration: Math.ceil(usage.total_duration / 60),
                        };
                    })
                );
                return response;
            } catch (error) {
                setDailyUsageList([]);
            }
        },
        [authState, workspace]
    );

    return (
        <LicenseContext.Provider
            value={{
                licenseState,
                updateLicenseAsync,
                scheduleCancellationNextLicenseAsync,
                dailyUsageList,
                memberQuotaState,
                recordingQuotaState,
                licenseAlertState,
                fetchDailyUsageList,
                fetchMemberQuotaAsync,
                fetchRecordingQuotaAsync,
                fetchLicenseAlertAsync,
            }}
        >
            {children}
        </LicenseContext.Provider>
    );
};

export default LicenseContext;
