import { BackendApiClient } from './BackendApiClient';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import { type OpenAPIConfig } from './core/OpenAPI';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class CallaboApiClient extends BackendApiClient {
    constructor(
        config?: Partial<OpenAPIConfig>,
        HttpRequest: HttpRequestConstructor = AxiosHttpRequest
    ) {
        super(config, HttpRequest);
    }
}
