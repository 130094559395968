import { Workspace, WorkspacesUsersType } from 'callabo-api/src';
import { TranscribingLanguageType } from 'libs/callabo-state/types/TranscribingLanguage';
import { FEATURE_POLICY, FeaturePolicyNames } from 'libs/rtzr-commons/FeaturePolicy';

export class WorkspaceModel {
    constructor(private originResponse: Workspace) {
        //
    }

    public get id(): string {
        return this.originResponse.slug;
    }

    public get name(): string {
        return this.originResponse.name;
    }

    public get member_type(): WorkspacesUsersType {
        return this.originResponse.member_type;
    }

    public hasPolicy(key: FeaturePolicyNames): boolean {
        return FEATURE_POLICY[key][this.originResponse.member_type];
    }

    public get createdAt(): string {
        return this.originResponse.created_at;
    }

    public get accessScope(): Workspace.default_access_scope {
        return this.originResponse.default_access_scope;
    }

    public get language(): TranscribingLanguageType {
        switch (this.originResponse.default_transcribe_language) {
            case Workspace.default_transcribe_language.EN:
                return 'en';
            case Workspace.default_transcribe_language.JA:
                return 'ja';
            case Workspace.default_transcribe_language.KO:
            default:
                return 'ko';
        }
    }

    public get color(): string {
        return this.originResponse.color;
    }
}
