import { useRouter } from 'next/router';
import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { UrlObject } from 'url';

type Url = UrlObject | string;

export default interface SafeNavigator {
    push: (url: Url) => Promise<void>;
    replace: (url: Url) => Promise<void>;
}

const SafeNavigatorContext = createContext<SafeNavigator>({
    push: async () => {
        /* noop */
    },
    replace: async () => {
        /* noop */
    },
});

type Observer = {
    resolve: (value: void | PromiseLike<void>) => void;
    reject: (reason?: any) => void;
};

export function SafeNavigatorProvider({ children }: PropsWithChildren): JSX.Element {
    const router = useRouter();
    const [url, setUrl] = useState<Url | null>(null);
    const [method, setMethod] = useState<'push' | 'replace'>('push');
    const [redirectingUrl, setRedirectingUrl] = useState<Url | null>(null);
    const observersRef = useRef<Observer[]>([]);
    const push = useCallback((url: Url): Promise<void> => {
        setUrl(url);
        setMethod('push');
        return new Promise<void>((resolve, reject) => {
            observersRef.current.push({ resolve, reject });
        });
    }, []);
    const replace = useCallback((url: Url) => {
        setUrl(url);
        setMethod('replace');
        return new Promise<void>((resolve, reject) => {
            observersRef.current.push({ resolve, reject });
        });
    }, []);
    const navigator = useMemo(() => ({ push, replace }), [push, replace]);
    useEffect(() => {
        if (!router.isReady) {
            return;
        }
        if (redirectingUrl !== null) {
            // prevent redirecting loop
            return;
        }
        if (url === null) {
            return;
        }
        const observers = observersRef.current.splice(0, observersRef.current.length);
        setRedirectingUrl(url);
        const handleRouteChangeComplete = () => {
            setRedirectingUrl(null);
            setUrl(null);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
            observers.forEach(({ resolve }) => {
                resolve();
            });
        };
        const handleRouteChangeError = (err: any) => {
            setRedirectingUrl(null);
            setUrl(null);
            router.events.off('routeChangeError', handleRouteChangeError);
            observers.forEach(({ reject }) => {
                reject(err);
            });
        };
        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        router.events.on('routeChangeError', handleRouteChangeError);
        if (method === 'push') {
            router.push(url);
        } else {
            router.replace(url);
        }
    }, [url, redirectingUrl, router.isReady]);
    return (
        <SafeNavigatorContext.Provider value={navigator}>
            {redirectingUrl !== null ? null : children}
        </SafeNavigatorContext.Provider>
    );
}

/**
 * 안전한 네비게이터를 반환합니다.
 * 네비게이터의 Promise는 네비게이션 완료 후에 resolve 되는것을 보장합니다.
 * 단, resolve / reject 되는 시점은 이후 몇차례의 네비게이션 이벤트가 발생한 후 일 수 있습니다.
 *
 * @returns {SafeNavigator} navigator
 */
export function useSafeNavigator(): SafeNavigator {
    return useContext(SafeNavigatorContext);
}
