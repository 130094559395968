import { CALLABO_API_BASE_URL } from '@/modules/Paths';
import { LoginResponse } from 'callabo-api/src';
import { RefreshAuthentication } from 'callabo-api/src//models/RefreshAuthentication';
import { CallaboApiClient } from 'callabo-api/src/CallaboApiClient';
import { IStorage } from 'libs/rtzr-commons/IStorage';
import getIndexedDbStorageAsync from 'libs/rtzr-commons/IndexedDbStorage';
import { MemoryCache } from 'libs/rtzr-commons/MemoryCache';

/**
 * 인증 토큰 관리
 * indexed db를 활용하여, 각 탭과 worker에서의 토큰을 동기화한다.
 */
export class AuthTokenStorage {
    private _isRefreshing = false;
    public get isRefreshing(): boolean {
        return this._isRefreshing;
    }

    public getAccessTokenAsync = (): Promise<string> => {
        return this._database.getAsync<string>('access_token', true);
    };

    private setAccessTokenAsync = (accessToken: string): Promise<void> => {
        return this._database.setAsync('access_token', accessToken);
    };

    public getRefreshTokenAsync = (): Promise<string> => {
        return this._database.getAsync<string>('refresh_token', true);
    };

    public setRefreshTokenAsync = (refreshToken: string): Promise<void> => {
        return this._database.setAsync('refresh_token', refreshToken);
    };

    public isExistTokenAsync = async (): Promise<boolean> => {
        return !!(await this.getAccessTokenAsync()) && !!(await this.getRefreshTokenAsync());
    };

    public setAccessTokenExpiredDate = (tokenExpiredDate: Date): Promise<void> => {
        return this._database.setAsync<string>(
            'token_expired_date',
            tokenExpiredDate.toISOString()
        );
    };

    public getAccessTokenExpiredDate = (): Promise<string> => {
        return this._database.getAsync<string>('token_expired_date', true);
    };

    private constructor(private readonly _database: IStorage) {}

    /**
     * 토큰을 재갱신한다.
     */
    public refreshAsync = async (): Promise<LoginResponse> => {
        // console.log('refreshAsync');
        const apiClientWithoutToken = new CallaboApiClient({
            BASE: CALLABO_API_BASE_URL,
        });
        if (this._isRefreshing) return;

        this._isRefreshing = true;
        // console.trace('_isRefreshing true');
        try {
            const refreshToken = await this.getRefreshTokenAsync();
            const authRes = await apiClientWithoutToken.user.authV1UserAuthPost({
                grant_type: RefreshAuthentication.grant_type.REFRESH_TOKEN,
                refresh_token: refreshToken,
            });
            await this.setAccessTokenAsync(authRes.token.access_token);
            await this.setRefreshTokenAsync(authRes.token.refresh_token);
            const tokenCreateAt = authRes.token.created_at;
            const tokenExpiredDate = new Date(tokenCreateAt);
            tokenExpiredDate.setSeconds(
                tokenExpiredDate.getSeconds() + Math.round(authRes.token.expires_in / 2)
            );
            await this.setAccessTokenExpiredDate(tokenExpiredDate);
            return authRes as LoginResponse;
            // } catch (e) {
            //     console.log(`refresh중 에러 발생 ${e}`);
        } finally {
            this._isRefreshing = false;
        }
    };

    public close = (): void => {
        this._database.removeMany(['access_token', 'refresh_token', 'token_expired_date']);
    };

    public static createAsync = async (
        accessToken: string,
        refreshToken: string,
        tokenExpiredDate: Date
    ): Promise<AuthTokenStorage> => {
        const indexedDbStorage = await getIndexedDbStorageAsync();
        await indexedDbStorage.setAsync<string>('access_token', accessToken);
        await indexedDbStorage.setAsync<string>('refresh_token', refreshToken);
        await indexedDbStorage.setAsync<string>(
            'token_expired_date',
            tokenExpiredDate.toISOString()
        );
        return new AuthTokenStorage(indexedDbStorage);
    };

    public static createForShareAsync = async (
        accessToken: string,
        refreshToken: string,
        tokenExpiredDate: Date
    ): Promise<AuthTokenStorage> => {
        const memoryCache = new MemoryCache();
        await memoryCache.setAsync('access_token', accessToken);
        await memoryCache.setAsync('refresh_token', refreshToken);
        await memoryCache.setAsync('token_expired_date', tokenExpiredDate.toISOString());
        return new AuthTokenStorage(memoryCache);
    };
}
