/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { SalesforceContact } from '../models/SalesforceContact';
import type { SalesforceLead } from '../models/SalesforceLead';
import type { SalesforceOpportunity } from '../models/SalesforceOpportunity';
import type { SalesforceWorkOrder } from '../models/SalesforceWorkOrder';

export class SalesforceService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Salesforce Sobjects
     * @param slug
     * @param kw search keyword
     * @param keyword search keyword
     * @param t
     * @returns any Successful Response
     * @throws ApiError
     */
    public salesforceSobjectsV1WorkspaceSlugSalesforceSobjectsGet(
        slug: string,
        kw?: string,
        keyword?: string,
        t?: Array<'contact' | 'lead' | 'oppty'>
    ): CancelablePromise<
        Array<SalesforceLead | SalesforceOpportunity | SalesforceContact | SalesforceWorkOrder>
    > {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/salesforce/sobjects',
            path: {
                slug: slug,
            },
            query: {
                kw: kw,
                keyword: keyword,
                t: t,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Salesforce Sobjects Work Order
     * @param slug
     * @param endDate 작업주문 마감 기한 필터
     * @returns any Successful Response
     * @throws ApiError
     */
    public salesforceSobjectsWorkOrderV1WorkspaceSlugSalesforceSobjectsWorkOrderGet(
        slug: string,
        endDate?: string
    ): CancelablePromise<
        Array<SalesforceLead | SalesforceOpportunity | SalesforceContact | SalesforceWorkOrder>
    > {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/salesforce/sobjects/work-order',
            path: {
                slug: slug,
            },
            query: {
                end_date: endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
