import styled from '@emotion/styled';
import { Style } from 'libs/rtzr-design/common';
import * as React from 'react';

export interface CommonLayoutProps {
    sidebar: React.ReactNode;
    content: React.ReactNode;
}

export function CommonLayout(props: CommonLayoutProps): JSX.Element {
    return (
        <Container>
            <Style.DesktopOnly>{props.sidebar}</Style.DesktopOnly>
            <Body>{props.content}</Body>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    background: #f6f6f6;
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;
