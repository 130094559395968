import { AuthContext } from '@/contexts/AuthContext';
import WorkspaceContext from '@/contexts/WorkspaceContext';
import { IntegrationType } from 'callabo-api/src';
import { useLicenseState } from 'libs/callabo-state/store/useLicenseStore';
import analyticsInstance, {
    IntegrationAuthType,
} from 'libs/rtzr-commons/modules/marketing/Analytics';
import { useContext, useEffect } from 'react';

export const useAnalyticsUserProperty = (): void => {
    const { authState } = useContext(AuthContext);
    const {
        workspace,
        integrations,
        isIntegrationLoaded: isGoogleCalendalIntegrationLoaded,
    } = useContext(WorkspaceContext);
    const licenseState = useLicenseState();

    useEffect(() => {
        if (
            authState.type !== 'signedIn' ||
            licenseState.type !== 'loaded' ||
            !workspace ||
            !isGoogleCalendalIntegrationLoaded
        )
            return;
        const { user } = authState;
        const plan = licenseState.detail?.plan ?? 'expired';
        analyticsInstance.setUserProperties({
            email: user.email,
            workspaceId: workspace.id,
            plan: plan,
            status: user.userStatus,
            grantType: user.provider,
            type: workspace.member_type,
            calendarAuth: integrations.some(
                (integration) => integration.type === IntegrationType.GOOGLE_CALENDAR
            )
                ? IntegrationAuthType.Yes
                : IntegrationAuthType.No,
            accessPreset: workspace.accessScope,
        });
    }, [authState, workspace, licenseState, isGoogleCalendalIntegrationLoaded]);
};
