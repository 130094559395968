/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentStatus {
    PAID = 'paid',
    CANCELLED = 'cancelled',
    FAILED = 'failed',
}
