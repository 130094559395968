/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AuthInfoProvider {
    EMAIL = 'email',
    GOOGLE = 'google',
    APPLE = 'apple',
    MICROSOFT = 'microsoft',
}
