import styled from '@emotion/styled';

const StyledDiv = styled.div`
    z-index: 9001;
    position: relative;
`;

export function GlobalModalRoot(): JSX.Element {
    return <StyledDiv id="global-modal-root" />;
}
