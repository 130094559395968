/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum callabo__models__license__LicensePlan {
    TRIAL = 'trial',
    PLUS = 'plus',
    PRO = 'pro',
}
