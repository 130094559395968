/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Body_create_subscription_v1_notification_subscription_post } from '../models/Body_create_subscription_v1_notification_subscription_post';
import type { Body_delete_subscription_v1_notification_subscription_delete } from '../models/Body_delete_subscription_v1_notification_subscription_delete';
import type { NotificationSubscription } from '../models/NotificationSubscription';

export class NotificationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Unsubscribe
     * @param token 콜라보에서 알림 발송했을 때 발행한 토큰
     * @returns void
     * @throws ApiError
     */
    public unsubscribeV1NotificationUnsubscribePost(token?: string): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/notification/unsubscribe',
            query: {
                token: token,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subscriptions
     * @returns NotificationSubscription Successful Response
     * @throws ApiError
     */
    public getSubscriptionsV1NotificationSubscriptionsGet(): CancelablePromise<
        Array<NotificationSubscription>
    > {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/notification/subscriptions',
        });
    }

    /**
     * Create Subscription
     * @param requestBody
     * @returns NotificationSubscription Successful Response
     * @throws ApiError
     */
    public createSubscriptionV1NotificationSubscriptionPost(
        requestBody: Body_create_subscription_v1_notification_subscription_post
    ): CancelablePromise<NotificationSubscription> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/notification/subscription',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Subscription
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public deleteSubscriptionV1NotificationSubscriptionDelete(
        requestBody: Body_delete_subscription_v1_notification_subscription_delete
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/notification/subscription',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
