import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import styled from '@emotion/styled';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import { memo } from 'react';
import { useContext } from 'react';
import { AuthContext } from 'src/contexts/AuthContext';

function CommonSidebarFn({ locale }: PageProps): JSX.Element {
    const { authState } = useContext(AuthContext);

    switch (authState.type) {
        case 'signedIn': {
            return (
                <Wrapper>
                    <Header locale={locale} />
                    <Content locale={locale} />
                    <Divider />
                    <Footer locale={locale} />
                </Wrapper>
            );
        }
        default: {
            return <></>;
        }
    }
}

const Wrapper = styled.nav`
    height: 100%;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    box-sizing: border-box;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #ededed;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
`;
const Divider = styled.div`
    height: 1px;
    background-color: #ddd;
    margin: 0 20px;
`;

export const CommonSidebar = memo(CommonSidebarFn);
