import styled from '@emotion/styled';

export const BarChart = (props: { height: string; width: string; color: string }): JSX.Element => {
    return (
        <Wrapper height={props.height}>
            <Background>
                <RatioBar width={props.width} color={props.color} />
            </Background>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ height: string }>`
    height: ${(props) => props.height};
`;

const Background = styled.div`
    height: 100%;
    border-radius: 100px;
    background: #ededed;
`;

const RatioBar = styled.div<{ width: string; color: string }>`
    width: ${(props) => props.width};
    background: ${(props) => props.color};
    height: 100%;
    border-radius: 100px;
`;
