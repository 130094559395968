import { RecordingQuota } from 'callabo-api/src';

export class RecordingQuotaModel {
    constructor(private originalRecordingQuota: RecordingQuota) {}

    public get total(): number {
        return this.originalRecordingQuota.total;
    }

    public get used(): number {
        return this.originalRecordingQuota.used;
    }

    public get remaining(): number {
        return this.originalRecordingQuota.remaining;
    }

    public get hasEnoughRemaining(): boolean {
        return this.remaining >= 60;
    }

    public get usageRatio(): number {
        return this.used / this.total;
    }

    public get isOverDurationQuota(): boolean {
        return this.remaining <= 0;
    }

    public get totalMinutes(): number {
        return Math.ceil(this.total / 60);
    }

    public get usedMinutes(): number {
        return Math.ceil(this.used / 60);
    }

    public get remainingMinutes(): number {
        return Math.floor(this.remaining / 60);
    }
}
