/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MicrosoftAuthentication = {
    grant_type: MicrosoftAuthentication.grant_type;
    id_token: string;
    invitation_token?: string;
};

export namespace MicrosoftAuthentication {
    export enum grant_type {
        MICROSOFT = 'microsoft',
    }
}
