/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RecordInsightType {
    TOC = 'toc',
    ACTION_ITEM = 'action_item',
    KEYWORD = 'keyword',
    DATE_TIME = 'date_time',
    SUMMARY = 'summary',
    TAKEAWAY = 'takeaway',
    ACTION_ITEM_TS = 'action_item_ts',
}
