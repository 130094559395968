/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Label } from '../models/Label';
import type { LabelCreateRequest } from '../models/LabelCreateRequest';
import type { LabelUpdateRequest } from '../models/LabelUpdateRequest';
import type { LabelWithRecordCount } from '../models/LabelWithRecordCount';

export class LabelService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Label
     * @param slug
     * @returns LabelWithRecordCount Successful Response
     * @throws ApiError
     */
    public listLabelV1WorkspaceSlugLabelGet(
        slug: string
    ): CancelablePromise<Array<LabelWithRecordCount>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/label',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Label
     * @param slug
     * @param requestBody
     * @returns Label Successful Response
     * @throws ApiError
     */
    public createLabelV1WorkspaceSlugLabelPost(
        slug: string,
        requestBody: LabelCreateRequest
    ): CancelablePromise<Label> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/label',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                402: `You have reached the maximum number of labels allowed for this workspace.`,
                409: `This label already exists in the system.`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Label
     * @param slug
     * @param labelId
     * @returns void
     * @throws ApiError
     */
    public deleteLabelV1WorkspaceSlugLabelLabelIdDelete(
        slug: string,
        labelId: number
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/label/{label_id}',
            path: {
                slug: slug,
                label_id: labelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Label
     * @param slug
     * @param labelId
     * @param requestBody
     * @returns Label Successful Response
     * @throws ApiError
     */
    public updateLabelV1WorkspaceSlugLabelLabelIdPatch(
        slug: string,
        labelId: number,
        requestBody: LabelUpdateRequest
    ): CancelablePromise<Label> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}/label/{label_id}',
            path: {
                slug: slug,
                label_id: labelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
