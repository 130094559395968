import { RegexPattern } from './RegexPattern';

export function isValidHexColor(color: string): boolean {
    const hexColorRegex = RegexPattern.HexColor;
    return hexColorRegex.test(color);
}

export function createRandomColor(): string {
    let r, g, b;
    do {
        r = Math.floor(Math.random() * 256);
        g = Math.floor(Math.random() * 256);
        b = Math.floor(Math.random() * 256);
    } while (r > 100 && g > 100 && b > 100); // 흰색에 가까운 색 걸러내기

    const toHex = (value: number) => value.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
}

export function getContrastColor(hex: string): string {
    if (!isValidHexColor(hex)) return '#FFFFFF';
    let color = hex.replace(/^#/, '');
    if (color.length === 3) {
        color = color
            .split('')
            .map((char) => char + char)
            .join('');
    }
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? '#000000' : '#FFFFFF';
}
