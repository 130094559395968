import { UsageState } from './components/UsageState';
import LicenseContext from '@/contexts/LicenseContext';
import styled from '@emotion/styled';
import { WorkspacesUsersType } from 'callabo-api/src';
import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useMemo, useState } from 'react';
import WorkspaceContext from 'src/contexts/WorkspaceContext';
import { PathName } from 'src/modules/PathName';
import { Paths, replaceWorkspaceId } from 'src/modules/Paths';

const Content = ({ locale }: PageProps): JSX.Element => {
    const router = useRouter();
    const { t } = useTranslate({ locale });
    const isActive = useMemo(() => {
        return {
            Calls: router.pathname === Paths.Calls || router.pathname === Paths.RecordDetail,
            Events: router.pathname === Paths.Events,
            SharedRecords: router.pathname === Paths.SharedRecords,
            Member: router.pathname === Paths.Member,
            Label: router.pathname === Paths.Label,
            Config: router.pathname === Paths.Config,
            Integration: router.pathname === Paths.Integration,
            License: router.pathname === Paths.License,
        };
    }, [router.pathname]);
    const { workspace } = useContext(WorkspaceContext);
    const { licenseState, recordingQuotaState } = useContext(LicenseContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        workspace && (
            <ContentsWrapper>
                <Link
                    href={new PathName(Paths.Calls).setWorkspaceId(workspace.id).toString()}
                    passHref
                >
                    <ContentItem active={isActive.Calls}>
                        <Icon
                            active={isActive.Calls}
                            selected={'/callabo/sidebar/video_select.svg'}
                            unSelected={'/callabo/sidebar/video_unselect.svg'}
                        />
                        <span>{t.lnb.MEETING_ALL}</span>
                    </ContentItem>
                </Link>
                <Link
                    href={new PathName(Paths.Events).setWorkspaceId(workspace?.id).toString()}
                    passHref
                >
                    <ContentItem active={isActive.Events}>
                        <Icon
                            active={isActive.Events}
                            selected={'/callabo/sidebar/meeting_setting_select.svg'}
                            unSelected={'/callabo/sidebar/meeting_setting_unselect.svg'}
                        />
                        <span>{t.lnb.MEETING_SCHEDULED}</span>
                    </ContentItem>
                </Link>
                <Link
                    href={new PathName(Paths.SharedRecords)
                        .setWorkspaceId(workspace?.id)
                        .toString()}
                    passHref
                >
                    <ContentItem active={isActive.SharedRecords}>
                        <Icon
                            active={isActive.SharedRecords}
                            selected={'/callabo/sidebar/share_select.svg'}
                            unSelected={'/callabo/sidebar/share_unselect.svg'}
                        />
                        <span>{t.lnb.MEETING_SHARING}</span>
                    </ContentItem>
                </Link>
                <Accordion>
                    <ContentItem
                        active={
                            !isMenuOpen && (isActive.Member || isActive.Label || isActive.Config)
                        }
                        onClick={() => setIsMenuOpen((prev) => !prev)}
                    >
                        <Icon
                            active={
                                !isMenuOpen &&
                                (isActive.Member || isActive.Label || isActive.Config)
                            }
                            selected={'/callabo/sidebar/member_select.svg'}
                            unSelected={'/callabo/sidebar/member_unselect.svg'}
                        />
                        <span>{t.lnb.WORKSPACE}</span>
                        <ToggleButton
                            isOpen={isMenuOpen}
                            active={
                                !isMenuOpen &&
                                (isActive.Member || isActive.Label || isActive.Config)
                            }
                            selected={'/callabo/sidebar/icon_small_arrow_down_select.svg'}
                            unSelected={'/callabo/sidebar/icon_small_arrow_down.svg'}
                        />
                    </ContentItem>
                    <SubItemGroup isOpen={isMenuOpen}>
                        <Link
                            href={new PathName(Paths.Member)
                                .setWorkspaceId(workspace.id)
                                .toString()}
                            passHref
                        >
                            <ContentItem active={isActive.Member} style={{ paddingLeft: 32 }}>
                                <Icon
                                    active={isActive.Member}
                                    selected={'/callabo/sidebar/group_select.svg'}
                                    unSelected={'/callabo/sidebar/group_unselect.svg'}
                                />
                                <span>{t.lnb.MEMBERS}</span>
                            </ContentItem>
                        </Link>
                        <Link
                            href={new PathName(Paths.Label).setWorkspaceId(workspace.id).toString()}
                            passHref
                        >
                            <ContentItem active={isActive.Label} style={{ paddingLeft: 32 }}>
                                <Icon
                                    active={isActive.Label}
                                    selected={'/callabo/sidebar/label_select.svg'}
                                    unSelected={'/callabo/sidebar/label_unselect.svg'}
                                />
                                <span>{t.lnb.LABEL}</span>
                            </ContentItem>
                        </Link>
                        <Link
                            href={new PathName(Paths.Config)
                                .setWorkspaceId(workspace.id)
                                .toString()}
                            passHref
                        >
                            <ContentItem active={isActive.Config} style={{ paddingLeft: 32 }}>
                                <Icon
                                    active={isActive.Config}
                                    selected={'/callabo/sidebar/config_select.svg'}
                                    unSelected={'/callabo/sidebar/config_unselect.svg'}
                                />
                                <span>{t.lnb.CONFIG}</span>
                            </ContentItem>
                        </Link>
                    </SubItemGroup>
                </Accordion>
                <Link href={replaceWorkspaceId(Paths.Integration, workspace.id)} passHref>
                    <ContentItem active={isActive.Integration}>
                        <Icon
                            active={isActive.Integration}
                            selected={'/callabo/sidebar/integration_select.svg'}
                            unSelected={'/callabo/sidebar/integration_unselect.svg'}
                        />
                        <span>{t.lnb.INTEGRATION}</span>
                    </ContentItem>
                </Link>
                {workspace.member_type === WorkspacesUsersType.OWNER && (
                    <Link
                        href={new PathName(Paths.License).setWorkspaceId(workspace.id).toString()}
                        passHref
                    >
                        <ContentItem active={isActive.License}>
                            <Icon
                                active={isActive.License}
                                selected={'/callabo/sidebar/cash.svg'}
                                unSelected={'/callabo/sidebar/cash_unSelected.svg'}
                            />
                            <span>{t.lnb.LICENSE}</span>
                        </ContentItem>
                    </Link>
                )}
                {router.query.workspaceId && (
                    <UsageContainer
                        href={new PathName(Paths.UsageDashboard)
                            .setWorkspaceId(workspace.id)
                            .toString()}
                    >
                        <UsageState
                            recordingQuota={
                                licenseState.type === 'loaded' &&
                                recordingQuotaState.type === 'loaded'
                                    ? recordingQuotaState?.detail
                                    : undefined
                            }
                            locale={locale}
                        />
                    </UsageContainer>
                )}
            </ContentsWrapper>
        )
    );
};

const ContentsWrapper = styled.ul`
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const SubItemGroup = styled.div<{ isOpen: boolean }>`
    height: ${(props) => (props.isOpen ? '135px' : 0)};
    transition: height 0.1s ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const ContentItem = styled.li<{ active: boolean }>`
    width: calc(100% - 12px);
    min-width: calc(100% - 12px);
    max-width: calc(100% - 12px);
    height: 44px;
    padding: 0 12px 0 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    border-radius: 0px 4px 4px 0px;
    font-size: 0.875rem;
    line-height: 17px;
    white-space: nowrap;
    background: ${(props) =>
        props.active && 'linear-gradient(96.55deg, #2e67fe -4%, #962efe 205.2%)'};
    color: ${(props) => (props.active ? '#ffffff' : '#888888')};
    font-weight: ${(props) => (props.active ? 700 : 400)};
    position: relative;
    z-index: 10;

    &:hover {
        background: ${(props) =>
            !props.active &&
            'linear-gradient(96.55deg,rgba(46, 103, 254, 0.1) -4%,rgba(150, 46, 254, 0.1) 205.2%)'};
    }
`;

const Icon = styled.div<{ active: boolean; selected: string; unSelected: string }>`
    min-width: 16px;
    min-height: 16px;
    background: url(${(props) => (props.active ? props.selected : props.unSelected)}) no-repeat;
`;

const UsageContainer = styled.a`
    margin-top: auto;
    padding: 24px 20px;
    cursor: pointer;
`;

const Accordion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const ToggleButton = styled.button<{
    isOpen: boolean;
    active: boolean;
    selected: string;
    unSelected: string;
}>`
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    margin-left: auto;
    background: url(${(props) => (props.active ? props.selected : props.unSelected)}) no-repeat;
    transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')});
    transition: transform 0.1s ease-in-out;
`;

export default Content;
