import { MemberQuota } from 'callabo-api/src';

export class MemberQuotaModel {
    constructor(private originalMemberQuota: MemberQuota) {}

    public get total(): number {
        return this.originalMemberQuota.total ?? 0;
    }

    public set total(count: number) {
        this.originalMemberQuota.total = count;
    }

    public get used(): number {
        return this.originalMemberQuota.used ?? 0;
    }

    public get remaining(): number {
        return this.originalMemberQuota.remaining ?? 0;
    }

    public get usageRatio(): number {
        return this.used / this.total;
    }
}
