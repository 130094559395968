/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RecordCreateRequest = {
    source: RecordCreateRequest.source;
    title?: string;
    duration?: number;
    filesize: number;
    /**
     * 녹음/녹화 종료 시간
     */
    rec_date: string;
};

export namespace RecordCreateRequest {
    export enum source {
        VOICE_RECORDER = 'voice_recorder',
        SCREEN_RECORDER = 'screen_recorder',
        MANUAL_UPLOAD = 'manual_upload',
        PHONE_CALL = 'phone_call',
    }
}
