/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Body_register_v1_workspace__slug__card_put } from '../models/Body_register_v1_workspace__slug__card_put';
import type { WorkspaceCard } from '../models/WorkspaceCard';

export class WorkspaceCardService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get
     * Workspace에 등록된 카드 정보를 가져옵니다.
     * @param slug
     * @returns WorkspaceCard Successful Response
     * @throws ApiError
     */
    public getV1WorkspaceSlugCardGet(slug: string): CancelablePromise<WorkspaceCard> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/card',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Register
     * Workspace에 카드를 등록합니다.
     * 이미 등록된 카드가 있으면 삭제하고 새로 등록합니다.
     * @param slug
     * @param requestBody
     * @returns WorkspaceCard Successful Response
     * @throws ApiError
     */
    public registerV1WorkspaceSlugCardPut(
        slug: string,
        requestBody: Body_register_v1_workspace__slug__card_put
    ): CancelablePromise<WorkspaceCard> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/workspace/{slug}/card',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `다른 Workspace에서 이미 등록된 카드입니다.`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Card
     * Workspace에 등록된 카드를 삭제합니다.
     * @param slug
     * @returns void
     * @throws ApiError
     */
    public deleteCardV1WorkspaceSlugCardDelete(slug: string): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/card',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
