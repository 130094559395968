import { useSafeNavigator } from './SafeNavigator';
import { useEffect } from 'react';
import { UrlObject } from 'url';

export interface RedirectProps {
    url: UrlObject | string;
    push?: boolean;
}

const Redirect = ({ url, push = false }: RedirectProps): JSX.Element => {
    const navigator = useSafeNavigator();

    useEffect(() => {
        if (push) {
            navigator.push(url);
        } else {
            navigator.replace(url);
        }
    }, [navigator]);

    return <></>;
};

export default Redirect;
