/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { HTMLStr } from './HTMLStr';
import type { InsightsExportOutput } from './InsightsExportOutput';
import type { ZapierTriggerRecordEvent } from './ZapierTriggerRecordEvent';
import type { ZapierTriggerRecordUser } from './ZapierTriggerRecordUser';

export type ZapierTriggerRecord = {
    title: string | HTMLStr;
    rec_date: string;
    duration?: number;
    filesize: number;
    source: ZapierTriggerRecord.source;
    event?: ZapierTriggerRecordEvent;
    creator: ZapierTriggerRecordUser;
    insights: InsightsExportOutput;
    link: string;
};

export namespace ZapierTriggerRecord {
    export enum source {
        ZOOM = 'zoom',
        GOOGLE_MEET = 'google_meet',
        TEAMS_MEET = 'teams_meet',
        PHONE_CALL = 'phone_call',
        VOICE_RECORDER = 'voice_recorder',
        SCREEN_RECORDER = 'screen_recorder',
        MANUAL_UPLOAD = 'manual_upload',
    }
}
