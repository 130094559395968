import { CSSInterpolation } from '@emotion/css';
import { css } from '@emotion/react';

export const mobileMaxWidth = 767;
export const desktopMinWidth = 768;

class Media {
    public forMobile(args: CSSInterpolation): CSSInterpolation {
        return css`
            @media only screen and (max-width: ${mobileMaxWidth}px) {
                ${args}
            }
        `;
    }

    public forDesktop(args: CSSInterpolation): CSSInterpolation {
        return css`
            @media only screen and (min-width: ${desktopMinWidth}px) {
                ${args}
            }
        `;
    }
}

export default new Media();
