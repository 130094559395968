/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SharedRecordDuration {
    _7D = '7d',
    _30D = '30d',
    _60D = '60d',
    _90D = '90d',
    INFINITE = 'infinite',
}
