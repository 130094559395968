/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { WorkspaceStatus } from './WorkspaceStatus';
import type { WorkspacesUsersType } from './WorkspacesUsersType';

export type Workspace = {
    name: string;
    slug: string;
    status: WorkspaceStatus;
    created_at: string;
    updated_at: string;
    member_type?: WorkspacesUsersType;
    default_access_scope: Workspace.default_access_scope;
    default_transcribe_language: Workspace.default_transcribe_language;
    keywords: Array<string>;
    color: string;
};

export namespace Workspace {
    export enum default_access_scope {
        ALL = 'all',
        ATTENDEES = 'attendees',
    }

    export enum default_transcribe_language {
        KO = 'ko',
        EN = 'en',
        JA = 'ja',
    }
}
