/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmailAuthentication = {
    grant_type: EmailAuthentication.grant_type;
    email: string;
    password: string;
};

export namespace EmailAuthentication {
    export enum grant_type {
        EMAIL = 'email',
    }
}
