/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppleAuthentication = {
    grant_type: AppleAuthentication.grant_type;
    id_token: string;
    invitation_token?: string;
};

export namespace AppleAuthentication {
    export enum grant_type {
        APPLE = 'apple',
    }
}
