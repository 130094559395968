/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Workspace } from '../models/Workspace';
import type { WorkspaceAlert } from '../models/WorkspaceAlert';
import type { WorkspaceCreate } from '../models/WorkspaceCreate';
import type { WorkspaceMember } from '../models/WorkspaceMember';
import type { WorkspaceMemberInvite } from '../models/WorkspaceMemberInvite';
import type { WorkspaceMemberUpdateRequest } from '../models/WorkspaceMemberUpdateRequest';
import type { WorkspaceUpdate } from '../models/WorkspaceUpdate';

export class WorkspaceService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Workspace
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public listWorkspaceV1WorkspaceGet(): CancelablePromise<Array<Workspace>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace',
        });
    }

    /**
     * Create Workspace
     * @param requestBody
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public createWorkspaceV1WorkspacePost(
        requestBody: WorkspaceCreate
    ): CancelablePromise<Workspace> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Conflict`,
                412: `Precondition Failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Workspace
     * @param slug
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public getWorkspaceV1WorkspaceSlugGet(slug: string): CancelablePromise<Workspace> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
                429: `Too Many Requests`,
            },
        });
    }

    /**
     * Modify Workspace
     * @param slug
     * @param requestBody
     * @returns Workspace Successful Response
     * @throws ApiError
     */
    public modifyWorkspaceV1WorkspaceSlugPatch(
        slug: string,
        requestBody: WorkspaceUpdate
    ): CancelablePromise<Workspace> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Member
     * 워크스페이스의 모든 멤버를 가져옵니다.
     * @param slug
     * @returns WorkspaceMember Successful Response
     * @throws ApiError
     */
    public listMemberV1WorkspaceSlugMembersGet(
        slug: string
    ): CancelablePromise<Array<WorkspaceMember>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/members',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Members
     * 이메일을 통해 Guest 멤버를 초대합니다.
     *
     * 각 이메일별로 다음과 같은 처리가 됩니다.
     *
     * * 이미 워크스페이스에 속한 유저의 이메일인 경우: 무시합니다.
     * * 워크스페이스에 속하지는 않았지만, 콜라보 회원인 경우: 워크스페이스에 Guest 멤버로 초대하고 초대 메일을 발송합니다.
     * * 콜라보 회원이 아닌 경우: 가입 링크를 포함한 초대 메일을 발송합니다.
     *
     * 응답으로는 워크스페이스에 속하지는 않았지만, 콜라보 회원 멤버의 정보를 반환합니다.
     * @param slug
     * @param requestBody
     * @returns WorkspaceMember Successful Response
     * @throws ApiError
     */
    public addMembersV1WorkspaceSlugMembersPost(
        slug: string,
        requestBody: Array<WorkspaceMemberInvite>
    ): CancelablePromise<Array<WorkspaceMember>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/members',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Member
     * 워크스페이스의 멤버의 타입을 변경합니다.
     * @param slug
     * @param requestBody
     * @returns WorkspaceMember Successful Response
     * @throws ApiError
     */
    public updateMemberV1WorkspaceSlugMembersPatch(
        slug: string,
        requestBody: WorkspaceMemberUpdateRequest
    ): CancelablePromise<WorkspaceMember> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}/members',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                402: `Member 이상의 타입을 추가할 때, Quota를 초과해 실패한 경우`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Request Invitation
     * @param slug
     * @returns void
     * @throws ApiError
     */
    public requestInvitationV1WorkspaceSlugRequestInvitationPost(
        slug: string
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/request-invitation',
            path: {
                slug: slug,
            },
            errors: {
                409: `Conflict`,
                422: `Validation Error`,
                429: `Too Many Requests`,
            },
        });
    }

    /**
     * Get Workspace Service Alert
     * 서비스 차원에서 우선 해결해야 할 워크스페이스 알림을 가져옵니다.
     * @param slug
     * @returns WorkspaceAlert Successful Response
     * @throws ApiError
     */
    public getWorkspaceServiceAlertV1WorkspaceSlugAlertGet(
        slug: string
    ): CancelablePromise<WorkspaceAlert> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/alert',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
