import { useTooltip } from '../hooks/useTooltip';
import styled from '@emotion/styled';
import React, { PropsWithChildren, createContext } from 'react';

interface IState {
    toast(message: string, onHideTooltip?: () => void): void;
}

export const GlobalToastMessageContext = createContext<IState>({
    toast: () => new Error(),
});

export const GlobalToastMessageProvider: React.FC<PropsWithChildren> = (props) => {
    const [TooltipComponent, showToastMessage] = useTooltip({
        autoHideTimeMs: 2000,
        transitionMs: 300,
    });

    return (
        <GlobalToastMessageContext.Provider value={{ toast: showToastMessage }}>
            <GlobalTooltipRoot id="global-tooltip-root">
                <TooltipComponent />
            </GlobalTooltipRoot>
            {props.children}
        </GlobalToastMessageContext.Provider>
    );
};

const GlobalTooltipRoot = styled.div`
    z-index: 9101;
    position: absolute;
    top: calc(100% - 168px);
    width: 100%;
    height: 168px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
`;
