import { LicenseSubscriptionUpdateRequest } from 'callabo-api/src';

export type PaidLicensePlan = LicenseSubscriptionUpdateRequest.plan;
export const PaidLicensePlan = {
    ...LicenseSubscriptionUpdateRequest.plan,
};

export type LicensePeriod = LicenseSubscriptionUpdateRequest.period | 'P2W';
export const LicensePeriod = {
    ...LicenseSubscriptionUpdateRequest.period,
    P2W: 'P2W' as const, // trial중에 결제한 예외적인 경우
};
