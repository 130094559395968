import getIndexedDbStorageAsync from './IndexedDbStorage';
import { setCookie } from '@/utils/BrowserCookie';
import { v4 as uuidv4 } from 'uuid';

export async function getOrCreateDeviceIdAsync(): Promise<string> {
    const storage = await getIndexedDbStorageAsync();
    const uuid = await storage.getAsync<string>('deviceId');
    if (uuid) {
        return uuid;
    }

    const newUuid = self.crypto && self.crypto.randomUUID ? self.crypto.randomUUID() : uuidv4();
    await storage.setAsync('deviceId', newUuid);
    setCookie('deviceId', newUuid);
    return newUuid;
}
