import { IStorage, KeyValue } from './IStorage';
import { IDBPDatabase, openDB } from 'idb';

export class IndexedDbStorage implements IStorage {
    private _cache: Map<string, any> = new Map();

    constructor(private readonly idb: IDBPDatabase<unknown>, private readonly storeName: string) {}

    public static async createAsync(
        databaseName = 'rtzr',
        storeName = 'web'
    ): Promise<IndexedDbStorage> {
        const idb = await openDB(databaseName, 2, {
            upgrade(db, _, __, ___) {
                // …
                if (!db.objectStoreNames.contains(storeName)) db.createObjectStore(storeName);
            },
            blocked() {
                // …
            },
            blocking() {
                // …
            },
            terminated() {
                // …
            },
        });
        return new IndexedDbStorage(idb, storeName);
    }

    public setAsync = async <T>(key: string, data: T): Promise<void> => {
        const tx = this.idb.transaction(this.storeName, 'readwrite');
        const store = tx.store;
        await store.put(data, key);
        await tx.done;
        this._cache.set(key, data);
    };

    public setMany = async (keyValues: KeyValue[]): Promise<void> => {
        const tx = this.idb.transaction(this.storeName, 'readwrite');
        const store = tx.store;

        for (const idx in keyValues) {
            const data = keyValues[idx];
            const key = data.key;
            const value = data.value;
            await store.put(value, key);
            this._cache.set(key, value);
        }

        await tx.done;
    };

    /**
     * 데이터 읽기
     * @param key 키
     * @param force db로 부터 무조건 값을 읽는다.
     * @returns
     */
    public getAsync = async <T>(key: string, force = false): Promise<T> => {
        try {
            if (!force && this._cache.has(key)) {
                return this._cache.get(key) as T;
            }

            const tx = this.idb.transaction(this.storeName, 'readonly');
            const store = tx.store;
            const data: any = await store.get(key);
            await tx.done;

            this._cache.set(key, data);
            return data as T;
        } catch (error) {
            return undefined;
        }
    };

    public getMany = async <T>(keys: string[], force = false): Promise<T[]> => {
        if (!force) {
            const result: T[] = [];
            for (const key of keys) {
                if (this._cache.has(key)) {
                    result.push(this._cache.get(key) as T);
                }
            }
            if (result.length === keys.length) {
                return result;
            }
        }

        const tx = this.idb.transaction(this.storeName, 'readonly');
        const store = tx.store;
        const data: any[] = [];
        for (const key of keys) {
            data.push(await store.get(key));
        }
        await tx.done;

        // TODO: cache 저장 필요
        return data as T[];
    };

    public removeAsync = async (key: string): Promise<void> => {
        const tx = this.idb.transaction(this.storeName, 'readwrite');
        const store = tx.store;
        await store.delete(key);
        await tx.done;
        this._cache.delete(key);
    };

    public removeMany = async (keys: string[]): Promise<void> => {
        const tx = this.idb.transaction(this.storeName, 'readwrite');
        const store = tx.store;

        for (const key of keys) {
            await store.delete(key);
            this._cache.delete(key);
        }

        await tx.done;
    };

    public static async get<T>(key: string, force = false): Promise<T> {
        const storage = await getIndexedDbStorageAsync();
        return storage.getAsync<T>(key, force);
    }

    public static async getMany<T>(keys: string[], force = false): Promise<T[]> {
        const storage = await getIndexedDbStorageAsync();
        return storage.getMany<T>(keys, force);
    }

    public static async set<T>(key: string, data: T): Promise<void> {
        const storage = await getIndexedDbStorageAsync();
        return storage.setAsync<T>(key, data);
    }

    public static async setMany(keyValue: KeyValue[]): Promise<void> {
        const storage = await getIndexedDbStorageAsync();
        return storage.setMany(keyValue);
    }

    public static async remove(key: string): Promise<void> {
        const storage = await getIndexedDbStorageAsync();
        return storage.removeAsync(key);
    }

    public static async removeMany(keys: string[]): Promise<void> {
        const storage = await getIndexedDbStorageAsync();
        return storage.removeMany(keys);
    }
}

let _storage: IndexedDbStorage | undefined;
async function getIndexedDbStorageAsync(): Promise<IndexedDbStorage> {
    if (_storage) {
        return _storage;
    }
    _storage = await IndexedDbStorage.createAsync('rtzr', 'web');
    return _storage;
}

export default getIndexedDbStorageAsync;
