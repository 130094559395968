import { SlackChannelIntegration } from 'callabo-api/src';
import { SlackIntegration } from 'callabo-api/src/models/SlackIntegration';

export class SlackIntegrationModel {
    constructor(private originResponse: SlackIntegration | SlackChannelIntegration) {
        //
    }

    public get id(): number {
        return this.originResponse.id;
    }

    public get botScopes(): string[] {
        return this.originResponse.bot_scopes;
    }

    public get userScopes(): string[] {
        return this.originResponse.user_scopes;
    }

    public get channel(): string {
        return (this.originResponse as SlackChannelIntegration).channel ?? '';
    }
}
