import { toDoubleDigit } from './NumberUtils';
import { Locales } from 'callabo-locale/src/hooks/useTranslate';

interface DateFormatOption {
    /**
     * 년도 표현
     * true인 경우, YYYY로 표현
     */
    fullYear?: boolean;
    /**
     * 날짜 구분자 표현
     */
    separator?: string;
    /**
     * 요일 표현
     */
    showDay?: boolean;
    /**
     * 같은 년도인 경우, 년도 표시 생략
     */
    hiddenYearIfCurrent?: boolean;

    /**
     * 값이 한자리수일 경우 두자리로 맞출지 여부 (기본값 false)
     */
    padDuobleDigit?: boolean;

    separatorNextToDate?: string;
}

interface TimeFormatOption {
    /**
     * 오전, 오후 표현
     */
    showMeridiem?: boolean;
    showUnit?: boolean;
    separator?: string;
    showSecond?: string;
}

export function simpleFormatDateTime(
    date?: Date,
    dateOption: DateFormatOption = {},
    timeOption: TimeFormatOption = {},
    l: Locales = 'ko'
): string {
    if (!date) return '';
    return `${simpleFormatDate(date, dateOption, l)} ${timeToString(date, timeOption)}`.trim();
}

export function simpleFormatDate(
    dateObject?: Date,
    option: DateFormatOption = {},
    l: Locales = 'ko'
): string {
    if (!dateObject) return '';
    const {
        fullYear = true,
        separator = '-',
        showDay = false,
        hiddenYearIfCurrent = true,
        padDuobleDigit = false,
        separatorNextToDate = '',
    } = option;

    const padMethod = padDuobleDigit ? toDoubleDigit : (value: number) => `${value}`;

    const year: string = padMethod(dateObject.getFullYear() % (fullYear ? 10000 : 100));
    const month: string = padMethod(dateObject.getMonth() + 1);
    const date: string = padMethod(dateObject.getDate());
    const day: string = showDay ? ` (${dateToDay(dateObject, l)})` : '';
    if (l === 'en') {
        return new Date(dateObject).toLocaleDateString('en-us');
    }
    if (hiddenYearIfCurrent && new Date().getFullYear() === dateObject.getFullYear()) {
        return `${month}${separator}${date}${separatorNextToDate}${day}`;
    } else {
        return `${year}${separator}${month}${separator}${date}${separatorNextToDate}${day}`;
    }
}

export function descriptiveFormatDate(dateObject?: Date, l: Locales = 'ko'): string {
    if (!dateObject) return '';
    const units: Record<Locales, string[]> = {
        ko: ['년 ', '월 ', '일'],
        ja: ['年', '月', '日'],
        en: ['. ', '. ', '.'],
    };
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const date = dateObject.getDate();
    return `${year}${units[l][0]}${month}${units[l][1]}${date}${units[l][2]}`;
}

export function dateToDay(dateObject: Date, l: Locales = 'ko'): string {
    const days: Record<Locales, string[]> = {
        ko: ['일', '월', '화', '수', '목', '금', '토'],
        ja: ['日', '月', '火', '水', '木', '金', '土'],
        en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    };
    return days[l][dateObject.getDay()];
}

export function timeToString(
    dateObject?: Date,
    option: TimeFormatOption = {},
    l: Locales = 'ko'
): string {
    if (!dateObject) return '';
    const meridiemUnit: Record<Locales, string[]> = {
        ko: ['오전 ', '오후 '],
        ja: ['午前 ', '午後 '],
        en: [' AM', ' PM'],
    };
    const timeUnit: Record<Locales, string[]> = {
        ko: ['시 ', '분'],
        ja: ['時 ', '分'],
        en: [':', ''],
    };
    const { showMeridiem = false, separator = ':', showUnit = false } = option;

    const hour = dateObject.getHours();
    const meridiem = showMeridiem ? (hour >= 12 ? meridiemUnit[l][1] : meridiemUnit[l][0]) : '';
    const hourString = showMeridiem && hour > 12 ? hour % 12 : hour;
    const minuteString = toDoubleDigit(dateObject.getMinutes());
    if (l === 'en') {
        return `${hourString}${timeUnit[l][0]}${minuteString}${meridiem}`;
    } else {
        return `${meridiem}${hourString}${
            showUnit ? timeUnit[l][0] : ''
        }${separator}${minuteString}${showUnit ? timeUnit[l][1] : ''}`;
    }
}

export function dateIsOver(date: Date): boolean {
    return date.getTime() < Date.now();
}

export function dateIsSame(date1: Date, date2: Date): boolean {
    if (!date1 || !date2) return false;

    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export function isToday(date: Date): boolean {
    return dateIsSame(date, new Date());
}

export function isYesterday(date: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return dateIsSame(date, yesterday);
}

/**
 * 해당 날짜부터 n일 후 자정을 반환
 * const newDate = nDaysLater(new Date(), 30); 등으로 사용.
 * @param today
 * @param n
 * @returns
 */
export function nDaysLater(today: Date, n: number): Date {
    const laterDay = new Date(
        `${today.getFullYear()}-${`${today.getMonth() + 1}`.padStart(
            2,
            '0'
        )}-${`${today.getDate()}`.padStart(2, '0')}`
    );
    laterDay.setDate(laterDay.getDate() + n);
    return laterDay;
}

export function getDaysInMonth(year: number, month: number): number {
    const lastDay = new Date(year, month + 1, 0).getDate();
    return lastDay;
}
