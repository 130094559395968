/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { Attendee } from './Attendee';
import type { HTMLStr } from './HTMLStr';

export type EventWithExtra = {
    id: number;
    /**
     * Title of the event
     */
    summary: string | HTMLStr;
    start: string;
    end: string;
    attendees: Array<Attendee>;
    conference_url: string;
    /**
     * 반복일정 유/무
     */
    recurring: boolean;
    /**
     * 녹화할지 유/무
     */
    recording_scheduled: boolean;
    /**
     * 언어
     */
    language: EventWithExtra.language;
};

export namespace EventWithExtra {
    /**
     * 언어
     */
    export enum language {
        KO = 'ko',
        EN = 'en',
        JA = 'ja',
    }
}
