/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Body_update_transcribe_config_v1_workspace__slug__record__id__transcribe_config_put = {
    /**
     * 언어
     */
    language?: Body_update_transcribe_config_v1_workspace__slug__record__id__transcribe_config_put.language;
};

export namespace Body_update_transcribe_config_v1_workspace__slug__record__id__transcribe_config_put {
    /**
     * 언어
     */
    export enum language {
        KO = 'ko',
        EN = 'en',
        JA = 'ja',
    }
}
