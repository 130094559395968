import { Integration } from 'callabo-api/src/models/Integration';
import { IntegrationType } from 'callabo-api/src/models/IntegrationType';

export class IntegrationModel {
    constructor(private originResponse: Integration) {
        //
    }

    public get id(): number {
        return this.originResponse.id;
    }

    public get type(): IntegrationType {
        return this.originResponse.type;
    }

    public get setting(): Record<string, string> {
        return this.originResponse.setting;
    }
}
