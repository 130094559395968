import { FirebaseApp } from 'firebase/app';
import {
    MessagePayload,
    Messaging,
    NextFn,
    Observer,
    Unsubscribe,
    getMessaging,
    getToken,
    isSupported,
    onMessage,
} from 'firebase/messaging';
import getConfig from 'next/config';
import React, { createContext, useCallback, useEffect, useState } from 'react';

const { publicRuntimeConfig } = getConfig();

type OnMessageHandlerType = NextFn<MessagePayload> | Observer<MessagePayload>;
interface IState {
    pushToken: string;
    setOnMessage: (handler: OnMessageHandlerType) => Unsubscribe | undefined;
}

interface IProps {
    app: FirebaseApp;
    children?: React.ReactNode;
}

export const FirebaseContext = createContext<IState>({
    pushToken: undefined,
    setOnMessage: () => undefined,
});

export const FirebaseContextProvider: React.FC<IProps> = (props: IProps) => {
    const [messaging, setMessaging] = useState<Messaging>();
    const [pushToken, setPushToken] = useState<string>();
    const [permission, setPermission] = useState<NotificationPermission>();

    useEffect(() => {
        (async () => {
            if (
                !('Notification' in window) ||
                !Notification.requestPermission ||
                !(await isSupported())
            )
                return;
            setPermission(await Notification.requestPermission());
        })();
    }, []);

    useEffect(() => {
        if (permission !== 'granted') return;
        (async () => {
            const messaging = getMessaging(props.app);
            setMessaging(messaging);
            try {
                const token = await getToken(messaging, {
                    vapidKey: publicRuntimeConfig.FIREBASE_VAPID_KEY,
                });
                setPushToken(token);
            } catch (e) {
                console.error(e);
            }
        })();
    }, [permission, props.app]);

    const setOnMessage = useCallback(
        (handler: OnMessageHandlerType) => {
            if (messaging) return onMessage(messaging, handler);
        },
        [messaging]
    );

    return (
        <FirebaseContext.Provider
            value={{
                pushToken,
                setOnMessage,
            }}
        >
            {props.children}
        </FirebaseContext.Provider>
    );
};
