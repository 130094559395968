const COOKIE_PREFIX = '\\(O.O)/_'; // 쿠키 이름 앞에 붙일 prefix. 중복 방지를 위해 사용.

export function setCookie(name: string, value: string, seconds?: number): void {
    if (!name) {
        return;
    }

    let expires = '';
    if (seconds) {
        const date = new Date();
        date.setTime(date.getTime() + seconds * 1000); // 밀리초로 변환
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${COOKIE_PREFIX}${name}=${encodeURIComponent(
        value || ''
    )}${expires}; path=/`;
}

export function getCookie(name: string): string | null {
    const nameEQ = `${COOKIE_PREFIX}${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

export function removeCookie(name: string): void {
    document.cookie = `${COOKIE_PREFIX}${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}

export function removeCookies(names: string[]): void {
    names.forEach((name) => {
        removeCookie(name);
    });
}
