import styled from '@emotion/styled';
import { floor } from 'libs/rtzr-commons/NumberUtils';
import { useCallback, useRef } from 'react';

export interface TooltipOption {
    autoHideTimeMs?: number; // 0 is infinity
    transitionMs?: number;
    onHide?: () => void;
}

export function useTooltip(
    option?: TooltipOption
): [
    (props: { left?: number; top?: number }) => JSX.Element,
    (message: string) => void,
    () => void
] {
    const timeoutRef = useRef<number>();
    const tooltipRef = useRef<HTMLDivElement>();
    const tooltipMessageRef = useRef<HTMLSpanElement>();

    const TooltipComponent = (props: { left?: number; top?: number }) => {
        return (
            <TooltipContainer
                ref={tooltipRef}
                top={props.top}
                left={props.left}
                transitionMs={option?.transitionMs}
            >
                <span
                    style={{
                        maxWidth: 'calc(100% - 24px)',
                    }}
                    ref={tooltipMessageRef}
                ></span>
            </TooltipContainer>
        );
    };

    const hideTooltip = useCallback(() => {
        if (!tooltipRef.current) return;

        tooltipRef.current.style.opacity = '0';
        if (!option?.onHide) return;

        if (option?.transitionMs) {
            setTimeout(() => option?.onHide(), option.transitionMs);
        } else {
            option?.onHide();
        }
    }, [tooltipRef.current]);

    const showTooltip = useCallback(
        (message: string, onCloseTooltip?: () => void) => {
            if (!tooltipMessageRef.current || !tooltipRef) return;
            tooltipMessageRef.current.textContent = message;
            tooltipRef.current.style.opacity = '1';
            if (option?.autoHideTimeMs) {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                timeoutRef.current = self.setTimeout(() => {
                    hideTooltip();
                    onCloseTooltip?.();
                }, option.autoHideTimeMs);
            }
        },
        [tooltipRef, tooltipMessageRef, timeoutRef]
    );

    return [TooltipComponent, showTooltip, hideTooltip];
}

const TooltipContainer = styled.div<{
    top: number;
    left: number;
    transitionMs: number;
}>`
    top: ${(props) => (props.top !== undefined ? `${props.top}px` : '')};
    left: ${(props) => (props.left !== undefined ? `${props.left}px` : '')};
    position: fixed;
    opacity: 0;
    transition: ${(props) =>
        props.transitionMs ? `opacity ${floor(props.transitionMs / 1000, 1)}s` : ''};

    span {
        padding: 14px 20px;

        background: #333333;
        border-radius: 4px;

        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;

        color: #ffffff;
        display: table-cell;

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: auto;
        white-space: pre-line;
    }
`;
