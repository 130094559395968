import { NextPage } from 'next';

// eslint-disable-next-line @typescript-eslint/ban-types
export type CallaboPage<P = {}, IP = P> = NextPage<P, IP> & {
    /**
     * 이하 callabo
     */
    isInWorkspace?: boolean;

    /**
     * 공통 레이아웃 적용
     */
    enableCommonLayout?: boolean;

    /**
     * 페이지 접근 권한 값
     */
    accessibility?: Accessibility;

    // skipInitialize?: boolean;

    isHomepage?: boolean;

    /**
     * 공유 여부
     */
    isShared?: boolean;
};

export enum Accessibility {
    /**
     * 모두 접근 가능. 기본값
     */
    EVERYONE = 'everyone',
    /**
     * 로그인 안되어있는 경우 접근 가능. 로그인 페이지 등
     */
    PUBLIC_ONLY = 'public_only',
    /**
     * 인증되지 않은 유저 접근 가능. 인증 페이지 등
     */
    UNVERIFIED_USER = 'unverified_user',
    /**
     * 인증되었으나 워크스페이스는 없는 유저
     */
    NEW_VERIFIED_USER = 'new_verified_user',
    /**
     * 인증된 유저 접근 가능
     */
    USER = 'user',
}
