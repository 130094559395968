/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SharedRecordAuthentication = {
    grant_type: SharedRecordAuthentication.grant_type;
    slug: string;
    share_id: string;
    password: string;
};

export namespace SharedRecordAuthentication {
    export enum grant_type {
        SHARED_RECORD = 'shared_record',
    }
}
