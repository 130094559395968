import { InquiryMenu } from './components/InquiryMenu';
import styled from '@emotion/styled';
import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import useDropdownMenu from 'libs/callabo-ui/common/DropdownMenu/useDropdownMenu';
import analyticsInstance from 'libs/rtzr-commons/modules/marketing/Analytics';
import { Style } from 'libs/rtzr-design/common';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'src/contexts/AuthContext';
import { Paths } from 'src/modules/Paths';

const Footer = ({ locale }: PageProps): JSX.Element => {
    const { authState } = useContext(AuthContext);
    const { expanded, openMenu, closeMenu } = useDropdownMenu();
    const { t } = useTranslate({ locale });

    const onClickInquiryItem = useCallback((eventTarget: string) => {
        closeMenu();
        analyticsInstance.logEvent('action_click_inquiry', {
            target: eventTarget,
        });
    }, []);

    switch (authState.type) {
        case 'signedIn': {
            return (
                <Container>
                    <Link href={Paths.My} passHref>
                        <Wrapper>
                            <Span>{authState.user.name}</Span>
                            <Icon>
                                <Image
                                    style={{ flex: '0 0 auto' }}
                                    src={require('./assets/icon_account_more.svg')}
                                    width={16}
                                    height={16}
                                    alt="icon"
                                />
                            </Icon>
                        </Wrapper>
                    </Link>
                    <Divider />
                    <Wrapper
                        onClick={() => {
                            if (expanded) return;
                            openMenu();
                            analyticsInstance.logEvent('action_open_inquiry_menu');
                        }}
                    >
                        <span>{t.lnb.INQUIRY}</span>
                        <Icon>
                            <Image
                                style={{ flex: '0 0 auto' }}
                                src={require('./assets/icon_service_more.svg')}
                                width={16}
                                height={16}
                                alt="icon"
                            />
                        </Icon>
                    </Wrapper>
                    {expanded ? (
                        <Style.DesktopOnly>
                            <InquiryMenu
                                onCloseMenu={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    closeMenu();
                                }}
                                onClickItem={onClickInquiryItem}
                                locale={locale}
                            />
                        </Style.DesktopOnly>
                    ) : (
                        <></>
                    )}
                    <TermsLinkGroup>
                        <Link href={Paths.TermService} target="_blank">
                            <button
                                onMouseDown={() => {
                                    analyticsInstance.from = 'GNB';
                                }}
                            >
                                {t.common.TERM_SERVICE}
                            </button>
                        </Link>
                        <span>{'/'}</span>
                        <Link href={Paths.TermPrivacy} target="_blank">
                            <button
                                onMouseDown={() => {
                                    analyticsInstance.from = 'GNB';
                                }}
                            >
                                {t.common.TERM_PRIVACY}
                            </button>
                        </Link>
                    </TermsLinkGroup>
                </Container>
            );
        }
        default: {
            return <></>;
        }
    }
};

const Container = styled.div`
    padding-bottom: 16px;
    position: relative;
`;

const Wrapper = styled.div`
    position: relative;
    padding: 16px 24px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 17px;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const Divider = styled.div`
    height: 1px;
    background-color: #ddd;
    margin: 0 20px;
`;

const Icon = styled.div`
    width: 16px;
    height: 16px;
    flex: 0 0 auto;
`;

const Span = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
`;

const TermsLinkGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 12px;
    padding: 0 24px;

    button {
        color: #bbbbbb;
    }

    span {
        color: #dddddd;
    }
`;

export default Footer;
