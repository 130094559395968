/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Device = {
    platform: Device.platform;
    device_id: string;
    push_token: string;
};

export namespace Device {
    export enum platform {
        WEB = 'web',
        ANDROID = 'android',
        IOS = 'ios',
    }
}
