import { useEffect, useState } from 'react';

export function isClientSideRender(): boolean {
    const [isCSR, setIsCSR] = useState(false);
    useEffect(() => {
        setIsCSR(true);
    }, []);

    return isCSR || typeof window !== 'undefined';
}
