import { IStorage, KeyValue } from './IStorage';

export class MemoryCache implements IStorage {
    private _cache: Map<string, any> = new Map();

    public setAsync = async <T>(key: string, data: T): Promise<void> => {
        this._cache.set(key, data);
    };

    /**
     * 데이터 읽기
     * @param key 키
     * @param force db로 부터 무조건 값을 읽는다.
     * @returns
     */
    public getAsync = async <T>(key: string): Promise<T> => {
        try {
            return this._cache.get(key) as T;
        } catch (error) {
            return undefined;
        }
    };

    public removeAsync = async (key: string): Promise<void> => {
        this._cache.delete(key);
    };

    public setMany(keyValues: KeyValue[]): Promise<void> {
        for (const idx in keyValues) {
            const data = keyValues[idx];
            const key = data.key;
            const value = data.value;
            this._cache.set(key, value);
        }
        return Promise.resolve();
    }

    public removeMany(keys: string[]): Promise<void> {
        for (const key of keys) {
            this._cache.delete(key);
        }
        return Promise.resolve();
    }
}
