/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum NotificationCategory {
    TRANSCRIBE_COMPLETE = 'transcribe_complete',
    MARKETING = 'marketing',
    TUTORIAL = 'tutorial',
    NOTICE = 'notice',
}
