import {
    LicenseSubscription,
    LicenseSubscriptionStatus,
    callabo__models__license__LicensePlan,
} from 'callabo-api/src';
import { LicensePlan } from 'libs/callabo-state/types/License';
import { LicensePeriod } from 'libs/callabo-ui/types/License';
import { getDaysInMonth } from 'libs/rtzr-commons/DateUtils';
import { PLAN_POLICY, PlanPolicyNames } from 'libs/rtzr-commons/FeaturePolicy';

export class LicenseSubscriptionModel {
    constructor(private originResponse: LicenseSubscription & { expired?: boolean }) {
        //
    }

    public get id(): number {
        return this.originResponse?.id;
    }

    public get plan(): LicensePlan {
        return this.originResponse?.plan;
    }

    public get status(): LicenseSubscriptionStatus {
        return this.originResponse?.status;
    }

    public get count(): number {
        return this.originResponse?.count;
    }

    public get totalDurationQuota(): number {
        return this.originResponse?.total_duration_quota;
    }

    public get totalMembersQuota(): number {
        return this.originResponse?.total_members_quota;
    }

    public get startAt(): string {
        return this.originResponse?.start_at;
    }

    public get expiredAt(): string {
        return this.originResponse?.expire_at;
    }

    public get endAt(): Date {
        if (!this.originResponse) return;
        const expiryDate = new Date(this.expiredAt);
        const endDate = new Date(expiryDate.setDate(expiryDate.getDate() - 1));
        return endDate;
    }

    public get createdAt(): string {
        return this.originResponse?.created_at;
    }
    public get updatedAt(): string {
        return this.originResponse?.updated_at;
    }

    public get isTrialLicense(): boolean {
        return this.plan === callabo__models__license__LicensePlan.TRIAL;
    }

    public get daysLeft(): number {
        if (!this.originResponse) return 0;
        const now = new Date();
        const expiredDate = new Date(this.expiredAt);
        const timeDiff = expiredDate.getTime() - now.getTime();
        const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysLeft;
    }

    public get totalDuration(): number {
        if (!this.originResponse) return 0;
        const startDate = new Date(this.startAt);
        const expiredDate = new Date(this.expiredAt);
        const duration = Math.ceil(
            (expiredDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
        );
        return duration;
    }

    public get period(): LicensePeriod {
        return this.originResponse?.period as LicensePeriod;
    }

    public getLeftDaysRate(): number {
        if (this.originResponse.period === LicensePeriod.P2W) {
            const startDate = new Date(this.originResponse.start_at);
            return this.daysLeft / getDaysInMonth(startDate.getFullYear(), startDate.getMonth());
        } else {
            return this.daysLeft / this.totalDuration;
        }
    }

    public hasPlanPolicy(key: PlanPolicyNames): boolean {
        return this.isActive ? PLAN_POLICY[key][this.originResponse.plan] : false;
    }

    public get isActive(): boolean {
        return this.originResponse.expired !== true;
    }
}

export const ExpiredLicenseSubscription = new LicenseSubscriptionModel({
    id: 0,
    plan: callabo__models__license__LicensePlan.TRIAL,
    status: LicenseSubscriptionStatus.PAID,
    count: 1,
    total_duration_quota: 0,
    total_members_quota: 10,
    start_at: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    expire_at: new Date(Date.now()).toISOString().split('T')[0],
    created_at: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    updated_at: new Date().toISOString(),
    period: LicensePeriod.P2W,
    expired: true,
});
