/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserStatus {
    CREATED = 'created',
    VERIFIED = 'verified',
    WITHDRAW = 'withdraw',
}
