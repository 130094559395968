import { Portal } from './Portal';
import styled from '@emotion/styled';
import Media from 'libs/rtzr-design/Media';
import Image from 'next/image';
import React, { useEffect } from 'react';

const ModalWrapper = styled.div`
    box-sizing: content-box;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
`;

const ModalOverlay = styled.div<{ overlayColor?: string }>`
    box-sizing: border-box;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
`;

const ModalInner = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    transform: translate(0, -50%);
    top: 50%;
`;

const ClosableModalContent = styled.div`
    padding: 16px 24px 24px 24px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(11, 14, 56, 0.16);
    border-radius: 8px;
    max-width: 788px;

    ${Media.forMobile(`
        max-width: calc(100% - 24px);
        padding: 8px 16px 16px 16px;
    `)}
`;

const ModalContent = styled.div`
    padding: 32px 16px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(11, 14, 56, 0.16);
`;
export interface ModalProps {
    onClose?: () => void;
    maskClosable?: boolean;
    closable?: boolean;
    children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = (props) => {
    const { children, onClose, maskClosable, closable } = props;

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    useEffect(() => {
        if (!closable) return;
        const closePopupByEsc = (e) => {
            if (e.code === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', closePopupByEsc);
        return () => {
            window.removeEventListener('keydown', closePopupByEsc);
        };
    }, [closable]);

    return (
        <Portal elementId={'global-modal-root'}>
            <ModalOverlay />
            <ModalWrapper onClick={maskClosable ? onMaskClick : undefined}>
                <ModalInner
                    className="modal-inner"
                    onClick={maskClosable ? onMaskClick : undefined}
                >
                    {closable ? (
                        <ClosableModalContent>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    transform: 'translateX(10px)',
                                    marginBottom: 2,
                                }}
                            >
                                <button
                                    onClick={onClose}
                                    style={{
                                        height: 24,
                                    }}
                                >
                                    <Image
                                        src={'/callabo/close_dark.svg'}
                                        width={24}
                                        height={24}
                                        alt="icon"
                                    />
                                </button>
                            </div>
                            {children}
                        </ClosableModalContent>
                    ) : (
                        <ModalContent>{children}</ModalContent>
                    )}
                </ModalInner>
            </ModalWrapper>
        </Portal>
    );
};
