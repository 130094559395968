/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { LicenseSubscription } from '../models/LicenseSubscription';
import type { LicenseSubscriptionUpdateRequest } from '../models/LicenseSubscriptionUpdateRequest';
import type { Paging_LicensePaymentHistory_ } from '../models/Paging_LicensePaymentHistory_';
import type { Paging_LicenseSubscription_ } from '../models/Paging_LicenseSubscription_';

export class LicenseService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Active License
     * 현재 구독중인 라이센스 정보를 가져옵니다.
     * @param slug
     * @returns LicenseSubscription Successful Response
     * @throws ApiError
     */
    public getActiveLicenseV1WorkspaceSlugLicenseActiveGet(
        slug: string
    ): CancelablePromise<LicenseSubscription> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/license/active',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update License
     * 라이센스를 구독 / 변경합니다.
     *
     * 1. 유료 라이센스 시작
     *
     * 현재 Active한 라이센스가 없는 상태에서 API를 호출하면 새로운 라이센스를 구독하게 됩니다.
     * 이 경우, 결제를 진행하고, subscription을 생성합니다.
     *
     * 2. 유료 라이센스 추가 구매
     *
     * 현재 Active한 subscription이 있는 상태에서 요청한 License 개수가 Active한 subscription보다 많은 경우입니다.
     * 이 경우 다음과 같은 동작을 수행합니다.
     *
     * * 요청한 라이센스 개수와 Active한 라이센스의 개수 차이만큼의 라이센스를 일할계산하여 결제를 진행합니다.
     * * 결제가 완료되면, subscription을 업데이트합니다.
     *
     * 3. 유료 라이센스 다운그레이드
     *
     * 현재 Active한 subscription이 있는 상태에서 요청한 License 개수가 Active한 subscription보다 적거나 같은
     * 경우입니다. 이 경우, 현재 Active한 subscription이 아닌 다음 subscription을 업데이트합니다.
     * 제약사항은 다음과 같습니다.
     *
     * * 변경하는 라이센스 개수에서의 멤버수가 현재 멤버수보다 적은 경우, Quota 초과로 인한 HTTP 412응답을 반환합니다.
     * @param slug
     * @param requestBody
     * @returns LicenseSubscription Successful Response
     * @throws ApiError
     */
    public updateLicenseV1WorkspaceSlugLicenseActivePut(
        slug: string,
        requestBody: LicenseSubscriptionUpdateRequest
    ): CancelablePromise<LicenseSubscription> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/workspace/{slug}/license/active',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                412: `
                 * 결제카드가 등록되지 않은 경우
                 * 다운그레이드 하려는 멤버수가 라이센스 멤버수를 초과하는 경우
                 * 현재 구독중인 라이센스가 결제되지 않은 경우
                `,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Next License
     * 현재 구독기간 이후 예정된 라이센스 정보를 가져옵니다.
     * @param slug
     * @returns LicenseSubscription Successful Response
     * @throws ApiError
     */
    public getNextLicenseV1WorkspaceSlugLicenseNextGet(
        slug: string
    ): CancelablePromise<LicenseSubscription> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/license/next',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Schedule Cancellation
     * 라이센스를 해지 예약합니다. 해지할 라이센스가 없다면 무시합니다.
     * @param slug
     * @returns void
     * @throws ApiError
     */
    public scheduleCancellationV1WorkspaceSlugLicenseNextDelete(
        slug: string
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/license/next',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Previous License
     * 현재 구독기간 이전 마지막으로 구독한 라이센스 정보를 가져옵니다.
     * @param slug
     * @returns LicenseSubscription Successful Response
     * @throws ApiError
     */
    public getPreviousLicenseV1WorkspaceSlugLicensePreviousGet(
        slug: string
    ): CancelablePromise<LicenseSubscription> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/license/previous',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Licenses
     * 구독한 라이센스 목록을 가져옵니다. 만료일 기준으로 내림차순 정렬됩니다.
     * @param slug
     * @param nextToken 다음 페이지 토큰
     * @returns Paging_LicenseSubscription_ Successful Response
     * @throws ApiError
     */
    public listLicensesV1WorkspaceSlugLicenseGet(
        slug: string,
        nextToken?: string
    ): CancelablePromise<Paging_LicenseSubscription_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/license',
            path: {
                slug: slug,
            },
            query: {
                next_token: nextToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List License Payment Histories
     * 구독 결제 목록을 가져옵니다. 결제일 기준으로 내림차순 정렬됩니다.
     * @param slug
     * @param nextToken 다음 페이지 토큰
     * @returns Paging_LicensePaymentHistory_ Successful Response
     * @throws ApiError
     */
    public listLicensePaymentHistoriesV1WorkspaceSlugLicensePaymentHistoriesGet(
        slug: string,
        nextToken?: string
    ): CancelablePromise<Paging_LicensePaymentHistory_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/license/payment-histories',
            path: {
                slug: slug,
            },
            query: {
                next_token: nextToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Pay Active
     * 현재 라이센스를 결제합니다.
     * 이미 결제된 라이센스라면 무시합니다.
     * @param slug
     * @returns LicenseSubscription Successful Response
     * @throws ApiError
     */
    public payActiveV1WorkspaceSlugLicenseActivePayPost(
        slug: string
    ): CancelablePromise<LicenseSubscription> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/license/active/pay',
            path: {
                slug: slug,
            },
            errors: {
                412: `
                 * 결제카드가 등록되지 않은 경우
                 * 다운그레이드 하려는 멤버수가 라이센스 멤버수를 초과하는 경우
                 * 현재 구독중인 라이센스가 결제되지 않은 경우
                `,
                422: `Validation Error`,
            },
        });
    }
}
