/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type WorkspaceAlert = {
    type: WorkspaceAlert.type;
};

export namespace WorkspaceAlert {
    export enum type {
        TRIAL_LICENSE_ENDED = 'trial_license_ended',
        PLUS_LICENSE_ENDED = 'plus_license_ended',
        PRO_LICENSE_ENDED = 'pro_license_ended',
        LICENSE_PAYMENT_FAILED = 'license_payment_failed',
        LICENSE_REVOKED = 'license_revoked',
        RECORDING_QUOTA_EXCEEDED = 'recording_quota_exceeded',
        NEXT_LICENSE_NOT_SCHEDULED = 'next_license_not_scheduled',
        TRIAL_LICENSE_EXPIRING_SOON = 'trial_license_expiring_soon',
    }
}
