/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum LicensePaymentType {
    NORMAL = 'normal',
    UPGRADE = 'upgrade',
}
